import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, SlideStyleProps>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#fff',
  },
  accentLineSocialRounded: {
    position: 'absolute',
    left: '6%',
    top: '14.2%',
    right: 'auto',
    bottom: 'auto',
    width: '7.5%',
    height: '1.5%',
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    borderRadius: '20px',
  },
  leftTitle: {
    position: 'absolute',
    left: '6%',
    top: '17.5%',
    right: 'auto',
    bottom: 'auto',
    width: '24%',
    marginTop: '0px',
    marginBottom: '0px',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
    fontSize: '4em',
    lineHeight: '110%',
    fontFamily: ({ fontPair }) => fontPair?.majorFont.familyName,
  },
  leftSubtitle: {
    position: 'absolute',
    left: '6%',
    top: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '24%',
    marginTop: '0px',
    marginBottom: '0px',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    fontSize: '2em',
    lineHeight: '110%',
    textTransform: 'none',
  },
  socialBackgroundRound: {
    position: 'absolute',
    left: 'auto',
    top: '0%',
    right: '0%',
    bottom: '0%',
    display: 'flex',
    width: '62%',
    paddingLeft: '6%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
    opacity: 1,
    borderTopRightRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
  },
  socialRow: {
    display: 'flex',
    width: '90%',
    marginTop: '2%',
    marginBottom: '2%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  socialIconRound: {
    width: '16%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
  },
  socialTextBlock: {
    display: 'flex',
    marginLeft: '5%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  socialPlatformRound: {
    marginTop: '0px',
    marginBottom: '2%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    fontSize: '2.6em',
    lineHeight: '100%',
  },
  socialUrlDark: {
    marginTop: '0px',
    marginBottom: '0px',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
    fontSize: '1.7em',
    lineHeight: '100%',
  },
  slideNumber: {
    position: 'absolute',
    left: 'auto',
    top: 'auto',
    right: '2.3%',
    bottom: '4%',
    display: 'flex',
    width: '4%',
    height: 'auto',
    paddingTop: '0%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    textAlign: 'center',
  },
  number: {
    marginTop: '0',
    marginBottom: '0',
    color: '#fff',
    fontSize: '1.4em',
    lineHeight: '100%',
  },
}));
