import axios from 'axios';
import { firebaseAuth } from 'services/firebase';

axios.defaults.baseURL = process.env.REACT_APP_POWERPOINT_PROCESSOR_URL;

export const apiClient = axios.create();

apiClient.interceptors.request.use(
  async config => {
    const userIdToken = await firebaseAuth.currentUser!.getIdToken();
    if (userIdToken) {
      config.headers.Authorization = 'Bearer ' + userIdToken;
    }

    return config;
  },
  error => Promise.reject(error)
);
