import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, SlideStyleProps>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius * 2,
  },
  leftImageEdge: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 'auto',
    right: 'auto',
    width: '44%',
    height: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
  },
  quoteTextEdge: {
    position: 'absolute',
    left: '49%',
    top: '5.5%',
    right: 'auto',
    bottom: 'auto',
    width: '44%',
    fontSize: '2.7em',
    lineHeight: '123%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
  },
  quoteAuthorEdge: {
    position: 'absolute',
    left: '49%',
    top: '78%',
    right: 'auto',
    bottom: 'auto',
    marginTop: 0,
    marginBottom: 0,
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.black),
    fontSize: '2.8em',
    lineHeight: '100%',
  },
}));
