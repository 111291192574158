import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    position: 'sticky',
    top: 20,
    padding: theme.spacing(3.375, 7),
    maxHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
  },
}));
