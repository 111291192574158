import cn from 'classnames';
import { useStyles } from './SlideSecond.styles';
import { useRootStyles } from '../../common.styles';
import { SlideProps } from '../../types';

export const SlideSecond: React.FC<SlideProps> = ({ colorTheme, logoUrl, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[1].image;
  const backupImageSource: string = photos?.items[1].backupImage!;

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img
          src={imageSource ? imageSource : backupImageSource}
          loading="lazy"
          alt=""
          className={rootStyles.fullBackgroundImage}
        />
        {/* svg 1 */}
        <div className={cn(rootStyles.svgAccentOne, rootStyles.opacityEighty)}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
            <path
              fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              d="M1115,217.8c-124.8-186.7-466.7-70.4-600.3,88.6c-69.9,83.2-99.5,187.7-79.5,281.5
	c20.1,94.7,100.9,182,216,189.6c85.4,5.7,171.9-32.4,244.8-78c91.6-57.4,172.9-130.6,218.5-217.4
	C1160,395.4,1166.1,294.2,1115,217.8z"
            ></path>
          </svg>
        </div>
        {/* svg 2 */}
        <div className={rootStyles.svgWhite}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1600 900">
            <path
              fill="#fff"
              d="M956,237.6c-40.6-7.4-81.7-13.3-120.8-27c-47.7-16.8-91.9-40.4-142-50.2c-90.7-17.7-186.6,37.5-229.4,119.7
	c-42.8,82.2-36.7,184.6,2.9,268.4c62.7,133,209.4,221.1,355.7,213.6c156.9-8,303.9-126.1,341.9-280.2c11.1-45.1,9.3-94.9-12.9-135.7
	c-21.6-39.5-60.4-67.3-102.1-84C1019.1,250.1,987.7,243.4,956,237.6z"
            ></path>
          </svg>
        </div>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerSvg)}>
            <div className={styles.logoBoxFlowTwo}>
              <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
            </div>
          </div>
        )}
        <h1 className={styles.titleFlowTwo}>Image Title Slide</h1>
        <h2 className={styles.subtitleFlowTwo}>Presenter Name / Date</h2>
      </div>
    </>
  );
};

export default SlideSecond;
