import { RootState } from 'services/store/root-reducer';

export const selectUploadFile = (store: RootState): RootState['storage']['uploadFile'] => {
  return store.storage.uploadFile;
};

export const selectSavedImages = (store: RootState): RootState['storage']['savedImages'] => {
  return store.storage.savedImages;
};

export const selectPreviewImageSource = (store: RootState): RootState['storage']['previewImageSource'] => {
  return store.storage.previewImageSource;
};
