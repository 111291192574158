import React from 'react';
import { ColorTheme } from 'components/CreatorPreview/templates/types';

const YoutubeBoxIcon: React.FC<ColorTheme> = ({ colorTheme }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 342.9 342.9"
      fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
      style={{ borderRadius: '100%', width: '16%', height: '100%' }}
    >
      <g>
        <polygon points="149.3,202.7 204.1,171.6 149.3,140.5" />
        <path
          d="M336.5,93.4L336.5,93.4C305.2-29.6,144.5-17.2,68,50.8C28,86.4,2.9,140,0.2,195.3
		C-2.5,251,21.8,312,70.3,333.9c36,16.2,77.7,8.3,114.5-5.9c46.2-17.9,98.8-22.8,128.8-64.2C343.5,222.5,349.3,143.7,336.5,93.4z
		 M261,216.2c-2,8.2-8.3,14.7-16.5,17c-14.6,3.9-73.1,3.9-73.1,3.9s-58.5,0-73.1-3.9c-8-2.2-14.2-8.3-16.5-16.3
		c-2.7-15-4-30.1-3.9-45.3c0-15,1.3-30.1,3.9-45c2-8.2,8.3-14.7,16.5-17c14.6-3.9,73.1-3.9,73.1-3.9s58.5,0,73.1,3.6
		c8.2,2.3,14.5,8.8,16.5,17c2.7,15,4,30.1,3.9,45.3C265,186.6,263.7,201.5,261,216.2z"
        />
      </g>
    </svg>
  );
};

export default YoutubeBoxIcon;
