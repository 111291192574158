import React from 'react';
import cn from 'classnames';
import MuiTabs, { TabsProps } from '@material-ui/core/Tabs';
import MuiTab, { TabProps } from '@material-ui/core/Tab';
import { TabContext } from '@material-ui/lab';
import { useStyles } from './CreatorActionTabs.styles';

interface CreatorActionTabsProps {
  className?: string;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  tabs: string[];
  TabsProps?: TabsProps;
  TabProps?: TabProps;
}

export const CreatorActionTabs: React.FC<CreatorActionTabsProps> = ({
  children,
  className,
  tabs,
  activeTab,
  setActiveTab,
  TabsProps,
  TabProps,
}) => {
  const styles = useStyles();

  return (
    <div className={cn(styles.container, className)}>
      <MuiTabs
        classes={{ root: styles.tabsRoot }}
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        {...TabsProps}
      >
        {tabs.map(tab => (
          <MuiTab
            key={tab}
            classes={{
              root: styles.tabRoot,
              wrapper: styles.textWrapper,
              selected: styles.selected,
              textColorInherit: styles.textOpacity,
            }}
            label={tab}
            value={tab}
            disableRipple
            component="div"
            {...TabProps}
          />
        ))}
      </MuiTabs>
      <TabContext value={activeTab}>{children}</TabContext>
    </div>
  );
};
