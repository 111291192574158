import { RootState } from 'services/store/root-reducer';

export const selectUserPresentations = (store: RootState): RootState['configuration']['userPresentations'] => {
  return store.configuration.userPresentations;
};

export const selectDeleteUserPresentationStatus = (
  store: RootState
): RootState['configuration']['deleteUserPresentationStatus'] => {
  return store.configuration.deleteUserPresentationStatus;
};

export const selectCuratedColorSchemes = (store: RootState): RootState['configuration']['curatedColorSchemes'] => {
  return store.configuration.curatedColorSchemes;
};

export const selectUserColorSchemes = (store: RootState): RootState['configuration']['userColorSchemes'] => {
  return store.configuration.userColorSchemes;
};

export const selectFontPairs = (store: RootState): RootState['configuration']['fontPairs'] => {
  return store.configuration.fontPairs;
};

export const selectPhotoPacks = (store: RootState): RootState['configuration']['photoPacks'] => {
  return store.configuration.photoPacks;
};

export const selectSubscriptionOptions = (store: RootState): RootState['configuration']['subscriptionOptions'] => {
  return store.configuration.subscriptionOptions;
};

export const selectPresentationLayouts = (store: RootState): RootState['configuration']['presentationLayouts'] => {
  return store.configuration.presentationLayouts;
};
