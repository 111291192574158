import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
    maxWidth: 480,
  },
  signUpTitle: {
    fontSize: theme.typography.pxToRem(34),
    fontWeight: theme.typography.fontWeightSemiBold,
    letterSpacing: -1.3,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
    border: 'none',
    borderRadius: theme.shape.borderRadius,
  },
  submitButtonText: {
    textTransform: 'uppercase',
  },
  registerPromptContainer: {
    textAlign: 'center',
  },
  registerPrompt: {
    fontSize: theme.typography.pxToRem(14),
  },
  registerPromptColor: {
    color: '#757575',
  },
}));
