import cn from 'classnames';
import { SlideProps } from '../../types';
import { BoxIcon, BinocularsIcon, LegalIcon, MapMarkersIcon } from './icons';
import { useStyles } from './SlideEight.styles';
import { useRootStyles } from '../../common.styles';
import SlideNumber from '../components/SlideNumber';

const SlideEight: React.FC<SlideProps> = ({ logoUrl, colorTheme, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  return (
    <div className={cn(styles.slideCanvasWrapper, rootStyles.root)}>
      <div className={styles.slideCanvas}>
        <h1 className={styles.slideTitle}>Four Stage Pyramid</h1>
        <div className={styles.stageOne}>
          <div className={styles.pyramidDiamond}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 20"
              fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              display="block"
            >
              <g id="a"></g>
              <g id="b">
                <g id="c">
                  <path
                    d="M46.5,7.3c-2.6-2.1-6.4-3.9-10.3-5.2c-3.9-1.2-8.8-2-13.7-2.2c-5.8-0.2-9.6,0.6-13.7,2.2
			C4.4,3.9,1.2,6.2,0.3,8.7c-1,2.8,0.7,5.8,5.3,7.9c4.8,2.2,12.1,3.1,19.2,3.4c7.8,0.3,16.5-0.3,21.5-2.8
			C51.8,14.3,50.6,10.5,46.5,7.3L46.5,7.3z"
                  />
                </g>
              </g>
            </svg>
          </div>
          <img src={BinocularsIcon} loading="lazy" alt="" className={styles.pyramidIcon} />
          <div className={styles.dottedLine}></div>
          <div className={styles.shapeLabel}>
            <h1 className={styles.labelTitle}>Stage 1 Title</h1>
            <p className={styles.labelText}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.stageTwo}>
          <div className={cn(styles.pyramidDiamond, styles.pyramidDiamondTwo)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 20"
              fill={colorTheme ? colorTheme?.accent2Color : 'CurrentColor'}
              display="block"
            >
              <g id="a"></g>
              <g id="a_00000096058514823490606050000014861585066791512729_"></g>
              <g id="b">
                <g id="c">
                  <path
                    d="M46.9,3.9c-5.3-3.6-16.5-4.5-25.7-3.4C7.3,2-7.1,10.9,3.8,16.8C7.9,19,14.6,20.1,21,20
			c16-0.1,34.2-6.8,27.6-14.6C48.2,4.9,47.6,4.3,46.9,3.9L46.9,3.9z"
                  />
                </g>
              </g>
            </svg>
          </div>
          <img src={BoxIcon} loading="lazy" alt="" className={cn(styles.pyramidIcon, styles.pyramidIconTwo)} />
          <div className={cn(styles.dottedLine, styles.dottedLineDotTwo)}></div>
          <div className={cn(styles.shapeLabel, styles.shapeLabelLeft)}>
            <h1 className={styles.labelTitle}>Stage 2 Title</h1>
            <p className={styles.labelText}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.stageThree}>
          <div className={cn(styles.pyramidDiamondThree, 'w-embed')}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 20"
              fill={colorTheme ? colorTheme?.accent3Color : 'CurrentColor'}
              display="block"
            >
              <g id="a"></g>
              <g id="a_00000096058514823490606050000014861585066791512729_"></g>
              <path
                d="M38.4,1.3c-1.5-0.5-3.2-0.8-5.4-1.1C25.5-0.6,17.1,1,11.6,2.6c-6.6,2-10.8,4.9-11.5,7.9c-0.4,2.2,1,4.4,4.6,6.1
	c6.1,3,18.3,4.1,28,2.8c9.7-1.3,16.5-4.8,17.2-8.4c0.4-2.2-1.5-4.3-4.6-6.2C43.3,3.5,41.4,2.2,38.4,1.3z"
              />
            </svg>
          </div>
          <img src={LegalIcon} loading="lazy" alt="" className={cn(styles.pyramidIcon, styles.pyramidIconThree)} />
          <div className={cn(styles.dottedLine, styles.dottedLineDotThree)}></div>
          <div className={styles.shapeLabel}>
            <h1 className={styles.labelTitle}>Stage 3 Title</h1>
            <p className={styles.labelText}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.stageFour}>
          <div className={styles.pyramidDiamondFour}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 20"
              fill={colorTheme ? colorTheme?.accent4Color : 'CurrentColor'}
              display="block"
            >
              <g id="a"></g>
              <g id="a_00000096058514823490606050000014861585066791512729_"></g>
              <path
                d="M38.4,1.3c-1.5-0.5-3.2-0.8-5.4-1.1C25.5-0.6,17.1,1,11.6,2.6c-6.6,2-10.8,4.9-11.5,7.9c-0.4,2.2,1,4.4,4.6,6.1
	c6.1,3,18.3,4.1,28,2.8c9.7-1.3,16.5-4.8,17.2-8.4c0.4-2.2-1.5-4.3-4.6-6.2C43.3,3.5,41.4,2.2,38.4,1.3z"
              />
            </svg>
          </div>
          <img src={MapMarkersIcon} loading="lazy" alt="" className={cn(styles.pyramidIcon, styles.pyramidIconFour)} />
          <div className={cn(styles.dottedLine, styles.dottedLineDotFour)}></div>
          <div className={cn(styles.shapeLabel, styles.shapeLabelLeft)}>
            <h1 className={styles.labelTitle}>Stage 4 Title</h1>
            <p className={styles.labelText}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <SlideNumber slide="8" colorTheme={colorTheme} fontPair={fontPair} />
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SlideEight;
