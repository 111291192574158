import { Action } from 'modules/store/action';
import { Status } from 'modules/store/status';
import { ConfigurationActionType } from './actions';
import { ConfigurationState } from './state';

export const configurationState: ConfigurationState = {
  deleteUserPresentationStatus: Status.DEFAULT,
  userPresentations: {
    data: [],
    error: null,
    status: Status.DEFAULT,
  },
  curatedColorSchemes: {
    data: [],
    error: null,
    status: Status.DEFAULT,
  },
  userColorSchemes: {
    data: [],
    error: null,
    status: Status.DEFAULT,
  },
  fontPairs: {
    data: [],
    error: null,
    status: Status.DEFAULT,
  },
  photoPacks: {
    data: [],
    error: null,
    status: Status.DEFAULT,
  },
  subscriptionOptions: {
    data: [],
    error: null,
    status: Status.DEFAULT,
  },
  presentationLayouts: {
    data: [],
    error: null,
    status: Status.DEFAULT,
  },
};

export function configurationReducer(state = configurationState, action: Action): ConfigurationState {
  switch (action.type) {
    /**
     * FetchUserPresentations
     */
    case ConfigurationActionType.FetchUserPresentations:
      return {
        ...state,
        userPresentations: {
          ...state.userPresentations,
          error: null,
          status: Status.PENDING,
        },
      };

    case ConfigurationActionType.FetchUserPresentationsSuccess:
      return {
        ...state,
        userPresentations: {
          data: action.payload.presentations,
          error: null,
          status: Status.SUCCESS,
        },
      };

    case ConfigurationActionType.FetchUserPresentationsFailure:
      return {
        ...state,
        userPresentations: {
          ...state.userPresentations,
          error: action.payload.error,
          status: Status.FAILURE,
        },
      };

    /**
     * DeleteUserPresentation
     */
    case ConfigurationActionType.DeleteUserPresentation:
      return {
        ...state,
        deleteUserPresentationStatus: Status.PENDING,
      };

    case ConfigurationActionType.DeleteUserPresentationSuccess:
      return {
        ...state,
        deleteUserPresentationStatus: Status.SUCCESS,
      };

    case ConfigurationActionType.DeleteUserPresentationFailure:
      return {
        ...state,
        deleteUserPresentationStatus: Status.FAILURE,
      };

    /**
     * FetchCuratedColorSchemes
     */
    case ConfigurationActionType.FetchCuratedColorSchemes:
      return {
        ...state,
        curatedColorSchemes: {
          ...state.curatedColorSchemes,
          error: null,
          status: Status.PENDING,
        },
      };

    case ConfigurationActionType.FetchCuratedColorSchemesFailure:
      return {
        ...state,
        curatedColorSchemes: {
          ...state.curatedColorSchemes,
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    case ConfigurationActionType.FetchCuratedColorSchemesSuccess:
      return {
        ...state,
        curatedColorSchemes: {
          ...state.curatedColorSchemes,
          status: Status.SUCCESS,
          data: action.payload.colors,
        },
      };

    /**
     * FetchUserColorSchemes
     */
    case ConfigurationActionType.FetchUserColorSchemes:
      return {
        ...state,
        userColorSchemes: {
          ...state.userColorSchemes,
          error: null,
          status: Status.PENDING,
        },
      };

    case ConfigurationActionType.FetchUserColorSchemesFailure:
      return {
        ...state,
        userColorSchemes: {
          ...state.userColorSchemes,
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    case ConfigurationActionType.FetchUserColorSchemesSuccess:
      return {
        ...state,
        userColorSchemes: {
          ...state.userColorSchemes,
          status: Status.SUCCESS,
          data: action.payload.colors,
        },
      };

    /**
     * SaveUserColorScheme
     */
    case ConfigurationActionType.SaveUserColorScheme:
      return {
        ...state,
        userColorSchemes: {
          ...state.userColorSchemes,
          error: null,
          status: Status.PENDING,
        },
      };

    case ConfigurationActionType.SaveUserColorSchemeSuccess:
      return {
        ...state,
        userColorSchemes: {
          ...state.userColorSchemes,
          status: Status.SUCCESS,
        },
      };

    case ConfigurationActionType.SaveUserColorSchemeFailure:
      return {
        ...state,
        userColorSchemes: {
          ...state.userColorSchemes,
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * FetchFontPairs
     */
    case ConfigurationActionType.FetchFontPairs:
      return {
        ...state,
        fontPairs: {
          ...state.fontPairs,
          error: null,
          status: Status.PENDING,
        },
      };

    case ConfigurationActionType.FetchFontPairsSuccess:
      return {
        ...state,
        fontPairs: {
          data: action.payload.fontPairs,
          error: null,
          status: Status.SUCCESS,
        },
      };

    case ConfigurationActionType.FetchFontPairsFailure:
      return {
        ...state,
        fontPairs: {
          ...state.fontPairs,
          error: action.payload.error,
          status: Status.FAILURE,
        },
      };

    /**
     * FetchPhotoPacks
     */
    case ConfigurationActionType.FetchPhotoPacks:
      return {
        ...state,
        photoPacks: {
          ...state.photoPacks,
          error: null,
          status: Status.PENDING,
        },
      };

    case ConfigurationActionType.FetchPhotoPacksSuccess:
      return {
        ...state,
        photoPacks: {
          data: action.payload.photoPacks,
          error: null,
          status: Status.SUCCESS,
        },
      };

    case ConfigurationActionType.FetchPhotoPacksFailure:
      return {
        ...state,
        photoPacks: {
          ...state.photoPacks,
          error: action.payload.error,
          status: Status.FAILURE,
        },
      };

    /**
     * FetchSubscriptionOptions
     */
    case ConfigurationActionType.FetchSubscriptionOptions:
      return {
        ...state,
        subscriptionOptions: {
          ...state.subscriptionOptions,
          error: null,
          status: Status.PENDING,
        },
      };

    case ConfigurationActionType.FetchSubscriptionOptionsSuccess:
      return {
        ...state,
        subscriptionOptions: {
          data: action.payload.subscriptionOptions,
          error: null,
          status: Status.SUCCESS,
        },
      };

    case ConfigurationActionType.FetchSubscriptionOptionsFailure:
      return {
        ...state,
        subscriptionOptions: {
          ...state.subscriptionOptions,
          error: action.payload.error,
          status: Status.FAILURE,
        },
      };

    /**
     * FetchPresentationLayouts
     */
    case ConfigurationActionType.FetchPresentationLayouts:
      return {
        ...state,
        presentationLayouts: {
          ...state.presentationLayouts,
          error: null,
          status: Status.PENDING,
        },
      };

    case ConfigurationActionType.FetchPresentationLayoutsSuccess:
      return {
        ...state,
        presentationLayouts: {
          data: action.payload.layouts,
          error: null,
          status: Status.SUCCESS,
        },
      };

    case ConfigurationActionType.FetchPresentationLayoutsFailure:
      return {
        ...state,
        presentationLayouts: {
          ...state.presentationLayouts,
          error: action.payload.error,
          status: Status.FAILURE,
        },
      };

    default:
      return state;
  }
}
