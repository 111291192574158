import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  title: {
    '&&': {
      // overwrite styles with higher specificity
      marginBottom: 0,
    },
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightSemiBold,
    color: '#89898E',
    marginRight: theme.spacing(0.5),
    marginBottom: '2px',
  },
  subtitleWrapper: {
    display: 'flex',
    marginBottom: theme.spacing(5),
    alignItems: 'center',
  },

  photopacksWrapper: {
    display: 'grid',
    gridGap: theme.spacing(4.75, 0),
    gridTemplateColumns: 'auto auto',
  },
}));
