import React from 'react';
import { Fade } from '@material-ui/core';
import { useStyles } from './CreatorActionArea.styles';

export const CreatorActionArea: React.FC = ({ children }) => {
  const styles = useStyles();

  return (
    <Fade in timeout={500}>
      <div className={styles.container}>{children}</div>
    </Fade>
  );
};
