import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, Partial<SlideStyleProps>>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#fff',
  },
  galleryContainer: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: 'auto',
    bottom: 'auto',
    display: 'flex',
    width: '100%',
    height: '100%',
    paddingBottom: '7.5%',
  },
  galleryRightContainer: {
    position: 'relative',
    marginLeft: '0%',
    width: '100%',
  },
  galleryRowRound: {
    display: 'flex',
    height: '50%',
    paddingBottom: '0.3%',
  },
  galleryRowTwoRound: {
    display: 'flex',
    height: '50%',
    paddingTop: '0.3%',
  },
  galleryTitle: {
    position: 'absolute',
    left: '4%',
    top: '7.5%',
    right: 'auto',
    bottom: 'auto',
    width: '26%',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '4.6em',
    lineHeight: '110%',
  },
  galleryOneRound: {
    position: 'relative',
    left: '0%',
    top: 'auto',
    right: 'auto',
    bottom: '0%',
    width: '50%',
    height: 'auto',
    maxHeight: '100%',
    marginLeft: '0%',
    objectFit: 'cover',
  },
  galleryOneRoundCorner: {
    borderTopLeftRadius: theme.shape.borderRadius * 2,
  },
  galleryTwoRound: {
    position: 'relative',
    left: '0%',
    top: 'auto',
    right: 'auto',
    bottom: '0%',
    width: '50%',
    height: 'auto',
    maxHeight: '100%',
    marginLeft: '0.7%',
    objectFit: 'cover',
  },
  galleryTwoRoundCorner: {
    borderTopRightRadius: theme.shape.borderRadius * 2,
  },
  galleryTitleRound: {
    position: 'relative',
    left: 'auto',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    width: '70%',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '4.2em',
    lineHeight: '110%',
    textAlign: 'center',
  },
  flowGalleryTitleContainer: {
    position: 'absolute',
    top: '23%',
    display: 'flex',
    width: '100%',
    height: '40%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  galleryTitleFlow: {
    position: 'static',
    left: '33%',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    width: '34%',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '3.8em',
    lineHeight: '110%',
    textAlign: 'center',
  },
}));
