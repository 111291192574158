import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, Partial<SlideStyleProps>>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  flowTeamImage: {
    position: 'absolute',
    left: '0%',
    top: '11%',
    width: '36%',
    height: '75%',
    maxHeight: '100%',
    objectFit: 'cover',
  },
  teamTitleRound: {
    position: 'absolute',
    left: '40%',
    top: '14%',
    right: 'auto',
    bottom: 'auto',
    marginTop: '0px',
    marginBottom: '0px',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    fontSize: '5em',
    lineHeight: '110%',
  },
  teamMemberTitleRound: {
    position: 'absolute',
    left: '40%',
    top: '25.3%',
    right: 'auto',
    bottom: 'auto',
    marginTop: '0px',
    marginBottom: '0px',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
    fontSize: '2.5em',
    lineHeight: '110%',
  },
  accentLineTeamRound: {
    position: 'absolute',
    left: '40%',
    top: '34.2%',
    right: 'auto',
    bottom: 'auto',
    width: '7.5%',
    height: '1.5%',
    borderRadius: '20px',
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
  },
  teamText: {
    position: 'absolute',
    left: '40%',
    top: '36%',
    right: 'auto',
    bottom: 'auto',
    width: '56%',
    fontWeight: 400,
    fontSize: '2em',
    lineHeight: '120%',
  },
  teamTextSecond: {
    top: '56%',
  },
  svgWhiteFlowSix: {
    borderRadius: theme.shape.borderRadius * 2,
  },
}));
