import { useStyles } from './SlideNine.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';
import cn from 'classnames';
import SlideNumber from '../components/SlideNumber';

export const SlideNine: React.FC<SlideProps> = ({ colorTheme, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  const photos = photoPack;
  const imageSource: string | null = photos?.items[18].image;
  const backupImageSource: string = photos?.items[18].backupImage!;

  return (
    <div className={cn(styles.slideCanvas, rootStyles.root)}>
      <img src={imageSource ? imageSource : backupImageSource} loading="lazy" alt="" className={styles.leftImage} />
      <div className={rootStyles.svgWhite}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1600 900"
          fill="#fff"
          className={styles.svgWhiteFlowRounded}
        >
          <g id="a"></g>
          <path
            d="M0,0v179.8c40.2,4.6,79.6,4,117.3-10.9c54.5-21.5,93.9-69.4,144.8-98.3c119.8-67.9,292.7-3.5,324.2,136.1
	c20,88.9-19.1,178,1.5,267.9c18.6,81.4,66.6,168.1,35.5,252.3C589.8,817.1,485.5,847.5,402,900h1198V0H0z"
          />
        </svg>
      </div>
      <div className={rootStyles.svgAccentOne}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1600 900"
          fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
        >
          <g id="a"></g>
          <g id="b">
            <g id="c">
              <path
                d="M496.2,259.5c19.6,80,120.4,71.9,168.4,27.7c25.1-23.1,40.9-58,42.5-93.9c1.7-36.3-13.5-75.9-44-90.1
			c-22.6-10.6-48.7-5.4-71.8,3.8c-29,11.6-62,14.9-80.8,41.7C491.8,175.6,488.2,226.8,496.2,259.5L496.2,259.5z"
              ></path>
            </g>
          </g>
        </svg>
      </div>
      <div className={rootStyles.svgWhite}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900" fill="#fff" className={styles.svgWhiteRounded}>
          <g id="a"></g>
          <g id="b_00000084500343047189640410000011526460232599583126_">
            <g id="c_00000182510017742002548160000000687784927192738946_">
              <path
                d="M555.2,239.6c-4-6.1-6.1-13.2-6.1-21.2c0-11.1,3-20.2,8.1-29.3c6.1-9.1,14.2-16.2,25.3-21.2l3,6.1
			c-7.1,3-12.1,7.1-17.2,13.2c-5.1,6.1-7.1,12.1-7.1,18.2c0,3,0,5.1,1,7.1c3-3,7.1-4,12.1-4s10.1,2,14.2,6.1c4,4,6.1,9.1,6.1,15.2
			c0,4-1,7.1-3,11.1c-2,2-4,5.1-7.1,7.1c-3,1-7.1,2-10.1,2C565.3,249.7,559.2,246.7,555.2,239.6L555.2,239.6z M609.8,239.6
			c-3-6.1-5.1-13.2-5.1-21.2c0-11.1,3-20.2,8.1-29.3c6.1-9.1,14.2-16.2,25.3-21.2l3,6.1c-7.1,3-12.1,7.1-17.2,13.2
			c-5.1,6.1-8.1,12.1-8.1,18.2c0,3,0,5.1,1,7.1c4-3,8.1-4,12.1-4c6.1,0,11.1,2,15.2,6.1c4,3,6.1,8.1,6.1,15.2c0,6.1-2,11.1-6.1,15.2
			c-4,3-9.1,5.1-15.2,5.1C621,249.7,614.9,246.7,609.8,239.6L609.8,239.6z"
              ></path>
            </g>
          </g>
        </svg>
      </div>
      <p className={styles.quoteTextEdge}>
        A hundred times every day I remind myself that my inner and outer life are based on the labors of other men,
        living and dead, and that I must exert myself in order to give in the same measure as I have received and am
        still receiving.
      </p>
      <h1 className={styles.quoteAuthorEdge}>Albert Einstein</h1>
      <SlideNumber slide="9" colorTheme={colorTheme} fontPair={fontPair} />
    </div>
  );
};

export default SlideNine;
