import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  actionTabs: {
    marginBottom: theme.spacing(5),
  },
  tabPanelRoot: {
    padding: 0,
  },
  colorAreaContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
