import React from 'react';
import { ColorTheme } from 'components/CreatorPreview/templates/types';

const FacebookBoxIcon: React.FC<ColorTheme> = ({ colorTheme }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 342.9 342.9"
      fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
      style={{ borderRadius: '100%', width: '16%', height: '100%' }}
    >
      <path
        d="M319,125.9L319,125.9c-18.1-35.7-44.2-67.5-70.7-88.4c-27-21.3-60.2-34.9-94-37.1C114.5-2.3,88.7,11,60.1,38.2
	C30,67,8.2,105.9,2,148.4c-7,47.9,4.8,99.6,36.1,135.2c33,37.7,83.2,53.7,131.7,58.2c53.4,4.9,113-5.4,147.3-48.6
	C355.5,244.9,346.7,180.7,319,125.9z M211.2,170.1h-26.9v96h-39.9v-96h-19v-33.9h19v-22c0-15.7,7.5-40.3,40.3-40.3l29.6,0.1v33
	h-21.5c-3.5,0-8.5,1.7-8.5,9.2v19.9h30.4L211.2,170.1z"
      />
    </svg>
  );
};

export default FacebookBoxIcon;
