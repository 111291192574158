export enum Status {
  DEFAULT = 'default',
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export const isStatusDefault = (status: Status | undefined): status is typeof Status.DEFAULT =>
  status === Status.DEFAULT;
export const isStatusPending = (status: Status | undefined): status is typeof Status.PENDING =>
  status === Status.PENDING;
export const isStatusSuccess = (status: Status | undefined): status is typeof Status.SUCCESS =>
  status === Status.SUCCESS;
export const isStatusFailure = (status: Status | undefined): status is typeof Status.FAILURE =>
  status === Status.FAILURE;
