import { useStyles } from './SlideSixth.styles';
import { SlideProps } from '../../types';
import headshotImage from './images/headshot2.jpg';
import { useRootStyles } from '../../common.styles';
import cn from 'classnames';

export const SlideSixth: React.FC<SlideProps> = ({ logoUrl, colorTheme, fontPair }) => {
  const styles = useStyles({ fontPair, colorTheme });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <div className={styles.teamBlock} />
        <img src={headshotImage} loading="lazy" alt="" className={styles.halfBackgroundImage} />
        <div className={styles.halfContainer} />
        <div className={rootStyles.svgWhite}>
          <svg className={styles.svgWhiteSixth} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
            <polygon fill="#fff" points="1600,0 800.2,0 604.6,900 1600,900 "></polygon>
          </svg>
        </div>
        <div className={rootStyles.svgAccentOne}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
            <polygon
              fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              points="706.6,80.6 672.7,237 1466,237 1500,80.6 "
            ></polygon>
          </svg>
        </div>
        <div className={rootStyles.svgDark}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
            <polygon
              fill={colorTheme ? colorTheme?.textColor : 'CurrentColor'}
              points="672.7,237 655.3,317.2 1257.1,317.2 1274.6,237 "
            ></polygon>
          </svg>
        </div>
        <h1 className={styles.teamTitleEdge}>Jane Smith</h1>
        <h2 className={styles.teamMemberTitleEdge}>Chief Executive Officer</h2>
        <p className={styles.teamTextEdge}>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula egetdolor. Aenean massa. Cum
          sociis natoque. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa
          quis enim.
        </p>
        <p className={cn(styles.teamTextEdge, styles.teamTextSecondEdge)}>
          In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pedemollis pretium.
          Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.
        </p>
        <div className={rootStyles.slideNumberEdge}>
          <div className={rootStyles.edgeSlideNumberShape}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 104" display="block">
              <polygon
                fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
                points="22.5,0 0,104 82.5,104 105,0 "
              ></polygon>
            </svg>
          </div>
          <h1 className={cn(rootStyles.number, rootStyles.numberEdge)}>6</h1>
        </div>
      </div>
    </>
  );
};

export default SlideSixth;
