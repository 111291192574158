import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  appBarHeight: {
    height: 110,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    minWidth: 770,
    paddingRight: theme.spacing(6.25),
    paddingLeft: theme.spacing(5),
  },
  sharpslideLogo: {
    cursor: 'pointer',
  },
  navigation: {
    marginRight: theme.spacing(5),
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  actionPrompt: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.utility.gray3,
    marginRight: theme.spacing(3.5),
  },
}));
