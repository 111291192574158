import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isStatusFailure, isStatusPending } from 'modules/store/status';
import { AuthAction } from './actions';
import {
  selectUser,
  selectLoginState,
  selectRegisterState,
  selectLogoutState,
  selectDeletionState,
  selectResetPasswordState,
} from './selectors';
import { UserCredentials, UserPremiumStatus, UserPremiumStatusV2 } from './types';

export const useAuthManagement = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const loginState = useSelector(selectLoginState);
  const registerState = useSelector(selectRegisterState);
  const logoutState = useSelector(selectLogoutState);
  const deletionState = useSelector(selectDeletionState);
  const resetPasswordState = useSelector(selectResetPasswordState);

  const { stripeRole, lifetime } = user.claims;
  // NOTE: Those are old prcing sunbscription plans, as long as we have users on those we need to keep them
  const isAnnualSubscriber = stripeRole === UserPremiumStatus.ANNUAL;
  const isMonthlySubscriber = stripeRole === UserPremiumStatus.MONTHLY;
  // NOTE: Those are new pricing one-time paypemnt plans
  const isOneWeekPremium = stripeRole === UserPremiumStatusV2.ONE_WEEK;
  const isOneYearPremium = stripeRole === UserPremiumStatusV2.ONE_YEAR;
  const isLifetimePremium = stripeRole === UserPremiumStatusV2.LIFETIME;
  // NOTE: This is a custom lifetime plan, which is not available in the Stripe pricing plans
  // it is used for special users, we can grant them via CMS
  const isCustomLifetime = lifetime;

  const isPremiumUser =
    isAnnualSubscriber ||
    isMonthlySubscriber ||
    isCustomLifetime ||
    isOneWeekPremium ||
    isOneYearPremium ||
    isLifetimePremium;

  const isLoading =
    isStatusPending(loginState.status) ||
    isStatusPending(registerState.status) ||
    isStatusPending(logoutState.status) ||
    isStatusPending(resetPasswordState.status);
  const isErrored = isStatusFailure(loginState.status) || isStatusFailure(registerState.status);

  const startAuthObserver = useCallback(() => {
    dispatch(AuthAction.startAuthObserver());
  }, [dispatch]);

  const loginUser = useCallback(
    (credentials: UserCredentials) => {
      dispatch(AuthAction.loginUser(credentials));
    },
    [dispatch]
  );

  const registerUser = useCallback(
    (credentials: UserCredentials) => {
      dispatch(AuthAction.registerUser(credentials));
    },
    [dispatch]
  );

  const logoutUser = useCallback(() => {
    dispatch(AuthAction.logoutUser());
  }, [dispatch]);

  const deleteUser = useCallback(() => {
    dispatch(AuthAction.deleteUser());
  }, [dispatch]);

  const resetUserPassword = useCallback(
    (email: UserCredentials['email']) => {
      dispatch(AuthAction.resetUserPassword(email));
    },
    [dispatch]
  );

  const changeUserEmail = useCallback(
    (newEmail: UserCredentials['email']) => {
      dispatch(AuthAction.changeUserEmail(newEmail));
    },
    [dispatch]
  );

  const changeUserPassword = useCallback(
    (newPassword: UserCredentials['password']) => {
      dispatch(AuthAction.changeUserPassword(newPassword));
    },
    [dispatch]
  );

  return {
    isLoading,
    isErrored,

    user: user.data,
    userClaims: user.claims,
    authStatus: user.authStatus,
    error: user.error,
    deleteError: deletionState.error,
    isPremiumUser,
    isAnnualSubscriber,
    isMonthlySubscriber,
    isOneWeekPremium,
    isOneYearPremium,
    isLifetimePremium,
    isCustomLifetime,

    startAuthObserver,
    loginUser,
    registerUser,
    logoutUser,
    deleteUser,
    resetUserPassword,
    changeUserEmail,
    changeUserPassword,
  };
};
