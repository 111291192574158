import React, { useEffect } from 'react';
import type { RouteComponentProps } from 'react-router';
import { Switch, Redirect } from 'react-router-dom';
import { ProtectedRoute } from 'components';
import { routes } from 'const';
import { DashboardContainer, CreatorContainer, AccountContainer } from '../';
import 'services/translations';
import { useConfigurationManagement } from 'modules/configuration/hooks';
import { Box, CircularProgress } from '@material-ui/core';
import { Status } from 'modules/store/status';

interface AppContainerProps extends RouteComponentProps {}

export const AppContainer: React.FC<AppContainerProps> = () => {
  const { fetchPresentationLayouts, presentationLayoutsStatus } = useConfigurationManagement();

  useEffect(() => {
    fetchPresentationLayouts();
  }, [fetchPresentationLayouts]);

  if (presentationLayoutsStatus !== Status.SUCCESS) {
    return (
      <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={100} thickness={2.5} />
      </Box>
    );
  }

  return (
    <Switch>
      <ProtectedRoute exact path={routes.dashboard.path} component={DashboardContainer} />
      <ProtectedRoute exact path={routes.account.path} component={AccountContainer} />
      <ProtectedRoute path={routes.creator.base.path} component={CreatorContainer} />

      <Redirect to={{ pathname: routes.dashboard.path }} />
    </Switch>
  );
};
