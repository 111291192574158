import cn from 'classnames';
import { useStyles } from './SlideEleven.styles';
import { useRootStyles } from '../../common.styles';
import { SlideProps } from '../../types';
import square from './images/square.svg';
import { dates, lines } from './SlideEleven.const';
import { theme } from 'theme';
import SlideNumber from '../components/SlideNumber';

export const SlideEleven: React.FC<SlideProps> = ({ logoUrl, colorTheme, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const series = [
    {
      label: 'Series 1',
      color: colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1,
    },
    {
      label: 'Series 2',
      color: colorTheme ? colorTheme.accent2Color : theme.palette.slide.accent2,
    },
    {
      label: 'Series 3',
      color: colorTheme ? colorTheme.accent3Color : theme.palette.slide.accent3,
    },
    {
      label: 'Series 4',
      color: colorTheme ? colorTheme.accent4Color : theme.palette.slide.accent4,
    },
  ];
  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <h1 className={styles.slideTitle}>Area Chart</h1>
        <h2 className={styles.slideSubtitle}>Chart Title</h2>
        <div className={styles.chartLegend}>
          {series.map(serie => {
            return (
              <div className={styles.legendItem} key={serie.label}>
                <div className={styles.legendSymbol} style={{ backgroundColor: serie.color }}>
                  <img src={square} loading="lazy" alt="square" className={styles.makeSquare} />
                </div>
                <h2 className={styles.legendLabel}>{serie.label}</h2>
              </div>
            );
          })}
        </div>
        <div className={styles.chartLine}>
          {lines.map(line => {
            return (
              <div className={styles.lineContainer} key={line.label}>
                <h2 className={styles.lineLabel}>{line.label}</h2>
                <div className={styles.line} />
              </div>
            );
          })}
        </div>
        <div className={styles.area}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 811.48 231.53"
            fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
          >
            <g id="a">
              <g id="b">
                <g id="c">
                  <polygon points="0 154.45 0 231.53 811.48 231.53 811.48 0 738.54 25.79 662.96 38.78 590.21 51.58 516.59 64.38 442.16 141.55 370.07 90.53 293.65 77.18 221.18 141.36 147.48 128.66 0 154.45"></polygon>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={styles.area}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 811.48 205.74"
            fill={colorTheme ? colorTheme?.accent2Color : 'CurrentColor'}
          >
            <g id="a">
              <g id="b">
                <g id="c">
                  <polygon points="0 167.2 0 205.74 811.48 205.74 811.48 0 738.54 38.45 662.96 64.74 590.21 102.49 516.59 115.72 442.16 141.04 370.07 102.86 293.65 102.86 221.18 141.27 147.48 141.27 0 167.2"></polygon>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={styles.area}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 811.48 141.53"
            fill={colorTheme ? colorTheme?.accent3Color : 'CurrentColor'}
          >
            <g id="a">
              <g id="b">
                <g id="c">
                  <polygon points="0 115.55 0 141.53 811.48 141.53 811.48 0 738.54 25.84 662.96 51.6 516.59 77.08 442.16 102.99 370.07 64.45 293.65 64.45 221.18 89.93 147.48 90 0 115.55"></polygon>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={styles.area}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 811.48 77.08"
            fill={colorTheme ? colorTheme?.accent4Color : 'CurrentColor'}
          >
            <g id="a">
              <g id="b">
                <g id="c">
                  <polygon points="0 64 0 77.08 811.48 77.08 811.48 0 738.54 12.52 662.96 12.61 442.16 51.01 370.07 38.96 293.65 38.96 221.18 51.11 147.48 38.4 0 64"></polygon>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <h1 className={styles.xAxisLabel}>X Axis Title</h1>
        <h1 className={styles.yAxisLabel}>Y Axis Title</h1>
        {dates.map(date => {
          return (
            <h2 className={styles.date} key={date.label} style={{ left: date.left }}>
              {date.label}
            </h2>
          );
        })}
        <SlideNumber slide="11" colorTheme={colorTheme} fontPair={fontPair} />
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
      </div>
    </>
  );
};

export default SlideEleven;
