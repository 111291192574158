import { LinearProgress, Modal, Typography } from '@material-ui/core';
import { Loader } from 'components/Loader';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './CreatorPreviewModal.styles';

interface CreatorPreviewModalProps {
  onOpen: boolean;
  onClose: () => boolean;
  imagesToLoadCount: number;
  previewImagesCount: number;
  pendingPresentationStatus: boolean;
}

const CreatorPreviewModal = ({
  onOpen,
  onClose,
  imagesToLoadCount,
  previewImagesCount,
  pendingPresentationStatus,
}: CreatorPreviewModalProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [countLoadedImages, setCountLoadedImages] = useState(0);

  useEffect(() => {
    setCountLoadedImages(previewImagesCount - imagesToLoadCount);
  }, [previewImagesCount, imagesToLoadCount]);

  const loadedImagesProgress = Math.round((100 / previewImagesCount) * countLoadedImages);

  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      aria-labelledby="title"
      aria-describedby="desc"
      BackdropProps={{
        className: styles.previewModalBackdrop,
      }}
    >
      <div className={styles.previewModal}>
        {!pendingPresentationStatus ? (
          <>
            <Typography id="title" color="primary" variant="h6" component="h2" className={styles.previewModalTitle}>
              {t('creatorComprehensivePreviewModal.titleUploading')}
            </Typography>
            <Typography id="desc" variant="h6" component="p" className={styles.previewModalDescription}>
              {t('creatorComprehensivePreviewModal.descriptionUploading')}
            </Typography>
            <LinearProgress
              classes={{ root: styles.uploadProgressBarRoot }}
              variant="determinate"
              value={loadedImagesProgress}
            />
          </>
        ) : (
          <>
            <Typography id="title" color="primary" variant="h6" component="h2" className={styles.previewModalTitle}>
              {t('creatorComprehensivePreviewModal.titleLoading')}
            </Typography>
            <Typography id="desc" variant="h6" component="p" className={styles.previewModalDescription}>
              {t('creatorComprehensivePreviewModal.descriptionLoading')}
            </Typography>
            <Loader loading={pendingPresentationStatus} />
          </>
        )}
      </div>
    </Modal>
  );
};

export default CreatorPreviewModal;
