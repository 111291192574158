import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const UploadIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="40" height="33" viewBox="0 0 40 33" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(0.012 -1.997)">
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          y2="15"
          transform="translate(19.995 19.002)"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          d="M34.4,29.505a8.612,8.612,0,0,0-4.116-16.173h-2.17A13.779,13.779,0,1,0,4.449,25.906"
          transform="translate(0)"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          d="M21.779,18.889,14.889,12,8,18.889"
          transform="translate(5.06 6.5)"
        />
      </g>
    </SvgIcon>
  );
};
