import cn from 'classnames';
import { SlideProps } from '../../types';
import { useStyles } from './SlideFourth.styles';
import { useRootStyles } from '../../common.styles';
import SlideNumber from '../components/SlideNumber';

export const SlideFourth: React.FC<SlideProps> = ({ colorTheme, photoPack, fontPair }) => {
  const styles = useStyles({ fontPair, colorTheme });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[19].image;
  const backupImageSource: string = photos?.items[19].backupImage!;

  return (
    <div className={cn(styles.slideCanvas, rootStyles.root)}>
      <img
        src={imageSource ? imageSource : backupImageSource}
        loading="lazy"
        alt=""
        className={styles.halfBackgroundImage}
      />
      <div className={cn(styles.backgroundOverlay, styles.half)} />
      <div className={styles.halfContainer}>
        <h1 className={styles.transition}>Agenda</h1>
      </div>
      <div className={rootStyles.svgWhite}>
        <svg
          className={styles.svgWhiteFourth}
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1600 900"
        >
          <path fill="#fff" d="M683.2,0C757.6,133.2,800,286.6,800,450s-42.4,316.8-116.8,450H1600V0H683.2z"></path>
        </svg>
      </div>
      <div className={styles.rightHalfClearContainer}>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>1)</h2>
          <h2 className={styles.agendaText}>Lorem ipsum dolor amet</h2>
        </div>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>2)</h2>
          <h2 className={styles.agendaText}>Aenean commodo</h2>
        </div>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>3)</h2>
          <h2 className={styles.agendaText}>Nullam dictum felis</h2>
        </div>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>4)</h2>
          <h2 className={styles.agendaText}>In enim justo</h2>
        </div>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>5)</h2>
          <h2 className={styles.agendaText}>Vivamus elementum</h2>
        </div>
      </div>
      <SlideNumber slide="4" colorTheme={colorTheme} fontPair={fontPair} />
    </div>
  );
};

export default SlideFourth;
