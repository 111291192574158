import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  accountSubtitle: {
    fontSize: theme.typography.pxToRem(23),
    color: '#757575',
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.75),
  },
  divider: {
    marginTop: theme.spacing(3),
  },
}));
