import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomizationIcon, DesignIcon, GoogleIcon, CloseIcon } from 'assets/icons';
import { Dialog, Box, Typography, IconButton } from '@material-ui/core';
import { Button } from 'components';
import { ConsultPerk } from './components';
import { useStyles } from './ConsultDialog.styles';

const perks = [
  {
    icon: <CustomizationIcon />,
    title: 'creatorDownload.consultDialog.perk1.title',
    description: 'creatorDownload.consultDialog.perk1.description',
  },
  {
    icon: <DesignIcon />,
    title: 'creatorDownload.consultDialog.perk2.title',
    description: 'creatorDownload.consultDialog.perk2.description',
  },
  {
    icon: <GoogleIcon />,
    title: 'creatorDownload.consultDialog.perk3.title',
    description: 'creatorDownload.consultDialog.perk3.description',
  },
];

interface ConsultDialogProps {
  isDialogOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onPermanentClose: () => void;
}

export const ConsultDialog: React.FC<ConsultDialogProps> = ({ isDialogOpen, onClose, onConfirm, onPermanentClose }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={isDialogOpen}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{ className: styles.paper, elevation: 0 }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Box className={styles.container}>
        <IconButton className={styles.closeIcon} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <Typography className={styles.header}>{t('creatorDownload.consultDialog.header')}</Typography>
        <Typography className={styles.heading}>{t('creatorDownload.consultDialog.heading')}</Typography>

        <Box className={styles.innerContainer}>
          <Box className={styles.perksContainer}>
            {perks.map(({ icon, title, description }, index) => (
              <ConsultPerk key={index} icon={icon} title={t(title)} description={t(description)} />
            ))}
          </Box>

          <Box className={styles.actions}>
            <Button variant="contained" onClick={onConfirm}>
              {t('creatorDownload.consultDialog.confirm')}
            </Button>
            <Typography className={styles.dismissText} onClick={onPermanentClose}>
              {t('creatorDownload.consultDialog.cancel')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
