import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, Partial<SlideStyleProps>>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    position: 'relative',
    objectFit: 'cover',
    OObjectFit: 'cover',
    height: '100%',
    width: '100%',
    borderRadius: theme.shape.borderRadius * 2,
  },
  accentLine: {
    position: 'absolute',
    left: '6%',
    top: '25.2%',
    right: 'auto',
    bottom: 'auto',
    width: '7.5%',
    height: '1.5%',
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
  },
  titleTwoBlock: {
    position: 'absolute',
    left: '0%',
    top: 'auto',
    right: '0%',
    bottom: '23%',
    width: '58%',
    height: '58%',
    padding: '0px',
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
    opacity: '0.75',
  },
  titleTwo: {
    position: 'absolute',
    left: '6%',
    top: '28.5%',
    right: 'auto',
    bottom: 'auto',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '5.5em',
    lineHeight: '110%',
    width: '44%',
  },
  subtitleTwo: {
    position: 'absolute',
    left: '6%',
    top: '58%',
    right: 'auto',
    bottom: 'auto',
    width: '47%',
    marginTop: '0',
    marginBottom: '0',
    color: '#fff',
    fontSize: '2em',
    lineHeight: '110%',
  },
}));
