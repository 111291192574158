import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const PreviewIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.432,11.414C19.462,6.839,15.755,4,11.755,4S4.049,6.839,2.078,11.414a.976.976,0,0,0,0,.78c1.971,4.575,5.678,7.414,9.677,7.414s7.707-2.839,9.677-7.414A.976.976,0,0,0,21.432,11.414Zm-9.677,6.243c-3.092,0-6.019-2.234-7.707-5.853,1.688-3.619,4.614-5.853,7.707-5.853s6.019,2.234,7.707,5.853C17.774,15.423,14.848,17.657,11.755,17.657Zm0-9.755a3.9,3.9,0,1,0,3.9,3.9A3.9,3.9,0,0,0,11.755,7.9Zm0,5.853A1.951,1.951,0,1,1,13.706,11.8,1.951,1.951,0,0,1,11.755,13.755Z"
        transform="translate(-2 -2)"
      />
    </SvgIcon>
  );
};
