import cn from 'classnames';
import { useStyles } from './SlideTwelth.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';
import { FacebookIcon, InstaIcon, TwitterIcon, YoutubeIcon } from './icons';
import SlideNumber from '../components/SlideNumber';

const SlideTwelth: React.FC<SlideProps> = ({ logoUrl, colorTheme, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <div className={styles.accentLineSocialRounded} />
        <h1 className={styles.leftTitle}>Follow Us on Social Media</h1>
        <h2 className={styles.leftSubtitle}>Subtitle Text</h2>
        <div className={styles.socialBackgroundRound}>
          <div className={styles.socialRow}>
            <TwitterIcon colorTheme={colorTheme} />
            <div className={styles.socialTextBlock}>
              <h1 className={styles.socialPlatformRound}>Twitter</h1>
              <h2 className={styles.socialUrlDark}>https://twitter.com/name</h2>
            </div>
          </div>
          <div className={styles.socialRow}>
            <FacebookIcon colorTheme={colorTheme} />
            <div className={styles.socialTextBlock}>
              <h1 className={styles.socialPlatformRound}>Facebook</h1>
              <h2 className={styles.socialUrlDark}>https://facebook.com/name</h2>
            </div>
          </div>
          <div className={styles.socialRow}>
            <InstaIcon colorTheme={colorTheme} />
            <div className={styles.socialTextBlock}>
              <h1 className={styles.socialPlatformRound}>Instagram</h1>
              <h2 className={styles.socialUrlDark}>https://instagram.com/name</h2>
            </div>
          </div>
          <div className={styles.socialRow}>
            <YoutubeIcon colorTheme={colorTheme} />
            <div className={styles.socialTextBlock}>
              <h1 className={styles.socialPlatformRound}>Youtube</h1>
              <h2 className={styles.socialUrlDark}>https://youtube.com/channel/name</h2>
            </div>
          </div>
        </div>
        <SlideNumber slide="12" colorTheme={colorTheme} fontPair={fontPair} />
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
      </div>
    </>
  );
};

export default SlideTwelth;
