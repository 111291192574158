import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(7.5),
  },
  messageIcon: {
    width: 100,
    height: 80,
    fill: theme.palette.utility.gray2,
  },
  messageText: {
    marginTop: theme.spacing(2),
  },
}));
