import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, Partial<SlideStyleProps>>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    backgroundColor: '#fff',
  },
  backgroundOverlay: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    height: 'auto',
    padding: '0px',
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    opacity: 0.75,
  },
  halfBackgroundImage: {
    position: 'relative',
    objectFit: 'cover',
    width: '50%',
    height: '100%',
    maxHeight: '100%',
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
  },
  backgroundOverlayAgendaFlow: {
    left: '6%',
    top: '7%',
    width: '45%',
    height: '88%',
    opacity: 0.5,
  },
  halfContainer: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    display: 'flex',
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightHalfClearContainer: {
    position: 'absolute',
    left: 'auto',
    top: '0%',
    right: '0%',
    bottom: '0%',
    display: 'flex',
    width: '50%',
    padding: '6% 6% 6% 6%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  transitionFlow: {
    position: 'absolute',
    left: 'auto',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '5.5em',
    lineHeight: '110%',
    textAlign: 'center',
  },
  flowAgendaTextContainer: {
    position: 'absolute',
    left: '10%',
    top: '24%',
    right: '0%',
    bottom: '0%',
    display: 'flex',
    width: '35%',
    height: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  agendaItem: {
    display: 'flex',
    width: '100%',
    marginBottom: '7%',
    alignItems: 'flex-start',
  },
  agendaNumber: {
    position: 'relative',
    width: '12%',
    marginTop: 0,
    marginBottom: 0,
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    fontSize: '2.5em',
    lineHeight: '110%',
  },
  agendaText: {
    position: 'relative',
    width: '88%',
    marginTop: 0,
    marginBottom: 0,
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
    fontSize: '2.5em',
    lineHeight: '110%',
  },
  svgWhiteFourth: {
    borderRadius: theme.shape.borderRadius * 2,
  },
}));
