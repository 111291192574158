import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const LogoUploadIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.247,6.77a4.292,4.292,0,0,0-3.229-.312,4.263,4.263,0,0,0-8.516,0,4.37,4.37,0,0,0-3.229.283,4.263,4.263,0,0,0,.6,7.8,4.292,4.292,0,0,0-.78,2.439,4.878,4.878,0,0,0,.068.732,4.234,4.234,0,0,0,4.185,3.541,4.565,4.565,0,0,0,.624,0,4.312,4.312,0,0,0,2.79-1.688,4.312,4.312,0,0,0,2.79,1.649,4.565,4.565,0,0,0,.624,0,4.253,4.253,0,0,0,3.473-6.7,4.253,4.253,0,0,0,.6-7.8ZM10.136,4.878a2.341,2.341,0,0,1,3.239,0,2.371,2.371,0,0,1,.507,2.595l-.254.576-.644.566A3.97,3.97,0,0,0,11.755,8.4a3.9,3.9,0,0,0-1.571.332L9.638,7.473a2.332,2.332,0,0,1,.5-2.595ZM4.185,11.55A2.312,2.312,0,0,1,7.794,8.838l.478.419.341.78A3.824,3.824,0,0,0,7.853,12.3a2.78,2.78,0,0,0,0,.439H7.316l-.819.078A2.312,2.312,0,0,1,4.185,11.55Zm6.438,5.931A2.322,2.322,0,0,1,6.078,17.4a3,3,0,0,1,0-.41,2.273,2.273,0,0,1,1.141-1.951l.5-.351.888-.1a3.9,3.9,0,0,0,2.322,1.532Zm1.132-3.229A1.951,1.951,0,1,1,13.706,12.3,1.951,1.951,0,0,1,11.755,14.253Zm5.678,3.141a2.3,2.3,0,0,1-2.614,1.893,2.332,2.332,0,0,1-1.951-1.8l-.137-.585.2-.9a3.9,3.9,0,0,0,2.146-1.717l.488.293h.088l.644.38a2.322,2.322,0,0,1,1.132,2.439Zm1.893-5.853a2.332,2.332,0,0,1-2.078,1.3h-.234l-.624-.1-.732-.4h0a3.9,3.9,0,0,0-.976-2.575L15.1,9.4h0l.615-.566a2.312,2.312,0,0,1,3.609,2.751Z"
        transform="translate(-1.993 -2.399)"
      />
    </SvgIcon>
  );
};
