import { Link } from '@material-ui/core';
import { AccountSubtitle } from 'components/AccountSubtitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './AccountDelete.styles';
import { FormDialog } from 'components/FormDialog';
import { useAuthManagement } from 'modules/auth/hooks';
import { useSharedManagement } from 'modules/shared/hooks';

export const AccountDelete = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { deleteUser, user, deleteError } = useAuthManagement();
  const { notifyUser } = useSharedManagement();

  const [openModal, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const handleSubmit = (userMail: string) => {
    if (user!.email !== userMail) {
      setErrorMessage(t('account.deleteAccount.wrongMail'));
      return;
    }

    deleteUser();
    setModalOpen(false);
  };

  useEffect(() => {
    if (deleteError?.code === 'auth/requires-recent-login')
      notifyUser(t('account.deleteAccount.sessionError'), 'error');
    else if (deleteError) notifyUser(t('account.deleteAccount.tryAgain'), 'error');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteError]);

  return (
    <div className={styles.container}>
      <AccountSubtitle subtitle={t('account.subtitle.deleteAccount')} />
      <Link onClick={() => setModalOpen(true)} className={styles.deleteLink}>
        {t('account.button.deletePerm')}
      </Link>
      <FormDialog
        open={openModal}
        setModalOpen={setModalOpen}
        handleSubmit={handleSubmit}
        dialogTitle={t('account.deleteAccount.title')}
        dialogContextText={t('account.deleteAccount.deleteConfirm')}
        textFieldLabel={t('account.deleteAccount.email')}
        error={errorMessage}
      />
    </div>
  );
};
