import React from 'react';
import { ColorTheme } from 'components/CreatorPreview/templates/types';

const YoutubeBoxIcon: React.FC<ColorTheme> = ({ colorTheme }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 342.9 342.9"
      fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
      style={{ borderRadius: '100%', width: '16%', height: '100%' }}
    >
      <g>
        <polygon points="143.8,210 211.1,171.7 143.8,133.4 	" />
        <path
          d="M171.5,0C76.8,0,0,76.8,0,171.4s76.8,171.5,171.5,171.5s171.5-76.8,171.5-171.5S266.1,0,171.5,0z M255.6,213.5
        c-1.9,7.7-7.8,13.8-15.5,16c-13.7,3.7-68.7,3.7-68.7,3.7s-54.9,0-68.7-3.7c-7.5-2.1-13.3-7.8-15.5-15.3
        c-2.6-14.1-3.8-28.3-3.7-42.6c0-14.1,1.2-28.3,3.7-42.2c1.9-7.7,7.8-13.8,15.5-16c13.7-3.7,68.7-3.7,68.7-3.7s54.9,0,68.7,3.4
        c7.7,2.2,13.6,8.2,15.5,16c2.6,14.1,3.8,28.3,3.7,42.6C259.4,185.7,258.1,199.7,255.6,213.5z"
        />
      </g>
    </svg>
  );
};

export default YoutubeBoxIcon;
