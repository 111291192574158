import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 26,
  },
  title: {
    fontSize: theme.typography.pxToRem(56),
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.primary.main,
    letterSpacing: -1.8,
    lineHeight: 1.2,
  },
  infoIcon: {
    position: 'absolute',
    fontSize: theme.typography.pxToRem(20),
    marginLeft: 12,
    marginTop: 16,
  },
}));
