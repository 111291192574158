import { createAction } from 'services/store/create-action';
import type firebase from 'firebase';
import type { UserAuthStatus, UserClaims, UserCredentials } from './types';

export enum AuthActionType {
  StartAuthObserver = '[Auth]: Start auth observer',
  StartAuthObserverFailure = '[Auth]: Start auth observer - failure',
  AuthObserverEmission = '[Auth]: Auth observer emission',

  SetUserAuthStatus = '[Auth]: Set user auth status',
  SetUserClaims = '[Auth]: Set user claims',

  LoginUser = '[Auth]: Login user',
  LoginUserSuccess = '[Auth]: Login user - success',
  LoginUserFailure = '[Auth]: Login user - failure',

  RegisterUser = '[Auth]: Register user',
  RegisterUserSuccess = '[Auth]: Register user - success',
  RegisterUserFailure = '[Auth]: Register user - failure',

  LogoutUser = '[Auth]: Logout user',
  LogoutUserSuccess = '[Auth]: Logout user - success',
  LogoutUserFailure = '[Auth]: Logout user - failure',

  DeleteUser = '[Auth] Delete user',
  DeleteUserSuccess = '[Auth] Delete user - success',
  DeleteUserFailure = '[Auth] Delete user - failure',

  ResetUserPassword = '[Auth] Reset user password',
  ResetUserPasswordSuccess = '[Auth] Reset user password - success',
  ResetUserPasswordFailure = '[Auth] Reset user password - failure',
  ChangeUserEmail = '[Auth] Change user email',
  ChangeUserEmailSuccess = '[Auth] Change user email - success',
  ChangeUserEmailFailure = '[Auth] Change user mail - failure',

  ChangeUserPassword = '[Auth] Change user password',
  ChangeUserPasswordSuccess = '[Auth] Change user password - success',
  ChangeUserPasswordFailure = '[Auth] Change user password - failure',
}

export const AuthAction = {
  startAuthObserver: () => createAction(AuthActionType.StartAuthObserver),
  startAuthObserverFailure: (error: firebase.FirebaseError) =>
    createAction(AuthActionType.StartAuthObserverFailure, { error }),
  authObserverEmission: (user: firebase.User | null) => createAction(AuthActionType.AuthObserverEmission, { user }),

  setUserAuthStatus: (userAuthStatus: UserAuthStatus) =>
    createAction(AuthActionType.SetUserAuthStatus, { userAuthStatus }),
  setUserClaims: (userClaims: UserClaims) => createAction(AuthActionType.SetUserClaims, { userClaims }),

  loginUser: (credentials: UserCredentials) => createAction(AuthActionType.LoginUser, { credentials }),
  loginUserSuccess: () => createAction(AuthActionType.LoginUserSuccess),
  loginUserFailure: (error: firebase.FirebaseError) => createAction(AuthActionType.LoginUserFailure, { error }),

  registerUser: (credentials: UserCredentials) => createAction(AuthActionType.RegisterUser, { credentials }),
  registerUserSuccess: (isNewUser?: boolean) => createAction(AuthActionType.RegisterUserSuccess, { isNewUser }),
  registerUserFailure: (error: firebase.FirebaseError) => createAction(AuthActionType.RegisterUserFailure, { error }),

  logoutUser: () => createAction(AuthActionType.LogoutUser),
  logoutUserSuccess: () => createAction(AuthActionType.LogoutUserSuccess),
  logoutUserFailure: (error: firebase.FirebaseError) => createAction(AuthActionType.LogoutUserFailure, { error }),

  deleteUser: () => createAction(AuthActionType.DeleteUser),
  deleteUserSuccess: () => createAction(AuthActionType.DeleteUserSuccess),
  deleteUserFailure: (error: firebase.FirebaseError) => createAction(AuthActionType.DeleteUserFailure, { error }),

  resetUserPassword: (email: UserCredentials['email']) => createAction(AuthActionType.ResetUserPassword, { email }),
  resetUserPasswordSuccess: () => createAction(AuthActionType.ResetUserPasswordSuccess),
  resetUserPasswordFailure: (error: firebase.FirebaseError) =>
    createAction(AuthActionType.ResetUserPasswordFailure, { error }),

  changeUserEmail: (newEmail: UserCredentials['email']) => createAction(AuthActionType.ChangeUserEmail, { newEmail }),
  changeUserMailSuccess: () => createAction(AuthActionType.ChangeUserEmailSuccess),
  changeUserEmailFailure: (error: firebase.FirebaseError) =>
    createAction(AuthActionType.ChangeUserEmailFailure, { error }),

  changeUserPassword: (newPassword: UserCredentials['password']) =>
    createAction(AuthActionType.ChangeUserPassword, { newPassword }),
  changeUserPasswordSuccess: () => createAction(AuthActionType.ChangeUserPasswordSuccess),
  changeUserPasswordFailure: (error: firebase.FirebaseError) =>
    createAction(AuthActionType.ChangeUserPasswordFailure, { error }),
};

export type AuthActionCreator = typeof AuthAction;
