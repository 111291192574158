import React, { useRef, useLayoutEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation, matchPath } from 'react-router';
import cn from 'classnames';
import { ListItem, Fade } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Tooltip } from 'components';
import { useStyles } from './CreatorToolbarItem.styles';

interface CreatorToolbarItemProps {
  title: string;
  destination: string;
  setHighlightPosition: (element: HTMLAnchorElement) => void;
  Icon: React.ComponentType<SvgIconProps>;
}

export const CreatorToolbarItem: React.FC<CreatorToolbarItemProps> = ({
  title,
  destination,
  setHighlightPosition,
  Icon,
}) => {
  const styles = useStyles();
  const location = useLocation();
  const isActive = !!matchPath(location.pathname, { path: destination, exact: true, strict: false });
  const elementRef = useRef<HTMLAnchorElement>(null);
  const setHighlightPositionCallback = useCallback(setHighlightPosition, [setHighlightPosition]);

  useLayoutEffect(() => {
    if (isActive && elementRef.current) {
      setHighlightPositionCallback(elementRef.current);
    }
  }, [isActive, elementRef, setHighlightPositionCallback]);

  return (
    <Tooltip
      placement="right"
      title={title}
      titleClassName={styles.toolbarTooltipText}
      enterDelay={400}
      disabled={isActive}
      TransitionComponent={Fade}
    >
      <NavLink
        ref={elementRef}
        className={cn(styles.toolbarNavLink, { [styles.toolbarItemActive]: isActive })}
        to={destination}
      >
        <ListItem className={styles.toolbarItem}>
          <Icon className={styles.toolbarItemIcon} />
        </ListItem>
      </NavLink>
    </Tooltip>
  );
};
