import cn from 'classnames';
import { useStyles } from './SlideTwelth.styles';
import { SlideProps } from '../../types';
import { socialIcons } from './utils';
import { useRootStyles } from '../../common.styles';

const SlideTwelth: React.FC<SlideProps> = ({ logoUrl, colorTheme, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <div className={styles.accentLineSocial} />
        <h1 className={styles.leftTitle}>Follow Us on Social Media</h1>
        <h2 className={styles.leftSubtitle}>Subtitle Text</h2>
        <div className={styles.socialBackground}>
          {socialIcons.map((icon, idx) => (
            <div key={idx} className={styles.socialRow}>
              <img className={styles.socialIcon} alt="" loading="lazy" src={icon.src} />
              <div className={styles.socialTextBlock}>
                <h1 className={styles.socialPlatform}>{icon.name}</h1>
                <h2 className={styles.socialUrl}>{icon.url}</h2>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.slideNumber}>
          <h1 className={styles.number}>12</h1>
        </div>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
      </div>
    </>
  );
};

export default SlideTwelth;
