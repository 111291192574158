import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, SlideStyleProps>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#fff',
  },
  accentLineSocial: {
    position: 'absolute',
    left: '6%',
    top: '14.2%',
    right: 'auto',
    bottom: 'auto',
    width: '7.5%',
    height: '1.5%',
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
  },
  leftTitle: {
    position: 'absolute',
    left: '6%',
    top: '17.5%',
    right: 'auto',
    bottom: 'auto',
    width: '24%',
    marginTop: '0px',
    marginBottom: '0px',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
    fontSize: '4em',
    lineHeight: '110%',
    fontFamily: ({ fontPair }) => fontPair?.majorFont.familyName,
  },
  leftSubtitle: {
    position: 'absolute',
    left: '6%',
    top: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '24%',
    marginTop: '0px',
    marginBottom: '0px',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    fontSize: '2em',
    lineHeight: '110%',
    textTransform: 'none',
  },
  socialTextBlockEdge: {
    position: 'absolute',
    right: 'auto',
    bottom: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  socialTextBlockEdgeOne: {
    left: '58%',
    top: '17%',
  },
  socialTextBlockEdgeTwo: {
    left: '55.5%',
    top: '36%',
  },
  socialTextBlockEdgeThree: {
    left: '53%',
    top: '55%',
  },
  socialTextBlockEdgeFour: {
    left: '51%',
    top: '74%',
  },
  socialUrl: {
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '1.7em',
    lineHeight: '100%',
  },
  socialPlatformHeading: {
    marginTop: '0px',
    marginBottom: '2%',
    color: '#fff',
    fontSize: '2.6em',
    lineHeight: '100%',
  },
  number: {
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    marginTop: '1px',
    marginBottom: '-1px',
    fontSize: '1.4em',
    lineHeight: '100%',
  },
}));
