import { useStyles } from './SlideSixth.styles';
import { SlideProps } from '../../types';
import headshotImage from './images/headshot2.jpg';
import { useRootStyles } from '../../common.styles';
import cn from 'classnames';

export const SlideSixth: React.FC<SlideProps> = ({ logoUrl, colorTheme, fontPair }) => {
  const styles = useStyles({ fontPair, colorTheme });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <div className={styles.teamBlock} />
        <img src={headshotImage} loading="lazy" alt="" className={styles.teamMember} />
        <h1 className={styles.teamTitle}>Jane Smith</h1>
        <h2 className={styles.teamMemberTitle}>Chief Executive Officer</h2>
        <p className={styles.teamText}>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula egetdolor. Aenean massa. Cum
          sociis natoque. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa
          quis enim.
        </p>
        <p className={cn(styles.teamText, styles.teamTextSecond)}>
          In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pedemollis pretium.
          Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean commodo ligula eget dolor. Donec quam
          felis, ultricies nec, pellentesque eu, pretium quis, sem.
        </p>
        <div className={rootStyles.slideNumber}>
          <h1 className={rootStyles.number}>6</h1>
        </div>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
      </div>
    </>
  );
};

export default SlideSixth;
