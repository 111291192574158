import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useAuthManagement } from 'modules/auth/hooks';
import { UserAuthStatus } from 'modules/auth/types';

export const AuthObserver: React.FC = ({ children }) => {
  const { authStatus, startAuthObserver } = useAuthManagement();

  useEffect(() => {
    startAuthObserver();
  }, [startAuthObserver]);

  if (authStatus === UserAuthStatus.UNKNOWN) {
    return (
      <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={100} thickness={2.5} />
      </Box>
    );
  }

  return <>{children}</>;
};
