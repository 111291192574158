import { useStyles } from './SlideThird.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';
import cn from 'classnames';

export const SlideThird: React.FC<SlideProps> = ({ colorTheme, logoUrl, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[7].image;
  const backupImageSource: string = photos?.items[7].backupImage!;

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img src={imageSource ? imageSource : backupImageSource} loading="lazy" alt="" className={styles.image} />
        <div className={styles.backgroundOverlay} />
        <div className={styles.slideContainer}>
          <h1 className={styles.transition}>Image Title Slide</h1>
        </div>
        <div className={styles.centerBottomDiv}>
          {logoUrl && (
            <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerTransitionSlide)}>
              <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SlideThird;
