import { makeStyles, Theme } from '@material-ui/core/styles';

type EditableStyleProps = {
  editing: boolean;
};

export const useStyles = makeStyles<Theme, EditableStyleProps>(theme => ({
  container: {
    display: 'flex',
    alignItems: ({ editing }) => (editing ? 'center' : 'flex-end'),
  },
  iconButton: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    width: 20,
    height: 20,
  },
}));
