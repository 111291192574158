import React from 'react';
import { ColorTheme } from 'components/CreatorPreview/templates/types';

const FacebookBoxIcon: React.FC<ColorTheme> = ({ colorTheme }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 342.9 342.9"
      fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
      style={{ borderRadius: '100%', width: '16%', height: '100%' }}
    >
      <path
        d="M171.5,0C76.8,0,0,76.8,0,171.4s76.8,171.5,171.5,171.5s171.5-76.8,171.5-171.5S266.1,0,171.5,0z M211.2,170.1
	h-26.9v96h-39.9v-96h-19v-33.9h19v-22c0-15.7,7.5-40.3,40.3-40.3l29.6,0.1V107h-21.5c-3.5,0-8.5,1.7-8.5,9.2v19.9h30.4h0
	L211.2,170.1z"
      />
    </svg>
  );
};

export default FacebookBoxIcon;
