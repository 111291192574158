import { from, of, EMPTY } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, filter, mergeMap } from 'rxjs/operators';
import { user } from 'rxfire/auth';
import { combineEpics, Epic } from 'redux-observable';
import { push, replace } from 'connected-react-router';
import { routes } from 'const';
import { ofType } from 'modules/store/of-type';
import { Action } from 'modules/store/action';
import { SharedAction } from 'modules/shared/actions';
import { firebaseAuth } from 'services/firebase';
import { i18nClient } from 'services/translations';
import type { RootState } from 'services/store/root-reducer';
import { UserAuthStatus } from './types';
import { AuthAction, AuthActionType } from './actions';

const startAuthObserverEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.StartAuthObserver),
    switchMap(() =>
      user(firebaseAuth).pipe(
        map(user => AuthAction.authObserverEmission(user)),
        catchError(error =>
          of(
            AuthAction.startAuthObserverFailure(error),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const authObserverEmissionAuthStatusEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(AuthActionType.AuthObserverEmission),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth, router }]) => {
      const currentAuthStatus = auth.user.authStatus;
      const currentLocation = router.location.pathname;
      const isAccessingFirstTime = auth.user.isNewUser;
      const isAccessingFromOutside = currentLocation === routes.login.path || currentLocation === routes.register.path;

      if (payload.user && currentAuthStatus !== UserAuthStatus.SIGNED_IN) {
        const destination = isAccessingFirstTime
          ? routes.creator.base.link()
          : isAccessingFromOutside
          ? routes.root.path
          : currentLocation + router.location.search;

        return of(AuthAction.setUserAuthStatus(UserAuthStatus.SIGNED_IN), push(destination));
      }

      if (payload.user && currentAuthStatus === UserAuthStatus.SIGNED_IN) {
        return EMPTY;
      }

      // NOTE: at this point we are sure that user is either logging out or
      // trying to access protected routes unprivileged
      if (!isAccessingFromOutside) {
        replace(routes.login.path);
      }
      return of(AuthAction.setUserAuthStatus(UserAuthStatus.SIGNED_OUT));
    })
  );

const authObserverEmissionUserClaimsEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.AuthObserverEmission),
    filter(({ payload }) => !!payload.user),
    switchMap(({ payload }) =>
      from(payload.user!.getIdTokenResult(true)).pipe(
        map(idTokenResult => {
          const { stripeRole, lifetime } = idTokenResult.claims;

          return AuthAction.setUserClaims({ stripeRole, lifetime });
        })
      )
    )
  );

const loginUserEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.LoginUser),
    switchMap(({ payload: { credentials } }) =>
      from(firebaseAuth.signInWithEmailAndPassword(credentials.email, credentials.password)).pipe(
        map(() => AuthAction.loginUserSuccess()),
        catchError(error =>
          of(
            AuthAction.loginUserFailure(error),
            SharedAction.notifyUser(i18nClient.t('login.loginFailureMessage'), 'error')
          )
        )
      )
    )
  );

const registerUserEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.RegisterUser),
    switchMap(({ payload: { credentials } }) =>
      from(firebaseAuth.createUserWithEmailAndPassword(credentials.email, credentials.password)).pipe(
        map(({ additionalUserInfo }) => AuthAction.registerUserSuccess(additionalUserInfo?.isNewUser)),
        catchError(error =>
          of(
            AuthAction.registerUserFailure(error),
            SharedAction.notifyUser(i18nClient.t('register.registerFailureMessage'), 'error')
          )
        )
      )
    )
  );

const logoutUserEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.LogoutUser),
    switchMap(() =>
      from(firebaseAuth.signOut()).pipe(
        map(() => AuthAction.logoutUserSuccess()),
        catchError(error =>
          of(
            AuthAction.logoutUserFailure(error),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const deleteUserEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.DeleteUser),
    switchMap(() =>
      from(firebaseAuth.currentUser!.delete()).pipe(
        mergeMap(() =>
          of(AuthAction.deleteUserSuccess(), SharedAction.notifyUser(i18nClient.t('account.deleteAccount.success')))
        ),
        catchError(error =>
          of(
            AuthAction.deleteUserFailure(error),
            SharedAction.notifyUser(i18nClient.t('account.deleteAccount.error'), 'error')
          )
        )
      )
    )
  );

const resetUserPasswordEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.ResetUserPassword),
    switchMap(({ payload }) =>
      from(firebaseAuth.sendPasswordResetEmail(payload.email)).pipe(
        mergeMap(() =>
          of(
            AuthAction.resetUserPasswordSuccess(),
            SharedAction.notifyUser(i18nClient.t('reset.resetPasswordSuccessMessage')),
            push(routes.login.path)
          )
        ),
        catchError(error => {
          return error.code === 'auth/user-not-found'
            ? of(
                AuthAction.resetUserPasswordFailure(error),
                SharedAction.notifyUser(i18nClient.t('reset.resetPasswordNotFoundMessage'), 'error')
              )
            : of(
                AuthAction.resetUserPasswordFailure(error),
                SharedAction.notifyUser(i18nClient.t('reset.resetPasswordFailureMessage'), 'error')
              );
        })
      )
    )
  );

const changeUserEmailEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.ChangeUserEmail),
    switchMap(({ payload: { newEmail } }) =>
      from(firebaseAuth.currentUser!.updateEmail(newEmail)).pipe(
        mergeMap(() =>
          of(AuthAction.changeUserMailSuccess(), SharedAction.notifyUser(i18nClient.t('account.changeMail.success')))
        ),
        catchError(error =>
          of(
            AuthAction.changeUserEmailFailure(error),
            SharedAction.notifyUser(i18nClient.t('account.changeMail.error'), 'error')
          )
        )
      )
    )
  );

const changeUserPasswordEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(AuthActionType.ChangeUserPassword),
    switchMap(({ payload: { newPassword } }) =>
      from(firebaseAuth.currentUser!.updatePassword(newPassword)).pipe(
        mergeMap(() =>
          of(
            AuthAction.changeUserPasswordSuccess(),
            SharedAction.notifyUser(i18nClient.t('account.changePassword.success'))
          )
        ),
        catchError(error =>
          of(
            AuthAction.changeUserPasswordFailure(error),
            SharedAction.notifyUser(i18nClient.t('account.changePassword.error'), 'error')
          )
        )
      )
    )
  );

export const authEpics = combineEpics(
  startAuthObserverEpic,
  authObserverEmissionAuthStatusEpic,
  authObserverEmissionUserClaimsEpic,
  loginUserEpic,
  registerUserEpic,
  logoutUserEpic,
  deleteUserEpic,
  resetUserPasswordEpic,
  changeUserEmailEpic,
  changeUserPasswordEpic
);
