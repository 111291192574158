import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, SlideStyleProps>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius * 2,
  },
  backgroundOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: 0,
    opacity: 0.75,
    height: 'auto',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : '#3a5df6'),
  },
  roundTransition: {
    position: 'absolute',
    left: '7%',
    top: '12%',
    right: 'auto',
    bottom: 'auto',
    width: '60%',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '7.5em',
    lineHeight: '110%',
    textAlign: 'left',
  },
  svgWhiteThird: {
    borderTopRightRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
  },
}));
