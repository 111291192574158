import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  toolbarContainer: {
    height: 505,
    width: '100%',
    position: 'sticky',
    top: '110px',
    maxHeight: 'calc(100vh - 110px)',
    borderTopRightRadius: theme.shape.borderRadius * 4,
    borderBottomRightRadius: theme.shape.borderRadius * 4,
    backgroundColor: theme.palette.primary.main,
  },
  toolbarList: {
    position: 'relative',
    padding: theme.spacing(5.375, 0),
  },
  toolbarHightlight: {
    position: 'absolute',
    top: 0,
    left: 4,
    width: 69,
    height: 105,
    clipPath: 'url(#toolbar-highlight)',
    backgroundColor: theme.palette.common.white,
    willChange: 'transform',

    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: 700,
      delay: 100,
    }),

    transform: 'scale(.8) translateY(16px)',
  },
  svgContainer: {
    width: 0,
    height: 0,
  },
}));
