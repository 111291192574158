import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isStatusPending, isStatusFailure } from 'modules/store/status';
import { StorageAction } from './actions';
import { selectUploadFile, selectSavedImages, selectPreviewImageSource } from './selectors';

export const useStorageManagement = () => {
  const dispatch = useDispatch();
  const uploadedFile = useSelector(selectUploadFile);
  const savedImages = useSelector(selectSavedImages);
  const previewImageSource = useSelector(selectPreviewImageSource);

  const isLoading = isStatusPending(uploadedFile.status) || isStatusPending(savedImages.status);
  const isErrored = isStatusFailure(uploadedFile.status) || isStatusFailure(savedImages.status);

  const uploadFile = useCallback(
    (file: File) => {
      dispatch(StorageAction.uploadFile(file));
    },
    [dispatch]
  );

  const fetchSavedImages = useCallback(() => {
    dispatch(StorageAction.fetchSavedImages());
  }, [dispatch]);

  const setPreviewImageSource = useCallback(
    (source: string | null) => {
      dispatch(StorageAction.setPreviewImageSource(source));
    },
    [dispatch]
  );

  return {
    isLoading,
    isErrored,

    uploadedFile,
    uploadProgress: uploadedFile.progress,
    savedImages: savedImages.imageItems,
    previewImageSource,

    uploadFile,
    fetchSavedImages,
    setPreviewImageSource,
  };
};
