import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, SlideStyleProps>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius * 2,
  },
  image: {
    position: 'relative',
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    borderRadius: theme.shape.borderRadius * 2,
  },
  slideContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius * 2,
  },
  backgroundOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: 0,
    opacity: 0.75,
    height: 'auto',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : '#3a5df6'),
  },
  transition: {
    width: '80%',
    color: '#fff',
    fontSize: '7em',
    lineHeight: '110%',
    textAlign: 'center',
  },
  centerBottomDiv: {
    position: 'absolute',
    left: '0%',
    top: 'auto',
    right: '0%',
    bottom: '0%',
    display: 'flex',
    width: '100%',
    height: '18%',
    justifyContent: 'center',
  },
}));
