import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import UnsplashLogo from 'assets/images/Unsplash.png';
import { useConfigurationManagement } from 'modules/configuration/hooks';
import { usePresentationManagement } from 'modules/presentation/hooks';
import { CreatorActionArea, CreatorTitle, Loader } from 'components';
import type { CreatorRouteProps } from 'views/Creator/types';
import { isStatusPending } from 'modules/store/status';
import { PhotoPackItem } from './components';
import { useStyles } from './CreatorPhotoPack.styles';

interface CreatorPhotoPackProps extends CreatorRouteProps {}

export const CreatorPhotoPack: React.FC<CreatorPhotoPackProps> = ({ match }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { photoPacks, photoPacksStatus } = useConfigurationManagement();
  const { presentationPhotoPackId, savePresentationPhotoPack } = usePresentationManagement();

  const { presentationId } = match.params;

  return (
    <CreatorActionArea>
      <CreatorTitle className={styles.title} title={t('creatorPhotoPack.title')} />
      <div className={styles.subtitleWrapper}>
        <Typography className={styles.subtitle}>{t('creatorPhotoPack.subtitle')}</Typography>

        <a href="https://unsplash.com/" target="_blank" rel="noopener noreferrer">
          <img width="80" height="25" src={UnsplashLogo} alt="Unsplash logotype" />
        </a>
      </div>

      <Loader loading={isStatusPending(photoPacksStatus)}>
        <div className={styles.photopacksWrapper}>
          {photoPacks.map(({ id, name, items, order }) => {
            const firstPhotoPackItem = items[0];

            return (
              <PhotoPackItem
                key={id}
                name={name}
                thumbnail={firstPhotoPackItem.imageThumbnail}
                thumbnailFallback={firstPhotoPackItem.backupThumbnail}
                isActive={presentationPhotoPackId ? presentationPhotoPackId === id : order === 0}
                onClick={() => savePresentationPhotoPack(id, presentationId!)}
              />
            );
          })}
        </div>
      </Loader>
    </CreatorActionArea>
  );
};
