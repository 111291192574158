import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, Partial<SlideStyleProps>>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  teamBlock: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: 'auto',
    height: '34%',
    padding: '0px',
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    opacity: 1,
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
  },
  teamMember: {
    position: 'absolute',
    left: '5%',
    top: '9%',
    right: 'auto',
    bottom: 'auto',
    width: '28%',
    height: '50%',
    objectFit: 'cover',
  },
  teamTitle: {
    position: 'absolute',
    left: '38%',
    top: '8%',
    right: 'auto',
    bottom: 'auto',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '5em',
    lineHeight: '110%',
  },
  teamMemberTitle: {
    position: 'absolute',
    left: '38%',
    top: '21%',
    right: 'auto',
    bottom: 'auto',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '2.5em',
    lineHeight: '110%',
  },
  teamText: {
    position: 'absolute',
    left: '38%',
    top: '37%',
    right: 'auto',
    bottom: 'auto',
    width: '56%',
    fontWeight: 400,
    fontSize: '2em',
    lineHeight: '120%',
  },
  teamTextSecond: {
    top: '58%',
  },
}));
