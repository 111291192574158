import React, { useRef, useState } from 'react';
import cn from 'classnames';
import type { TransitionProps } from '@material-ui/core/transitions';
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './CreatorPreviewSlide.styles';

interface CreatorPreviewSlideProps {
  source?: string;
  isLoading?: boolean;
  style?: TransitionProps['style']; // used by transition component
}

export const CreatorPreviewSlide: React.FC<CreatorPreviewSlideProps> = ({ source, isLoading, style }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerHeight = containerRef.current?.getBoundingClientRect().height;
  const styles = useStyles({ height: Math.max(containerHeight || 0, 205) });
  const [loaded, setLoaded] = useState(false);

  return (
    <div ref={containerRef} className={styles.slideContainer} style={style}>
      {isLoading ? (
        <Skeleton className={styles.skeleton} variant="rect" animation="wave" />
      ) : (
        <img
          className={cn(styles.slideImage, {
            [styles.slidesImageFadeIn]: loaded,
          })}
          src={source}
          alt="Presentation slide preview"
          loading="lazy"
          width="355"
          height="205"
          onLoad={() => setLoaded(true)}
        />
      )}
    </div>
  );
};
