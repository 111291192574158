import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const CheckedIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="38" height="27" viewBox="0 0 38 27" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M36.833,6,14.26,28.573,4,18.312"
        fill="none"
        stroke="#1fbffb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4px"
        transform="translate(-1.172 -3.172)"
      />
    </SvgIcon>
  );
};
