import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  savedImagesContainer: {
    display: 'grid',
    gridGap: theme.spacing(2.5),
    gridTemplateColumns: 'auto auto',
  },
  savedImageContainer: {
    width: 200,
    height: 100,
    borderRadius: theme.shape.borderRadius * 2,

    padding: theme.spacing(2),
    boxShadow: `0px 5px 16px ${theme.palette.utility.grayscale[300]}`,
    willChange: 'transform',

    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),
    color: '#fff',
    '&:hover': {
      transform: 'scale(1.05)',
      color: '#fff',
    },
  },
  savedImagePreview: {
    height: '100%',
    backgroundSize: 'contain',
  },
}));
