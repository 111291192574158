import { AccountSubtitle } from 'components/AccountSubtitle';
import { useAuthManagement } from 'modules/auth/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './AccountInfo.styles';

export const AccountInfo = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const {
    user,
    isPremiumUser,
    isMonthlySubscriber,
    isAnnualSubscriber,
    isOneWeekPremium,
    isOneYearPremium,
    isLifetimePremium,
    isCustomLifetime,
  } = useAuthManagement();
  const [currentPlan, setCurrentPlan] = useState<string>('');

  useEffect(() => {
    if (!isPremiumUser) setCurrentPlan(t('account.accountInfo.free'));
    if (isMonthlySubscriber) setCurrentPlan(t('account.accountInfo.billedMonthly'));
    if (isAnnualSubscriber) setCurrentPlan(t('account.accountInfo.billedAnnually'));
    if (isOneWeekPremium) setCurrentPlan(t('account.accountInfo.week'));
    if (isOneYearPremium) setCurrentPlan(t('account.accountInfo.year'));
    if (isLifetimePremium) setCurrentPlan(t('account.accountInfo.lifetime'));
    if (isLifetimePremium || isCustomLifetime) setCurrentPlan(t('account.accountInfo.lifetime'));
  }, [
    t,
    isPremiumUser,
    isMonthlySubscriber,
    isAnnualSubscriber,
    isOneWeekPremium,
    isOneYearPremium,
    isLifetimePremium,
    isCustomLifetime,
  ]);

  return (
    <>
      <AccountSubtitle subtitle={t('account.subtitle.info')} />
      <p>
        <span className={styles.infoTitle}>{t('account.accountInfo.email')}:</span> {user!.email}
      </p>
      <p>
        <span className={styles.infoTitle}>{t('account.accountInfo.plan')}:</span> {currentPlan}
      </p>
    </>
  );
};
