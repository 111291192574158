import { useStyles } from './SlideThird.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';
import cn from 'classnames';

export const SlideThird: React.FC<SlideProps> = ({ colorTheme, logoUrl, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[3].image;
  const backupImageSource: string = photos?.items[3].backupImage!;

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img
          src={imageSource ? imageSource : backupImageSource}
          loading="lazy"
          alt=""
          className={rootStyles.fullBackgroundImage}
        />
        <div className={cn(rootStyles.svgAccentOne, rootStyles.opacityEighty)}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1600 900">
            <polygon
              fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              points="213.9,211.1 110.1,688.9 1386.1,688.9 1489.9,211.1 "
            ></polygon>
          </svg>
        </div>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerSvg)}>
            <div className={rootStyles.svgWhite}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
                <polygon fill="#fff" points="641.1,616.2 609.6,761.1 958.9,761.1 990.4,616.2 "></polygon>
              </svg>
            </div>
            <div className={styles.logoBoxEdgeThree}>
              <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
            </div>
          </div>
        )}
        <h1 className={styles.transitionTitleEdge}>Image Section Title</h1>
      </div>
    </>
  );
};

export default SlideThird;
