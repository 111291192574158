import { Action } from 'modules/store/action';
import { Status } from 'modules/store/status';
import { PresentationActionType } from 'modules/presentation/actions';
import { StorageActionType } from './actions';
import { StorageState } from './state';

export const storageState: StorageState = {
  uploadFile: {
    status: Status.DEFAULT,
    progress: 0,
    reference: null,
    source: null,
  },
  savedImages: {
    status: Status.DEFAULT,
    imageItems: [],
    error: null,
  },
  previewImageSource: null,
};

export function storageReducer(state = storageState, action: Action): StorageState {
  switch (action.type) {
    /**
     * UploadFile
     */
    case StorageActionType.UploadFile:
      return {
        ...state,
        uploadFile: {
          ...storageState.uploadFile,
          status: Status.PENDING,
        },
      };

    case StorageActionType.UploadFileProgress:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          progress: action.payload.progress,
        },
      };

    case StorageActionType.UploadFileSuccess:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          progress: 100,
          reference: action.payload.ref,
          status: Status.SUCCESS,
        },
      };

    case StorageActionType.UploadFileFailure:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          status: Status.FAILURE,
        },
      };

    /**
     * UploadFileSource
     */
    case StorageActionType.UploadFileSetSource:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          source: action.payload.source,
        },
      };

    /**
     * FetchSavedImages
     */
    case StorageActionType.FetchSavedImages:
      return {
        ...state,
        savedImages: {
          ...state.savedImages,
          status: Status.PENDING,
        },
      };

    case StorageActionType.FetchSavedImagesSuccess:
      return {
        ...state,
        savedImages: {
          status: Status.SUCCESS,
          imageItems: action.payload.imageItems,
        },
      };

    case StorageActionType.FetchSavedImagesFailure:
      return {
        ...state,
        savedImages: {
          ...state.savedImages,
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * SetPreviewImageSource
     */
    case StorageActionType.SetPreviewImageSource:
      return {
        ...state,
        previewImageSource: action.payload.source,
      };

    /**
     * ResetPresentationState
     */
    case PresentationActionType.ResetPresentationState:
      return {
        ...state,
        previewImageSource: null,
      };

    default:
      return state;
  }
}
