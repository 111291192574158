import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, Partial<SlideStyleProps>>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    backgroundColor: '#fff',
  },
  halfBackgroundImage: {
    position: 'relative',
    objectFit: 'cover',
    width: '50%',
    height: '100%',
    maxHeight: '100%',
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
  },
  halfContainer: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    display: 'flex',
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  teamTitleEdge: {
    position: 'absolute',
    left: '47.2%',
    top: '14%',
    right: 'auto',
    bottom: 'auto',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '4em',
    lineHeight: '110%',
  },
  teamMemberTitleEdge: {
    position: 'absolute',
    left: '44.5%',
    top: '28.5%',
    right: 'auto',
    bottom: 'auto',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '2.2em',
    lineHeight: '110%',
  },
  teamTextEdge: {
    position: 'absolute',
    left: '50%',
    top: '37.5%',
    right: 'auto',
    bottom: 'auto',
    width: '44%',
    fontWeight: 400,
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
    fontFamily: ({ fontPair }) => fontPair?.majorFont.familyName,
    fontSize: '1.9em',
    lineHeight: '120%',
  },
  teamTextSecondEdge: {
    top: '60%',
  },
  svgWhiteSixth: {
    borderTopRightRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
  },
}));
