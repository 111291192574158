import firebase from 'firebase/app';
import { from, of, forkJoin, merge, concat } from 'rxjs';
import {
  catchError,
  switchMap,
  map,
  mergeMap,
  bufferCount,
  filter,
  withLatestFrom,
  defaultIfEmpty,
} from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { getDownloadURL } from 'rxfire/storage';
import { firebaseFirestore, firebaseStorage } from 'services/firebase';
import { i18nClient } from 'services/translations';
import type { RootState } from 'services/store/root-reducer';
import { Action } from 'modules/store/action';
import { ofType } from 'modules/store/of-type';
import type { PresentationDocumentData, PreviewImageDocumentData } from 'modules/presentation/types';
import { SharedAction } from 'modules/shared/actions';
import {
  PresentationDashboard,
  CuratedColorScheme,
  UserColorScheme,
  Font,
  FontPairDocumentData,
  PhotoPackDocumentData,
  StripePriceObject,
  LayoutsDocumentData,
} from './types';
import { ConfigurationAction, ConfigurationActionType } from './actions';

const curatedColorSchemesQuery = firebaseFirestore
  .collection('admin')
  .doc('config')
  .collection('colors')
  .where('isPublished', '==', true) as firebase.firestore.Query<CuratedColorScheme>;
const fontPairsQuery = firebaseFirestore
  .collection('admin')
  .doc('config')
  .collection('fonts')
  .where('isPublished', '==', true) as firebase.firestore.Query<FontPairDocumentData>;
const photoPacksQuery = firebaseFirestore
  .collection('admin')
  .doc('config')
  .collection('photopacks')
  .where('isPublished', '==', true) as firebase.firestore.Query<PhotoPackDocumentData>;
const subscriptionsQuery = firebaseFirestore
  .collectionGroup('prices')
  .where('active', '==', true) as firebase.firestore.Query<StripePriceObject>;
const layoutsQuery = firebaseFirestore
  .collection('admin')
  .doc('config')
  .collection('layouts')
  .where('isPublished', '==', true) as firebase.firestore.Query<LayoutsDocumentData>;

const createUserPresentationDocumentRef = (
  userId: string,
  presentationId: string
): firebase.firestore.DocumentReference<PresentationDocumentData> => {
  return firebaseFirestore.collection('users').doc(userId).collection('presentations').doc(presentationId);
};

const createUserPresentationsCollectionRef = (
  userId: string
): firebase.firestore.CollectionReference<PresentationDocumentData> => {
  return firebaseFirestore.collection('users').doc(userId).collection('presentations');
};

const createPresentationsThumbnailsQuery = (
  userId: string,
  presentationId: string
): firebase.firestore.Query<PreviewImageDocumentData> => {
  return firebaseFirestore
    .collection('users')
    .doc(userId)
    .collection('presentations')
    .doc(presentationId)
    .collection('images')
    .where('index', '==', 1) as firebase.firestore.Query<PreviewImageDocumentData>;
};

const createUserColorSchemesCollectionRef = (
  userId: string
): firebase.firestore.CollectionReference<UserColorScheme> => {
  return firebaseFirestore
    .collection('users')
    .doc(userId)
    .collection('colors') as firebase.firestore.CollectionReference<UserColorScheme>;
};

const fetchUserPresentationsEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(ConfigurationActionType.FetchUserPresentations),
    withLatestFrom(state$),
    switchMap(([, { auth: { user } }]) =>
      from(createUserPresentationsCollectionRef(user.data?.uid!).get()).pipe(
        mergeMap(querySnapshot => {
          const presentations$ = querySnapshot.docs.map(presentation =>
            from(createPresentationsThumbnailsQuery(user.data?.uid!, presentation.id).get()).pipe(
              map(thumbnailQuerySnapshot => thumbnailQuerySnapshot.docs.map(doc => doc.data())),
              mergeMap(thumbnailDocuments => {
                const thumbnails$ = thumbnailDocuments.map(
                  thumbnail =>
                    getDownloadURL(firebaseStorage.refFromURL(thumbnail.url)).pipe(
                      map(thumbnailURL => ({
                        name: presentation.data().name,
                        uid: presentation.id,
                        createdAt: presentation.data().createdAt,
                        modifiedAt: presentation.data().modifiedAt,
                        source: thumbnailURL,
                      }))
                    ),
                  catchError(error => of(ConfigurationAction.fetchUserPresentationsFailure(error)))
                );

                return forkJoin(thumbnails$).pipe(defaultIfEmpty<PresentationDashboard[]>([]));
              })
            )
          );

          return forkJoin(presentations$).pipe(
            defaultIfEmpty<PresentationDashboard[][]>([]),
            map(presentations => ConfigurationAction.fetchUserPresentationsSuccess(presentations.flat())),
            catchError(error =>
              of(
                ConfigurationAction.fetchUserPresentationsFailure(error),
                SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
              )
            )
          );
        }),
        catchError(error =>
          of(
            ConfigurationAction.fetchUserPresentationsFailure(error),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

// NOTE: this should probably live as cloud function
const deleteUserPresentationEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(ConfigurationActionType.DeleteUserPresentation),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) => {
      const presentationDocumentRef = createUserPresentationDocumentRef(auth.user.data?.uid!, payload.presentationId);
      const presentationPreviewCollectionRef = presentationDocumentRef.collection('images');

      const deletePresentationFiles$ = from(
        firebaseStorage.ref(`users/${auth.user.data?.uid}/${payload.presentationId}`).listAll()
      ).pipe(
        mergeMap(listResult => {
          const items$ = listResult.items.map(item => item.delete());
          const folders$ = forkJoin(listResult.prefixes.map(folder => folder.listAll())).pipe(
            defaultIfEmpty<firebase.storage.ListResult[]>([]),
            map(listResults => listResults.flatMap(listResult => listResult.items.map(item => item.delete())))
          );

          return merge(items$, folders$);
        })
      );

      const deletePresentationPreviewDocuments$ = from(presentationPreviewCollectionRef.get()).pipe(
        map(querySnapshot => querySnapshot.docs.map(doc => doc.id)),
        mergeMap(documentIds =>
          forkJoin(documentIds.map(documentId => presentationPreviewCollectionRef.doc(documentId).delete()))
        )
      );

      return concat(
        deletePresentationFiles$,
        deletePresentationPreviewDocuments$,
        presentationDocumentRef.delete()
      ).pipe(
        bufferCount(3),
        mergeMap(() =>
          of(
            ConfigurationAction.deleteUserPresentationSuccess(),
            ConfigurationAction.fetchUserPresentations(),
            SharedAction.notifyUser(i18nClient.t('dashboard.deletePresentationSuccessMessage'))
          )
        ),
        catchError(error =>
          of(
            ConfigurationAction.deleteUserPresentationFailure(error),
            SharedAction.notifyUser(i18nClient.t('dashboard.deletePresentationFailureMessage'), 'error')
          )
        )
      );
    })
  );

const setPresentationNameEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(ConfigurationActionType.SetPresentationName),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) =>
      from(
        createUserPresentationDocumentRef(auth.user.data?.uid!, payload.presentationId).set(
          {
            name: payload.presentationName,
            modifiedAt: firebase.firestore.Timestamp.now(),
          },
          { merge: true }
        )
      ).pipe(
        mergeMap(() =>
          of(
            ConfigurationAction.setPresentationNameSuccess(),
            ConfigurationAction.fetchUserPresentations(),
            SharedAction.notifyUser(i18nClient.t('dashboard.setPresentationNameSuccessMessage'))
          )
        ),
        catchError(error =>
          of(
            ConfigurationAction.setPresentationNameFailure(error),
            SharedAction.notifyUser(i18nClient.t('dashboard.setPresentationNameFailureMessage'), 'error')
          )
        )
      )
    )
  );

const fetchCuratedColorSchemesEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(ConfigurationActionType.FetchCuratedColorSchemes),
    switchMap(() =>
      from(curatedColorSchemesQuery.get()).pipe(
        map(querySnapshot => {
          const curatedColorSchemes = querySnapshot.docs.map(doc => doc.data());
          return ConfigurationAction.fetchCuratedColorSchemesSuccess(curatedColorSchemes);
        }),
        catchError(error =>
          of(
            ConfigurationAction.fetchCuratedColorSchemesFailure(error),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const fetchUserColorSchemesEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(ConfigurationActionType.FetchUserColorSchemes),
    withLatestFrom(state$),
    switchMap(([, { auth: { user } }]) =>
      from(createUserColorSchemesCollectionRef(user.data?.uid!).get()).pipe(
        map(querySnapshot => {
          const userColorSchemes = querySnapshot.docs.map(doc => doc.data());
          return ConfigurationAction.fetchUserColorSchemesSuccess(userColorSchemes);
        }),
        catchError(error =>
          of(
            ConfigurationAction.fetchUserColorSchemesFailure(error),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const saveUserColorSchemeEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(ConfigurationActionType.SaveUserColorScheme),
    withLatestFrom(state$),
    switchMap(([{ payload: { color } }, { auth: { user } }]) =>
      from(createUserColorSchemesCollectionRef(user.data?.uid!).doc(color.name).set(color)).pipe(
        map(() => ConfigurationAction.saveUserColorSchemeSuccess()),
        catchError(error =>
          of(
            ConfigurationAction.saveUserColorSchemeFailure(error),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );
const fetchFontPairsEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(ConfigurationActionType.FetchFontPairs),
    switchMap(() =>
      from(fontPairsQuery.get()).pipe(
        mergeMap(querySnapshot => {
          const fontDocuments$ = querySnapshot.docs.flatMap(document => {
            const documentData = { ...document.data(), id: document.id };
            const majorFont$ = from(documentData.majorFont.get()).pipe(
              filter(documentSnapshot => documentSnapshot.exists),
              map(documentSnapshot => documentSnapshot.data()!)
              // TODO: No previewImages found for a selected font, stream is cancelled
              // mergeMap(font => {
              //   const url =  getDownloadURL(firebaseStorage.refFromURL(font.reference?.toString())),
              //   return forkJoin(font).pipe(
              //     defaultIfEmpty({}),
              //     map(previewImagesUrls => ({
              //       ...font,
              //       url: url,
              //     }))
              //   );
              // })
            );

            const minorFont$ = from(documentData.minorFont.get()).pipe(
              filter(documentSnapshot => documentSnapshot.exists),
              map(documentSnapshot => documentSnapshot.data()!)
              // TODO: No previewImages found for a selected font, stream is cancelled
              // mergeMap(font => {
              //   const previewImages$ = Object.fromEntries(
              //     Object.entries(font.previewImages).map(([key, value]) => [
              //       key,
              //       getDownloadURL(firebaseStorage.refFromURL(value)),
              //     ])
              //   );

              //   return forkJoin(previewImages$).pipe(
              //     defaultIfEmpty({}),
              //     map(previewImagesUrls => ({
              //       ...font,
              //       previewImages: previewImagesUrls as FontPreviewImages,
              //     }))
              //   );
              // })
            );

            return [
              forkJoin([majorFont$, minorFont$]).pipe(
                defaultIfEmpty<Font[]>([]),
                map(([majorFont, minorFont]) => ({
                  ...documentData,
                  majorFont,
                  minorFont,
                }))
              ),
            ];
          });
          return forkJoin(fontDocuments$).pipe(
            map(fontPairs => ConfigurationAction.fetchFontPairsSuccess(fontPairs)),
            catchError(error =>
              of(
                ConfigurationAction.fetchFontPairsFailure(error),
                SharedAction.notifyUser('general.failureMessage', 'error')
              )
            )
          );
        }),
        catchError(error =>
          of(
            ConfigurationAction.fetchFontPairsFailure(error),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const fetchFetchPhotoPacksEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(ConfigurationActionType.FetchPhotoPacks),
    switchMap(() =>
      from(photoPacksQuery.get()).pipe(
        map(querySnapshot => {
          const photoPacks = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          }));

          return ConfigurationAction.fetchPhotoPacksSuccess(photoPacks);
        }),
        catchError(error =>
          of(
            ConfigurationAction.fetchPhotoPacksFailure(error),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const fetchSubscriptionOptionsEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(ConfigurationActionType.FetchSubscriptionOptions),
    switchMap(() =>
      from(subscriptionsQuery.get()).pipe(
        map(querySnapshot => {
          const subscriptionOptions = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          }));

          return ConfigurationAction.fetchSubscriptionOptionsSuccess(subscriptionOptions);
        }),
        catchError(error =>
          of(
            ConfigurationAction.fetchSubscriptionOptionsFailure(error),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const fetchPresentationLayoutsEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(ConfigurationActionType.FetchPresentationLayouts),
    switchMap(() =>
      from(layoutsQuery.get()).pipe(
        map(querySnapshot => {
          const presentationLayouts = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          }));

          return ConfigurationAction.fetchPresentationLayoutsSuccess(presentationLayouts);
        }),
        catchError(error =>
          of(
            ConfigurationAction.fetchPresentationLayoutsFailure(error),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

export const configurationEpics = combineEpics(
  fetchUserPresentationsEpic,
  deleteUserPresentationEpic,
  setPresentationNameEpic,
  fetchCuratedColorSchemesEpic,
  fetchUserColorSchemesEpic,
  saveUserColorSchemeEpic,
  fetchFontPairsEpic,
  fetchFetchPhotoPacksEpic,
  fetchSubscriptionOptionsEpic,
  fetchPresentationLayoutsEpic
);
