import React, { useState } from 'react';
import cn from 'classnames';
import { Typography } from '@material-ui/core';
import type { Font } from 'modules/configuration/types';
import { useStyles } from './CreatorFontItem.styles';
import { Loader } from 'components/Loader';

interface CreatorFontItemProps {
  isActive?: boolean;
  onClick: () => void;
  majorFont: Font;
  minorFont: Font;
  isLoading?: boolean;
}

const initialPreviewErrors = {
  majorUppercaseWhite: false,
  majorUppercaseHighlight: false,
  minorLowercaseWhite: false,
  minorLowercaseHighlight: false,
  majorFontName: false,
  minorFontName: false,
};

export const CreatorFontItem: React.FC<CreatorFontItemProps> = ({
  isActive,
  onClick,
  majorFont,
  minorFont,
  isLoading,
}) => {
  const [previewErrors] = useState(initialPreviewErrors);
  const isErrored = Object.entries(previewErrors).some(([, value]) => value);
  const styles = useStyles({ isErrored });

  const majorFontStyle = { fontFamily: majorFont.familyName };
  const minorFontStyle = { fontFamily: minorFont.familyName };

  const handleOnClick = (): void => {
    if (!isActive) onClick();
  };
  return (
    <div
      className={cn(styles.fontItemContainer, {
        [styles.fontItemContainerActive]: isActive,
        [styles.fontItemContainerHover]: !isActive,
      })}
      onClick={handleOnClick}
    >
      <div className={cn(styles.fontItemPreviewLetters, { [styles.fontItemPreviewLettersActive]: isActive })}>
        <div className={cn(styles.fontItemPreviewLetterHidden, { [styles.fontItemPreviewLetterVisible]: isActive })}>
          {previewErrors.majorUppercaseWhite ? (
            <Typography className={styles.fontItemPreviewLetter} style={majorFontStyle} component="span">
              A
            </Typography>
          ) : (
            <Typography className={styles.fontItemPreviewLetter} style={majorFontStyle} component="span">
              A
            </Typography>
          )}
        </div>

        <div className={cn(styles.fontItemPreviewLetterHidden, { [styles.fontItemPreviewLetterVisible]: !isActive })}>
          {previewErrors.majorUppercaseHighlight ? (
            <Typography className={styles.fontItemPreviewLetter} style={majorFontStyle} component="span">
              A
            </Typography>
          ) : (
            <Typography className={styles.fontItemPreviewLetter} style={majorFontStyle} component="span">
              A
            </Typography>
          )}
        </div>

        <div className={cn(styles.fontItemPreviewLetterHidden, { [styles.fontItemPreviewLetterVisible]: isActive })}>
          {previewErrors.minorLowercaseWhite ? (
            <Typography className={styles.fontItemPreviewLetter} style={minorFontStyle} component="span">
              a
            </Typography>
          ) : (
            <Typography className={styles.fontItemPreviewLetter} style={minorFontStyle} component="span">
              a
            </Typography>
          )}
        </div>

        <div className={cn(styles.fontItemPreviewLetterHidden, { [styles.fontItemPreviewLetterVisible]: !isActive })}>
          {previewErrors.minorLowercaseHighlight ? (
            <Typography className={styles.fontItemPreviewLetter} style={minorFontStyle} component="span">
              a
            </Typography>
          ) : (
            <Typography className={styles.fontItemPreviewLetter} style={minorFontStyle} component="span">
              a
            </Typography>
          )}
        </div>
      </div>

      <div className={styles.fontItemPreviewText}>
        {previewErrors.majorFontName ? (
          <Typography style={majorFontStyle} className={styles.fontItemPreviewTextPrimary}>
            {majorFont.familyName}
          </Typography>
        ) : (
          <Typography style={majorFontStyle} className={styles.fontItemPreviewTextPrimary}>
            {majorFont.familyName}
          </Typography>
        )}

        {previewErrors.minorFontName ? (
          <Typography style={minorFontStyle} className={styles.fontItemPreviewTextSecondary}>
            {minorFont.familyName}
          </Typography>
        ) : (
          <Typography style={minorFontStyle} className={styles.fontItemPreviewTextSecondary}>
            {minorFont.familyName}
          </Typography>
        )}
      </div>
      {isActive && isLoading && <Loader className={styles.fontItemLoader} loading={isLoading} />}
    </div>
  );
};
