import React from 'react';
import { ColorTheme } from 'components/CreatorPreview/templates/types';

const InstaBoxIcon: React.FC<ColorTheme> = ({ colorTheme }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 342.9 342.9"
      fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
      style={{ borderRadius: '100%', width: '16%', height: '100%' }}
    >
      <g id="a"></g>
      <g>
        <path
          d="M212.8,98.6h-72.7c-10.6,0-19.5,3.2-25.6,9.3c-6.2,6.1-9.4,15-9.4,25.7v72.3c0,10.8,3.4,19.9,9.8,26.1
		c6.3,6.1,15.1,9.3,25.6,9.3h71.9c10.7,0,19.7-3.2,25.9-9.3c6.3-6.1,9.6-15,9.6-25.8v-72.7c0-10.5-3.3-19.3-9.4-25.5
		C232.2,101.9,223.4,98.6,212.8,98.6z M176.5,214.5c-24.8,0-45-20.2-45-45s20.2-45,45-45c24.8,0,45,20.2,45,45
		S201.3,214.5,176.5,214.5z M227.6,132.6c-5.1,2.4-11.2,0.2-13.6-4.9c-2.4-5.1-0.2-11.2,4.9-13.6c5.1-2.4,11.2-0.2,13.6,4.9
		C234.9,124.1,232.7,130.2,227.6,132.6z"
        />
        <ellipse
          transform="matrix(0.9732 -0.2298 0.2298 0.9732 -34.2231 45.0857)"
          cx="176.5"
          cy="169.5"
          rx="28.9"
          ry="28.9"
        ></ellipse>
        <path
          d="M333.5,93.2c-3.3-9.8-7.4-18.7-12.2-26.9h0C285,4.5,208.5-11.2,145,7.3C50.4,34.9-48.4,186.5,26.2,287.6
		c28,37.9,73.7,56.2,117.8,55.2C253.5,340.5,378.4,226.9,333.5,93.2z M264,206.3c0,15.2-5,28.1-14.4,37.3
		c-9.3,9.1-22.1,13.8-37.1,13.8h-71.9c-14.7,0-27.5-4.8-36.8-13.9c-9.6-9.4-14.7-22.4-14.7-37.7v-72.3c0-30.6,20.6-51.2,51.2-51.2
		h72.7c14.9,0,27.8,5,37.1,14.3h0c9.2,9.3,14.1,22,14.1,36.8V206.3z"
        />
      </g>
    </svg>
  );
};

export default InstaBoxIcon;
