import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, SlideStyleProps>(theme => ({
  slideCanvasWrapper: {
    position: 'relative',
    backgroundColor: '#fff',
    aspectRatio: '16/9',
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
  },
  slideCanvas: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  slideTitle: {
    position: 'absolute',
    left: 0,
    top: '7%',
    right: 0,
    bottom: 'auto',
    marginTop: 0,
    marginBottom: 0,
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : '#3a5df6'),
    fontSize: '3.8em',
    lineHeight: '110%',
    textAlign: 'center',
  },
  stageOne: {
    position: 'absolute',
    left: 0,
    top: 'auto',
    right: 0,
    bottom: '11%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pyramidDiamond: {
    width: '45%',
    opacity: 0.9,
  },
  shapeLabel: {
    position: 'absolute',
    right: '6.5%',
    width: '15.5%',
  },
  shapeLabelLeft: {
    left: '6.5%',
    right: 'auto',
    textAlign: 'right',
  },
  labelTitle: {
    marginTop: 0,
    marginBottom: '5%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
    fontSize: '1.5em',
    lineHeight: '120%',
  },
  labelText: {
    marginBottom: 0,
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : '#0e0e1e'),
    fontSize: '1.1em',
    lineHeight: '125%',
  },
  dottedLine: {
    position: 'absolute',
    right: '23%',
    width: '3.5%',
    borderTop: ({ colorTheme }) => (colorTheme ? `1.5px dotted ${colorTheme.textColor}` : '1.5px dotted #0e0e1e'),
  },
  dottedLineDotTwo: {
    left: '23%',
    right: 'auto',
    width: '7%',
  },
  dottedLineDotThree: {
    width: '9.5%',
  },
  dottedLineDotFour: {
    left: '23%',
    right: 'auto',
    width: '12.5%',
  },
  pyramidIcon: {
    position: 'absolute',
    bottom: '19%',
    width: '3.5%',
  },
  pyramidIconTwo: {
    bottom: '20%',
  },
  pyramidIconThree: {
    bottom: '26%',
  },
  pyramidIconFour: {
    bottom: 'auto',
  },
  stageTwo: {
    position: 'absolute',
    left: '0%',
    top: 'auto',
    right: '0%',
    bottom: '26%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pyramidDiamondTwo: {
    width: '38%',
    opacity: 0.9,
    color: '#17befc',
  },
  stageThree: {
    position: 'absolute',
    left: '0%',
    top: 'auto',
    right: '0%',
    bottom: '41%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pyramidDiamondThree: {
    width: '32%',
    opacity: 0.9,
    color: '#1293f9',
  },
  stageFour: {
    position: 'absolute',
    left: '0%',
    top: 'auto',
    right: '0%',
    bottom: '56.5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pyramidDiamondFour: {
    width: '26%',
    opacity: 0.9,
    color: '#4b30e9',
  },
}));
