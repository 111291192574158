import { RootState } from 'services/store/root-reducer';

export const selectUser = (store: RootState): RootState['auth']['user'] => {
  return store.auth.user;
};

export const selectLoginState = (store: RootState): RootState['auth']['login'] => {
  return store.auth.login;
};

export const selectRegisterState = (store: RootState): RootState['auth']['register'] => {
  return store.auth.register;
};

export const selectLogoutState = (store: RootState): RootState['auth']['logout'] => {
  return store.auth.logout;
};

export const selectDeletionState = (store: RootState): RootState['auth']['delete'] => {
  return store.auth.delete;
};

export const selectResetPasswordState = (store: RootState): RootState['auth']['resetPassword'] => {
  return store.auth.resetPassword;
};
