import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import { useStyles } from './AccountSubtitle.styles';

interface AccountSubtitleProps {
  subtitle: string;
}

export const AccountSubtitle = ({ subtitle }: AccountSubtitleProps) => {
  const styles = useStyles();

  return (
    <>
      <Divider className={styles.divider} />
      <Typography className={styles.accountSubtitle}>{subtitle}</Typography>
    </>
  );
};
