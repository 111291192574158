import React from 'react';
import cn from 'classnames';
import { Typography } from '@material-ui/core';
import { useStyles } from './TooltipTitle.styles';

interface TooltipTitleProps {
  className?: string;
  title: string;
}

export const TooltipTitle: React.FC<TooltipTitleProps> = ({ className, title }) => {
  const styles = useStyles();

  return <Typography className={cn(styles.tooltipTitle, className)}>{title}</Typography>;
};
