import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  subtitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.utility.black1,
    maxWidth: 320, // temporary until we scale the UI
    marginBottom: theme.spacing(3.5),
  },
  pricingContainer: {
    display: 'flex',
    alignItems: 'end',
    gap: '12px',
  },

  divider: {
    height: 2,
    backgroundColor: theme.palette.utility.gray1,
    margin: theme.spacing(4.75, 0, 4.75),
  },

  perksContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  subscriptionPerk: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  subscriptionPerkText: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
    color: theme.palette.utility.black1,
    marginLeft: theme.spacing(1.75),
  },
  checkedWrapper: {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkedIcon: {
    width: 14,
    height: 14,

    '& path': {
      stroke: theme.palette.common.white,
      strokeWidth: 6,
    },
  },

  subscriptionContinueButton: {
    marginTop: theme.spacing(3.75),
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  subscriptionContinueButtonText: {
    fontSize: theme.typography.pxToRem(20),
  },
  subscriptionErrorBar: {
    marginBottom: theme.spacing(3.75),
  },
}));
