import cn from 'classnames';
import { SlideProps } from '../../types';
import { useStyles } from './SlideFourth.styles';
import { useRootStyles } from '../../common.styles';

export const SlideFourth: React.FC<SlideProps> = ({ colorTheme, photoPack, fontPair }) => {
  const styles = useStyles({ fontPair, colorTheme });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[19].image;
  const backupImageSource: string = photos?.items[19].backupImage!;

  return (
    <div className={cn(styles.slideCanvas, rootStyles.root)}>
      <img
        src={imageSource ? imageSource : backupImageSource}
        loading="lazy"
        alt=""
        className={styles.halfBackgroundImage}
      />
      <div className={cn(styles.backgroundOverlay, styles.half)} />
      <div className={styles.halfContainer}>
        <h1 className={styles.agendaTitleEdge}>Agenda</h1>
      </div>
      <div className={rootStyles.svgWhite}>
        <svg
          className={styles.svgWhiteFourth}
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1600 900"
        >
          <polygon fill="#fff" points="1600,0 800.2,0 604.6,900 1600,900"></polygon>
        </svg>
      </div>
      <div className={styles.rightHalfClearContainerEdge}>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>1)</h2>
          <h2 className={styles.agendaText}>Lorem ipsum dolor amet</h2>
        </div>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>2)</h2>
          <h2 className={styles.agendaText}>Aenean commodo</h2>
        </div>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>3)</h2>
          <h2 className={styles.agendaText}>Nullam dictum felis</h2>
        </div>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>4)</h2>
          <h2 className={styles.agendaText}>In enim justo</h2>
        </div>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>5)</h2>
          <h2 className={styles.agendaText}>Vivamus elementum</h2>
        </div>
      </div>
      <div className={rootStyles.slideNumberEdge}>
        <div className={rootStyles.edgeSlideNumberShape}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 104" display="block">
            <polygon
              fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              points="22.5,0 0,104 82.5,104 105,0 "
            ></polygon>
          </svg>
        </div>
        <h1 className={cn(rootStyles.number, rootStyles.numberEdge)}>4</h1>
      </div>
    </div>
  );
};

export default SlideFourth;
