import React, { useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router';
import cn from 'classnames';
import { List } from '@material-ui/core';
import {
  LayoutIcon,
  LogoUploadIcon,
  ColorSchemeIcon,
  FontSchemeIcon,
  PhotoPackIcon,
  PreviewIcon,
  DownloadIcon,
  ToolbarHighlight,
} from 'assets/icons';
import { routes } from 'const';
import { i18nClient } from 'services/translations';
import type { CreatorRouteParams } from 'views/Creator/types';
import { CreatorToolbarItem } from './components';
import { useStyles } from './CreatorToolbar.styles';

const createToolbarInventory = (presentationId: string, isFullSize: boolean) => [
  {
    title: i18nClient.t('creatorToolbar.layout'),
    destination: routes.creator.layout.link(presentationId),
    Icon: LayoutIcon,
  },
  {
    title: i18nClient.t('creatorToolbar.logo'),
    destination: routes.creator.logo.link(presentationId),
    Icon: LogoUploadIcon,
  },
  {
    title: i18nClient.t('creatorToolbar.color'),
    destination: routes.creator.color.link(presentationId),
    Icon: ColorSchemeIcon,
  },
  {
    title: i18nClient.t('creatorToolbar.font'),
    destination: routes.creator.font.link(presentationId),
    Icon: FontSchemeIcon,
  },
  {
    title: i18nClient.t('creatorToolbar.photo'),
    destination: routes.creator.photo.link(presentationId),
    Icon: PhotoPackIcon,
  },
  {
    title: i18nClient.t('creatorToolbar.preview'),
    destination: isFullSize ? routes.creator.preview.link(presentationId) : routes.creator.panel.link(presentationId),
    Icon: PreviewIcon,
  },
  {
    title: i18nClient.t('creatorToolbar.download'),
    destination: routes.creator.download.link(presentationId),
    Icon: DownloadIcon,
  },
];

interface CreatorToolbarProps {
  className?: string;
  isFullSize?: boolean;
}

export const CreatorToolbar: React.FC<CreatorToolbarProps> = ({ className, isFullSize }) => {
  const styles = useStyles();
  const { presentationId } = useParams<CreatorRouteParams>();
  const toolbarInventory = useMemo(() => {
    return presentationId ? createToolbarInventory(presentationId, isFullSize!) : [];
  }, [presentationId, isFullSize]);
  const [positionOffset, setPositionOffset] = useState<undefined | number>();

  const toolbarContainerRef = useRef<HTMLDivElement>(null);
  const toolbarHighlightRef = useRef<HTMLDivElement>(null);

  const handleSetHighlightPosition = (element: HTMLAnchorElement) => {
    const toolbarContainer = toolbarContainerRef.current;
    const toolbarHighlight = toolbarHighlightRef.current;

    if (toolbarContainer && toolbarHighlight) {
      const offsetActiveItem = element.getBoundingClientRect();
      const offsetToolbarContainer = toolbarContainer.getBoundingClientRect();

      const offsetTop = Math.floor(
        offsetActiveItem.top -
          offsetToolbarContainer.top -
          (toolbarHighlight.offsetHeight - offsetActiveItem.height) / 2
      );
      const scaledOffsetTop = Math.floor((offsetTop * 100) / 80);

      setPositionOffset(scaledOffsetTop);
    }
  };

  return (
    <div ref={toolbarContainerRef} className={cn(className, styles.toolbarContainer)}>
      <List className={styles.toolbarList} component="nav" disablePadding>
        {toolbarInventory.map(({ destination, title, Icon }, index) => (
          <CreatorToolbarItem
            key={index}
            title={title}
            destination={destination}
            setHighlightPosition={handleSetHighlightPosition}
            Icon={Icon}
          />
        ))}

        <div
          ref={toolbarHighlightRef}
          style={{ transform: `scale(.8) translateY(${positionOffset}px)` }}
          className={styles.toolbarHightlight}
        />
        <div className={styles.svgContainer}>
          <ToolbarHighlight />
        </div>
      </List>
    </div>
  );
};
