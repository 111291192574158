import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  createNewPresentation: {
    display: 'flex',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  createNewPresentationIcon: {
    width: 50,
    height: 50,
    fill: theme.palette.primary.main,
  },

  thumbnailPreviewContainer: {
    position: 'relative',

    '&:hover $thumbnailToolbar': {
      opacity: 1,
      visibility: 'visible',
    },
  },

  thumbnailPreviewAction: {
    width: 390,
    height: 220,
    borderRadius: theme.shape.borderRadius * 2,

    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[200]}`,
  },
  thumbnailPreview: {
    height: '100%',
    backgroundSize: 'contain',
    borderRadius: theme.shape.borderRadius * 2,
  },
  thumbnailToolbar: {
    position: 'absolute',
    top: 10,
    right: 10,
    visibility: 'hidden',
    opacity: 0,

    transition: theme.transitions.create(['visibility', 'opacity'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  thumbnailToolbarItem: {
    backgroundColor: theme.palette.common.white,

    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },

    '&:hover svg': {
      fill: theme.palette.common.white,
    },

    '& svg': {
      fill: theme.palette.primary.main,
    },
  },

  textField: {
    maxWidth: 300,
  },
  presentationName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1.625),
  },
  presentationInfo: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.utility.gray3,
  },
}));
