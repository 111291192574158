import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  previewModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem 2.7rem',
    borderRadius: theme.shape.borderRadius * 4,
    borderColor: 'transparent',

    '&:focus-visible': {
      outline: 'none',
    },
  },
  previewModalTitle: {
    marginTop: theme.spacing(2),
    fontSize: '2em',
    fontWeight: theme.typography.fontWeightBold,
  },
  previewModalDescription: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    fontSize: '1.1em',
  },
  uploadProgressBarRoot: {
    width: 304,
    height: 18,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(1.375),
  },
  previewModalBackdrop: {
    backgroundColor: theme.palette.slide.modal,
  },
}));
