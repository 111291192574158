import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CardActionArea, CardMedia, Fade } from '@material-ui/core';
import { Message } from 'components';
import { UploadStep } from 'components/CreatorLogoUpload';
import { useStyles } from './CreatorSavedImages.styles';
import { ImageItem } from 'modules/storage/types';

interface CreatorSavedImagesProps {
  savedImages: ImageItem[];
  fetchSavedImages: () => void;
  setPreviewImageSource: (source: string) => void;
  setUploadStep: React.Dispatch<React.SetStateAction<UploadStep>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  tabs: string[];
  presentationId: string;
  savePresentationLogo: (name: string, presentationId: string) => void;
}

export const CreatorSavedImages: React.FC<CreatorSavedImagesProps> = ({
  savedImages,
  fetchSavedImages,
  setPreviewImageSource,
  setUploadStep,
  setActiveTab,
  tabs,
  presentationId,
  savePresentationLogo,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const handleClick = (imageName: string, imageSource: string): void => {
    savePresentationLogo(imageName, presentationId);
    setPreviewImageSource(imageSource);
    setUploadStep(UploadStep.Preview);
    setActiveTab(tabs[0]);
  };

  useEffect(() => {
    fetchSavedImages();
  }, [fetchSavedImages]);

  return (
    <Fade in>
      {!savedImages.length ? (
        <Message text={t('creatorLogoUpload.emptyStorageMessage')} />
      ) : (
        <div className={styles.savedImagesContainer}>
          {savedImages.map(savedImage => (
            <CardActionArea
              key={savedImage.name}
              className={styles.savedImageContainer}
              onClick={() => handleClick(savedImage.name, savedImage.source)}
            >
              <CardMedia className={styles.savedImagePreview} image={savedImage.source} />
            </CardActionArea>
          ))}
        </div>
      )}
    </Fade>
  );
};
