import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChromePicker, ColorResult } from 'react-color';
import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import { Grid, Fade } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Button, Loader } from 'components';
import { SingleColorBar } from './components/SingleColorBar';
import { ColorPickerButton } from './components/ColorPickerButton';
import { useConfigurationManagement } from 'modules/configuration/hooks';
import type { ColorScheme } from 'modules/configuration/types';
import { mergeColorSchemeWithDefault, defaultSchemeValues } from './ColorCustomize.utils';
import { useStyles } from './ColorCustomize.styles';
import { usePresentationManagement } from 'modules/presentation/hooks';

interface ColorCustomizeProps {
  colors: ColorScheme[] | undefined;
  currentColorScheme: ColorScheme | undefined;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  tabs: string[];
  presentationId: string;
}

export const ColorCustomize = ({
  colors,
  currentColorScheme,
  setActiveTab,
  tabs,
  presentationId,
}: ColorCustomizeProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const {
    saveUserColorScheme,
    fetchUserColorSchemes,
    isSchemeLoading,
    isSchemeErrored,
    isSchemeSucceed,
    prevIsSchemeSucceed,
  } = useConfigurationManagement();

  const { savePresentationColors } = usePresentationManagement();

  const initialSchemeValues = currentColorScheme
    ? mergeColorSchemeWithDefault(currentColorScheme, defaultSchemeValues)
    : defaultSchemeValues;

  const [schemeValues, setSchemeValues] = useState(initialSchemeValues);
  const [displayColorPickerId, setDisplayColorPickerId] = useState<number | undefined>(undefined);

  const handleColorChange = (color: ColorResult, id: number): void => {
    setSchemeValues(prevSchemeValues =>
      prevSchemeValues.map(item => (item.id === id ? { ...item, color: color.hex } : item))
    );
  };

  const handleSchemeSaveApply = (applyColors: boolean): void => {
    const colorScheme = {
      textColor: schemeValues[0].color,
      accent1Color: schemeValues[1].color,
      accent2Color: schemeValues[2].color,
      accent3Color: schemeValues[3].color,
      accent4Color: schemeValues[4].color,
      accent5Color: schemeValues[5].color,
      accent6Color: schemeValues[6].color,
      name: Math.random().toString(36).substring(3),
      internalId: uuidv4(),
      createdAt: firebase.firestore.Timestamp.now(),
      order: Math.max(...colors!.map(o => o.order)),
    };

    applyColors ? savePresentationColors(colorScheme, presentationId) : saveUserColorScheme(colorScheme);
  };

  useEffect(() => {
    if (isSchemeSucceed !== prevIsSchemeSucceed) {
      fetchUserColorSchemes();
      setActiveTab(tabs[2]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSchemeSucceed, prevIsSchemeSucceed]);

  return (
    <Fade in>
      <div className={styles.root}>
        <Loader loading={isSchemeLoading}>
          <Grid container spacing={1}>
            {schemeValues.map(item => (
              <Grid container item xs={12} spacing={1} key={item.title}>
                <Grid item xs={3}>
                  <div className={styles.titleCell}>{item.title}</div>
                </Grid>

                <Grid item xs={3}>
                  <div className={styles.cell}>
                    <div>
                      <ColorPickerButton color={item.color} onClick={() => setDisplayColorPickerId(item.id)} />
                      {displayColorPickerId === item.id && (
                        <div className={styles.modalContainer}>
                          <div className={styles.modalCover} onClick={() => setDisplayColorPickerId(undefined)} />
                          <ChromePicker
                            color={item.color}
                            onChangeComplete={color => handleColorChange(color, item.id)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={styles.cell}>
                    <SingleColorBar width={item.width} color={item.color} />
                  </div>
                </Grid>
              </Grid>
            ))}
          </Grid>

          {isSchemeErrored && (
            <Fade in timeout={700}>
              <Alert className={styles.errorMessage} severity="error">
                {t('creatorColorScheme.saveSchemeError')}
              </Alert>
            </Fade>
          )}

          <Button
            className={styles.saveButton}
            // startIcon={<ZapIcon className={styles.buttonIcon} />}
            onClick={() => handleSchemeSaveApply(true)}
          >
            {t('creatorColorScheme.applyColors')}
          </Button>
          <Button
            className={styles.saveButton}
            // startIcon={<SaveIcon className={styles.buttonIcon} />}
            onClick={() => handleSchemeSaveApply(false)}
          >
            {t('creatorColorScheme.saveColors')}
          </Button>
          <Button
            className={styles.resetButton}
            // startIcon={<ResetIcon className={styles.buttonIcon} />}
            onClick={() => setSchemeValues(initialSchemeValues)}
          >
            {t('creatorColorScheme.resetColors')}
          </Button>
        </Loader>
      </div>
    </Fade>
  );
};
