import { makeStyles, Theme } from '@material-ui/core/styles';

type LoaderStyles = {
  centered?: boolean;
};

export const useStyles = makeStyles<Theme, LoaderStyles>(theme => ({
  root: {
    minHeight: 128,
    position: 'relative',
    width: '100%',
  },
  spinnerWrapper: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',

    display: 'flex',
    alignItems: ({ centered }) => (centered ? 'center' : 'flex-start'),
    justifyContent: 'center',
    marginTop: ({ centered }) => (centered ? 0 : theme.spacing(37.5)),
  },
  content: {
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shortest,
      easing: 'ease-out',
    }),
  },
  contentLoading: {
    opacity: 0.5,
  },
}));
