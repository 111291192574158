import cn from 'classnames';
import { SlideProps } from '../../types';
import { useStyles } from './SlideFourth.styles';
import { useRootStyles } from '../../common.styles';
import SlideNumber from '../components/SlideNumber';

export const SlideFourth: React.FC<SlideProps> = ({ colorTheme, photoPack, fontPair, logoUrl }) => {
  const styles = useStyles({ fontPair, colorTheme });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[19].image;
  const backupImageSource: string = photos?.items[19].backupImage!;

  return (
    <div className={cn(styles.slideCanvas, rootStyles.root)}>
      <img
        src={imageSource ? imageSource : backupImageSource}
        loading="lazy"
        alt=""
        className={styles.halfBackgroundImage}
      />
      <div className={cn(styles.backgroundOverlay, styles.backgroundOverlayAgendaFlow)} />
      <div className={styles.flowAgendaTextContainer}>
        <h1 className={styles.transitionFlow}>Agenda</h1>
      </div>

      <div className={rootStyles.svgWhite}>
        <svg
          className={styles.svgWhiteFourth}
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1600 900"
        >
          <path
            fill="#fff"
            d="M0,0v900h1600V0H0z M766.7,439.2c-24.3,51.2-60.9,90.8-80.4,146.5c-17.2,49.3-12.5,97-24.8,146.1
	C648.2,785,602,823.1,553,830.9c-54,8.6-101.1-13.2-143.8-48.7c-30.3-25.2-60.6-55.8-97.6-67.5c-38.7-12.2-80.3-3.4-119-16.5
	c-88.2-29.9-99.8-138.3-89.5-225.4c10.3-86.9,39.5-167.3,43.3-256.1c1.8-41.6,2.4-86.7,36.4-113.4c29-22.8,68.9-16.9,102-2.5
	c33,14.3,63.8,36.2,98.6,43.6c59.7,12.7,118.7-18.6,175.8-42c45.3-18.6,104.5-33,149.8-6.1c4.9,2.9,9.6,6.3,14.2,10.1
	c32,26.7,56.2,74,67.4,113.7C810.4,290.5,797.2,374.8,766.7,439.2z"
          ></path>
        </svg>
      </div>
      <div className={styles.rightHalfClearContainer}>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>1)</h2>
          <h2 className={styles.agendaText}>Lorem ipsum dolor amet</h2>
        </div>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>2)</h2>
          <h2 className={styles.agendaText}>Aenean commodo</h2>
        </div>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>3)</h2>
          <h2 className={styles.agendaText}>Nullam dictum felis</h2>
        </div>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>4)</h2>
          <h2 className={styles.agendaText}>In enim justo</h2>
        </div>
        <div className={styles.agendaItem}>
          <h2 className={styles.agendaNumber}>5)</h2>
          <h2 className={styles.agendaText}>Vivamus elementum</h2>
        </div>
      </div>
      <SlideNumber slide="4" colorTheme={colorTheme} fontPair={fontPair} />
      {logoUrl && (
        <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
          <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
        </div>
      )}
    </div>
  );
};

export default SlideFourth;
