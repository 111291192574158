import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import cn from 'classnames';
import { Typography, CardActionArea, CardMedia, Tooltip, IconButton, TextField } from '@material-ui/core';
import type { TransitionProps } from '@material-ui/core/transitions';
import { AddCircleIcon, DeleteIcon } from 'assets/icons';
import { routes } from 'const';
import { Editable } from 'components';
import { PresentationDashboard } from 'modules/configuration/types';
import { useStyles } from './DashboardPresentation.styles';

interface DashboardPresentationProps {
  presentation: PresentationDashboard;
  formatter: Intl.DateTimeFormat;
  onNameChange: (presentationName: string, presentationId: string) => void;
  onDelete: (presentationId: string) => void;
  style?: TransitionProps['style']; // used by transition component
}

export const DashboardPresentation: React.FC<DashboardPresentationProps> = ({
  presentation,
  formatter,
  onNameChange,
  onDelete,
  style,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const presentationName: string = presentation.name || t('dashboard.templateName');
  const handlePresentationNameChange = (presentationId: string) => (presentationName: string) => {
    return onNameChange(presentationName, presentationId);
  };

  return (
    <div style={style}>
      <div className={styles.thumbnailPreviewContainer}>
        <CardActionArea
          className={styles.thumbnailPreviewAction}
          onClick={() => history.push(routes.creator.layout.link(presentation.uid))}
        >
          <CardMedia className={styles.thumbnailPreview} image={presentation.source} />
        </CardActionArea>

        <div className={styles.thumbnailToolbar}>
          <Tooltip title="Delete" placement="top" enterDelay={500} arrow>
            <IconButton className={styles.thumbnailToolbarItem} onClick={() => onDelete(presentation.uid)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <Editable defaultValue={presentationName} onSubmit={handlePresentationNameChange(presentation.uid)}>
        {({ editing, value, onChange }) =>
          editing ? (
            <TextField
              className={styles.textField}
              autoFocus
              variant="outlined"
              margin="dense"
              size="small"
              onChange={onChange}
              value={value}
            />
          ) : (
            <Typography className={styles.presentationName}>{presentationName}</Typography>
          )
        }
      </Editable>

      <Typography className={styles.presentationInfo}>{`${t('dashboard.updated')}: ${formatter.format(
        presentation.modifiedAt?.toDate()
      )}`}</Typography>
    </div>
  );
};

export const DashboardPresentationAddNew: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div>
      <CardActionArea
        className={cn(styles.thumbnailPreviewAction, styles.createNewPresentation)}
        onClick={() => history.push(routes.creator.base.link())}
      >
        <AddCircleIcon className={styles.createNewPresentationIcon} />
      </CardActionArea>
      <Typography className={styles.presentationName}>{t('dashboard.createNewTemplate')}</Typography>
    </div>
  );
};
