import cn from 'classnames';
import { useStyles } from './SlideSeventh.styles';
import { SlideProps } from '../../types';
import { MagIcon, PeopleIcon, PuzzleIcon, SpeachBubbleIcon, SupportIcon, ToolsIcon } from './icons';
import { useRootStyles } from '../../common.styles';
import SlideNumber from '../components/SlideNumber';

export const SlideSeventh: React.FC<SlideProps> = ({ logoUrl, colorTheme, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  return (
    <div className={cn(styles.slideCanvas, rootStyles.root)}>
      <h1 className={styles.slideTitle}>Six Icons</h1>
      <div className={styles.iconsRowOne}>
        <div className={styles.iconBlock}>
          <div className={cn(styles.iconContainer, styles.iconContainerTop)}>
            <div className={cn(styles.edgeIconBox, styles.one)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              >
                <path
                  d="M93,36.7C87.8,26.3,80.1,17,72.4,10.9C64.5,4.7,54.9,0.7,45,0.1C33.4-0.7,25.9,3.2,17.5,11.2
	c-8.8,8.4-15.2,19.7-17,32.1c-2,14,1.4,29,10.5,39.4c9.6,11,24.3,15.7,38.4,17c15.6,1.4,33-1.6,43-14.2
	C103.7,71.4,101.1,52.7,93,36.7z"
                />
              </svg>
            </div>
            <img src={SpeachBubbleIcon} loading="lazy" alt="speachbubbleicon" className={styles.image} />
          </div>
          <div className={styles.iconLabel}>
            <h1 className={cn(styles.labelTitle, styles.labelTitleIcon)}>Icon Title</h1>
            <p className={cn(styles.labelText, styles.labelTextIcon)}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.iconBlock}>
          <div className={cn(styles.iconContainer, styles.iconContainerTop)}>
            <div className={cn(styles.edgeIconBox, styles.two)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                fill={colorTheme ? colorTheme?.accent2Color : 'CurrentColor'}
              >
                <path
                  d="M93.7,19.4C83.1,1.3,60.8-3.3,42.3,2.1C14.7,10.2-14.1,54.4,7.7,83.9C15.8,94.9,29.2,100.3,42,100
	c31.9-0.7,68.3-33.8,55.2-72.8C96.3,24.3,95.1,21.7,93.7,19.4z"
                />
              </svg>
            </div>
            <img src={PeopleIcon} loading="lazy" alt="peopleicon" className={styles.image} />
          </div>
          <div className={styles.iconLabel}>
            <h1 className={cn(styles.labelTitle, styles.labelTitleIcon)}>Icon Title</h1>
            <p className={cn(styles.labelText, styles.labelTextIcon)}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.iconBlock}>
          <div className={cn(styles.iconContainer, styles.iconContainerTop)}>
            <div className={cn(styles.edgeIconBox, styles.three)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                fill={colorTheme ? colorTheme?.accent3Color : 'CurrentColor'}
              >
                <path
                  d="M76.7,6.3C73.7,4,70.2,2.1,65.8,1C51-2.8,34.2,5,23.1,13.2C9.8,23,1.4,37.7,0.1,52.8c-0.9,10.8,2,21.9,9.3,30.7
	C21.7,98.3,46,103.8,65.5,97.3s33.1-23.9,34.4-42.1c0.8-11.1-3-21.6-9.2-31.2C86.6,17.6,82.7,11,76.7,6.3z"
                />
              </svg>
            </div>
            <img src={ToolsIcon} loading="lazy" alt="toolsicon" className={styles.image} />
          </div>
          <div className={styles.iconLabel}>
            <h1 className={cn(styles.labelTitle, styles.labelTitleIcon)}>Icon Title</h1>
            <p className={cn(styles.labelText, styles.labelTextIcon)}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.iconsRowTwo}>
        <div className={styles.iconBlock}>
          <div className={cn(styles.iconContainer, styles.iconContainerFour)}>
            <div className={cn(styles.edgeIconBox, styles.four)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                fill={colorTheme ? colorTheme?.accent4Color : 'CurrentColor'}
              >
                <path
                  d="M98.1,27.2C89-8.6,42.1-5,19.8,14.8C8.1,25.2,0.8,40.8,0,56.9c-0.8,16.3,6.3,34,20.4,40.4
	c10.5,4.7,22.7,2.4,33.4-1.7c13.5-5.2,28.8-6.7,37.6-18.7S101.9,41.9,98.1,27.2z"
                />
              </svg>
            </div>
            <img src={MagIcon} loading="lazy" alt="magicon" className={styles.image} />
          </div>
          <div className={styles.iconLabel}>
            <h1 className={cn(styles.labelTitle, styles.labelTitleIcon)}>Icon Title</h1>
            <p className={cn(styles.labelText, styles.labelTextIcon)}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.iconBlock}>
          <div className={cn(styles.iconContainer, styles.iconContainerFive)}>
            <div className={cn(styles.edgeIconBox, styles.five)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                fill={colorTheme ? colorTheme?.accent5Color : 'CurrentColor'}
              >
                <path
                  d="M7,63.3C12.2,73.7,19.9,83,27.6,89.1c7.9,6.2,17.6,10.2,27.4,10.8c11.6,0.8,19.1-3.1,27.4-11.1
	c8.8-8.4,15.2-19.7,17-32.1c2-14-1.4-29-10.5-39.4c-9.6-11-24.3-15.7-38.4-17c-15.6-1.4-33,1.6-43,14.2C-3.7,28.6-1.1,47.3,7,63.3z"
                />
              </svg>
            </div>
            <img src={SupportIcon} loading="lazy" alt="supporticon" className={styles.image} />
          </div>
          <div className={styles.iconLabel}>
            <h1 className={cn(styles.labelTitle, styles.labelTitleIcon)}>Icon Title</h1>
            <p className={cn(styles.labelText, styles.labelTextIcon)}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.iconBlock}>
          <div className={cn(styles.iconContainer, styles.iconContainerSix)}>
            <div className={cn(styles.edgeIconBox, styles.six)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                fill={colorTheme ? colorTheme?.accent6Color : 'CurrentColor'}
              >
                <path
                  d="M80.6,93.5C98.7,83,103.3,60.7,97.9,42.2C89.8,14.7,45.6-14.1,16.1,7.6C5.1,15.8-0.3,29.1,0,41.9
	c0.7,31.8,33.8,68.2,72.8,55.1C75.7,96.1,78.3,94.9,80.6,93.5z"
                />
              </svg>
            </div>
            <img src={PuzzleIcon} loading="lazy" alt="puzzleicon" className={styles.image} />
          </div>
          <div className={styles.iconLabel}>
            <h1 className={cn(styles.labelTitle, styles.labelTitleIcon)}>Icon Title</h1>
            <p className={cn(styles.labelText, styles.labelTextIcon)}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
      </div>
      <SlideNumber slide="7" colorTheme={colorTheme} fontPair={fontPair} />
      {logoUrl && (
        <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
          <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
        </div>
      )}
    </div>
  );
};

export default SlideSeventh;
