import cn from 'classnames';
import { SlideProps } from '../../types';
import { BoxIcon, BinocularsIcon, LegalIcon, MapMarkersIcon } from './icons';
import { useStyles } from './SlideEight.styles';
import { useRootStyles } from '../../common.styles';

const SlideEight: React.FC<SlideProps> = ({ logoUrl, colorTheme, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  return (
    <div className={cn(styles.slideCanvasWrapper, rootStyles.root)}>
      <div className={styles.slideCanvas}>
        <h1 className={styles.slideTitle}>Four Stage Pyramid</h1>
        <div className={styles.stageOne}>
          <div className={styles.pyramidDiamond}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 20"
              fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              display="block"
            >
              <g id="a">
                <g id="b">
                  <g id="c">
                    <g id="d">
                      <g id="e">
                        <polygon points="25 0 0 10 25 20 50 10 25 0"></polygon>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <img src={BinocularsIcon} loading="lazy" alt="" className={styles.pyramidIcon} />
          <div className={styles.dottedLine}></div>
          <div className={styles.shapeLabel}>
            <h1 className={styles.labelTitle}>Stage 1 Title</h1>
            <p className={styles.labelText}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.stageTwo}>
          <div className={cn(styles.pyramidDiamond, styles.pyramidDiamondTwo)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 20"
              fill={colorTheme ? colorTheme?.accent2Color : 'CurrentColor'}
              display="block"
            >
              <g id="a">
                <g id="b">
                  <g id="c">
                    <g id="d">
                      <g id="e">
                        <polygon points="25 0 0 10 25 20 50 10 25 0"></polygon>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <img src={BoxIcon} loading="lazy" alt="" className={cn(styles.pyramidIcon, styles.pyramidIconTwo)} />
          <div className={cn(styles.dottedLine, styles.dottedLineDotTwo)}></div>
          <div className={cn(styles.shapeLabel, styles.shapeLabelLeft)}>
            <h1 className={styles.labelTitle}>Stage 2 Title</h1>
            <p className={styles.labelText}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.stageThree}>
          <div className={cn(styles.pyramidDiamondThree, 'w-embed')}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 20"
              fill={colorTheme ? colorTheme?.accent3Color : 'CurrentColor'}
              display="block"
            >
              <g id="a">
                <g id="b">
                  <g id="c">
                    <g id="d">
                      <g id="e">
                        <polygon points="25 0 0 10 25 20 50 10 25 0"></polygon>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <img src={LegalIcon} loading="lazy" alt="" className={cn(styles.pyramidIcon, styles.pyramidIconThree)} />
          <div className={cn(styles.dottedLine, styles.dottedLineDotThree)}></div>
          <div className={styles.shapeLabel}>
            <h1 className={styles.labelTitle}>Stage 3 Title</h1>
            <p className={styles.labelText}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.stageFour}>
          <div className={styles.pyramidDiamondFour}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 20"
              fill={colorTheme ? colorTheme?.accent4Color : 'CurrentColor'}
              display="block"
            >
              <g id="a">
                <g id="b">
                  <g id="c">
                    <g id="d">
                      <g id="e">
                        <polygon points="25 0 0 10 25 20 50 10 25 0"></polygon>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <img src={MapMarkersIcon} loading="lazy" alt="" className={cn(styles.pyramidIcon, styles.pyramidIconFour)} />
          <div className={cn(styles.dottedLine, styles.dottedLineDotFour)}></div>
          <div className={cn(styles.shapeLabel, styles.shapeLabelLeft)}>
            <h1 className={styles.labelTitle}>Stage 4 Title</h1>
            <p className={styles.labelText}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={rootStyles.slideNumberEdge}>
          <div className={rootStyles.edgeSlideNumberShape}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 104" display="block">
              <polygon
                fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
                points="22.5,0 0,104 82.5,104 105,0 "
              ></polygon>
            </svg>
          </div>
          <h1 className={cn(rootStyles.number, rootStyles.numberEdge)}>8</h1>
        </div>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SlideEight;
