import { SlideStyleProps } from './types';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { theme } from 'theme';
export const useRootStyles = makeStyles<Theme, SlideStyleProps>({
  root: {
    '& h1': {
      fontFamily: ({ fontPair }) => fontPair?.majorFont.familyName,
      letterSpacing: '-0.05em',
      fontWeight: 'inherit',
    },
    '& h2': {
      fontFamily: ({ fontPair }) => fontPair?.minorFont.familyName,
      fontWeight: '400',
    },
    '& p': {
      fontFamily: ({ fontPair }) => fontPair?.minorFont.familyName,
      color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
      fontWeight: '400',
    },
  },
  fullBackgroundImage: {
    position: 'relative',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius * 2,
  },
  logoContainer: {
    position: 'absolute',
    left: '0%',
    top: '6%',
    right: 'auto',
    bottom: 'auto',
    display: 'flex',
    width: '23%',
    height: '19.5%',
    padding: '2% 3%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  logoContainerSvg: {
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    display: 'block',
    width: '100%',
    height: '100%',
    padding: '0%',
    backgroundColor: 'transparent',
  },
  logoContainerSvgOne: {
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    display: 'block',
    // height: '100%',
    padding: '0%',
    backgroundColor: 'transparent',
  },
  logoContainerStandard: {
    top: 'auto',
    bottom: '0%',
    width: '19%',
    height: '13.5%',
    padding: '2.2% 2.3%',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
  },
  logoContainerTitleTwo: {
    left: '58%',
    top: 'auto',
    right: 'auto',
    bottom: '0%',
    width: '22.5%',
    height: '23%',
    padding: '2.5%',
  },
  logoContainerTransitionSlide: {
    position: 'absolute',
    left: 'auto',
    top: 'auto',
    right: 'auto',
    bottom: '0%',
    height: '100%',
  },
  logoContainerRoundTransitionRight: {
    left: 'auto',
    top: 'auto',
    right: '4.7%',
    bottom: '8.7%',
    width: '18.5%',
    height: '16.5%',
    padding: '0%',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
  },
  logo: {
    maxHeight: '100%',
    maxWidth: '100%',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  divBlock: {
    position: 'absolute',
    left: '0%',
    top: 'auto',
    right: '0%',
    bottom: '0%',
    width: '100%',
    height: '13.4%',
    backgroundColor: '#fff',
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
  },
  slideNumber: {
    position: 'absolute',
    left: 'auto',
    top: 'auto',
    right: '2.3%',
    bottom: '0%',
    display: 'flex',
    width: '4%',
    height: '11%',
    paddingTop: '1.2%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme?.accent1Color : theme.palette.slide.accent1),
  },
  slideNumberRound: {
    bottom: '4%',
    width: '4%',
    height: 'auto',
    paddingTop: '0%',
    alignItems: 'center',
    borderRadius: '50%',
  },
  slideNumberEdge: {
    position: 'absolute',
    left: 'auto',
    top: 'auto',
    right: '2.3%',
    bottom: '0%',
    paddingTop: '0%',
    width: '6.2%',
    height: '11%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    textAlign: 'center',
  },
  number: {
    marginTop: '1px',
    marginBottom: '-1px',
    color: '#fff',
    fontSize: '1.4em',
    lineHeight: '100%',
  },
  numberRound: {
    marginTop: '0',
    position: 'absolute',
    fontSize: '1.2em',
  },
  numberEdge: {
    marginTop: '0',
    position: 'absolute',
    bottom: '57%',
    paddingLeft: '2px',
  },
  edgeSlideNumberShape: {
    width: '100%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme?.accent1Color : theme.palette.slide.accent1),
  },
  svgDark: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
  },
  opacitySeventy: {
    opacity: 0.7,
  },
  opacitySeventyFive: {
    opacity: 0.75,
  },
  opacityEighty: {
    opacity: 0.8,
  },
  opacityEightyFive: {
    opacity: 0.85,
  },
  svgAccentOne: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
  },
  svgWhite: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    color: '#fff',
  },
  makeSquare: {
    width: '100%',
  },
});
