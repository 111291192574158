import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  previewTitle: {
    '& span': {
      lineHeight: '120%',
    },
  },
  previewInfoWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3.5),
  },
  previewInfoTitle: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(0.5),
  },
  previewInfoNote: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
  },
  previewButton: {
    marginTop: theme.spacing(2),
  },
}));
