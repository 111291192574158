import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CardMedia } from '@material-ui/core';
import { CreatorActionArea, CreatorTitle } from 'components';
import { useStyles } from './CreatorLayout.styles';
import {
  BlockLayout,
  BlockLayoutSelected,
  RoundLayout,
  RoundLayoutSelected,
  EdgeLayout,
  EdgeLayoutSelected,
  FlowLayout,
  FlowLayoutSelected,
} from 'assets/images';
import { usePresentationManagement } from 'modules/presentation/hooks';
import type { CreatorRouteProps } from 'views/Creator/types';
import { useConfigurationManagement } from 'modules/configuration/hooks';
import { LayoutType } from 'modules/configuration/types';

const layoutTypeMap = {
  [LayoutType.Block]: BlockLayout,
  [LayoutType.Round]: RoundLayout,
  [LayoutType.Edge]: EdgeLayout,
  [LayoutType.Flow]: FlowLayout,
};

const selectedLayoutTypeMap = {
  [LayoutType.Block]: BlockLayoutSelected,
  [LayoutType.Round]: RoundLayoutSelected,
  [LayoutType.Edge]: EdgeLayoutSelected,
  [LayoutType.Flow]: FlowLayoutSelected,
};

interface CreatorLayoutProps extends CreatorRouteProps {}

export const CreatorLayout: React.FC<CreatorLayoutProps> = ({ match }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { presentationId } = match.params;
  const { savePresentationLayout, presentationLayoutId } = usePresentationManagement();
  const { presentationLayouts } = useConfigurationManagement();

  return (
    <CreatorActionArea>
      <CreatorTitle className={styles.title} title={t('creatorLayout.title')} />
      <Box className={styles.cardsContainer}>
        {presentationLayouts.map(({ id, type }) => (
          <Box key={id} className={styles.cardWrapper}>
            {presentationLayoutId === id ? (
              <CardMedia
                className={styles.card}
                component="img"
                src={selectedLayoutTypeMap[type]}
                onClick={() => savePresentationLayout(id, presentationId!)}
              />
            ) : (
              <CardMedia
                className={styles.card}
                component="img"
                src={layoutTypeMap[type]}
                onClick={() => savePresentationLayout(id, presentationId!)}
              />
            )}
          </Box>
        ))}
      </Box>
    </CreatorActionArea>
  );
};
