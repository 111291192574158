import cn from 'classnames';
import { useStyles } from './SlideSecond.styles';
import { useRootStyles } from '../../common.styles';
import { SlideProps } from '../../types';

export const SlideSecond: React.FC<SlideProps> = ({ colorTheme, logoUrl, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[1].image;
  const backupImageSource: string = photos?.items[1].backupImage!;

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img
          src={imageSource ? imageSource : backupImageSource}
          loading="lazy"
          alt=""
          className={rootStyles.fullBackgroundImage}
        />
        {/* svg 1 */}
        <div className={cn(rootStyles.svgDark, rootStyles.opacityEightyFive)}>
          <svg className={styles.svgDarkSecond} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
            <polygon points="0,0 0,900 737,900 932.6,0 "></polygon>
          </svg>
        </div>
        {/* svg 2 */}
        <div className={cn(rootStyles.svgAccentOne, rootStyles.opacityEighty)}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1600 900">
            <polygon
              fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              points="932.6,0 737,900 790.9,900 986.4,0 "
            ></polygon>
          </svg>
        </div>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerSvg)}>
            <div className={rootStyles.svgWhite}>
              <svg className={styles.svgWhiteSecond} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
                <polygon fill="#fff" points="1059.4,693.6 1014.6,900 1455,900 1499.9,693.6 "></polygon>
              </svg>
            </div>
            <div className={styles.logoBoxEdgeTitleTwo}>
              <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
            </div>
          </div>
        )}
        <div className={styles.accentLineEdge} />
        <h1 className={styles.titleTwoEdge}>Image Title Slide</h1>
        <h2 className={styles.subtitleTwoEdge}>Presenter Name / Date</h2>
      </div>
    </>
  );
};

export default SlideSecond;
