import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const InfoIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(function InfoIcon(props, ref) {
  return (
    <SvgIcon ref={ref} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(-2 -2)">
        <path fill="#c9c9ce" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" transform="translate(2 2)" />
        <path
          fill="#fff"
          d="M12,10.8A1.216,1.216,0,0,0,10.8,12v4.7A1.216,1.216,0,0,0,12,17.9a1.155,1.155,0,0,0,1.2-1.2V12A1.29,1.29,0,0,0,12,10.8Z"
        />
        <path fill="#fff" d="M12,8.5a1.216,1.216,0,0,0,1.2-1.2A1.155,1.155,0,0,0,12,6.1h0a1.2,1.2,0,0,0,0,2.4Z" />
      </g>
    </SvgIcon>
  );
});
