import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, ButtonBase } from '@material-ui/core';
import { Loader } from 'components';
import PowerPointLogo from 'assets/images/Microsoft-Office-PowerPoint.png';
import { ArrowDown } from 'assets/icons';
import { usePresentationManagement } from 'modules/presentation/hooks';
import { isStatusPending } from 'modules/store/status';
import { useStyles } from './DownloadOptions.styles';

interface DownloadOptionsProps {
  presentationId: string;
}

export const DownloadOptions: React.FC<DownloadOptionsProps> = ({ presentationId }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const {
    presentationDownloadURL,
    presentationDownloadURLStatus,
    generatePresentationDownloadLink,
    resetPresentationDownloadLink,
  } = usePresentationManagement();

  useEffect(() => {
    if (presentationDownloadURL) {
      resetPresentationDownloadLink();
    }
  }, [presentationDownloadURL, resetPresentationDownloadLink]);

  const handleDownloadClick = (): void => {
    generatePresentationDownloadLink(presentationId);
  };

  return (
    <Loader loading={isStatusPending(presentationDownloadURLStatus)}>
      <Typography className={styles.subtitle}>{t('creatorDownload.powerPointNote')}</Typography>

      <ButtonBase className={styles.downloadOptionWrapper} onClick={handleDownloadClick}>
        <div className={styles.downloadOptionImageWrapper}>
          <img width="69" height="64" src={PowerPointLogo} alt="Microsoft Office PowerPoint logotype" />
          <Typography className={styles.downloadOptionName}>{t('creatorDownload.powerPointOption')}</Typography>
        </div>

        <div className={styles.downloadOptionActionWrapper}>
          <div className={styles.downloadOptionActionTextWrapper}>
            <Typography className={styles.downloadOptionActionText}>{t('creatorDownload.download')}</Typography>
          </div>
          <div className={styles.downloadOptionActionIconWrapper}>
            <div className={styles.downloadOptionActionOverlay} />
            <ArrowDown className={styles.downloadOptionActionIcon} />
          </div>
        </div>
      </ButtonBase>
    </Loader>
  );
};
