import cn from 'classnames';
import { useStyles } from './SlideFifth.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';
import SquareIcon from '../assets/icons/square-icon.svg';
import SlideNumber from '../components/SlideNumber';

export const SlideFifth: React.FC<SlideProps> = ({ colorTheme, photoPack, fontPair, logoUrl }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSourceFirstRowOne: string | null = photos?.items[9].image;
  const backupSourceFirstRowOne: string = photos?.items[9].backupImage!;
  const imageSourceFirstRowTwo: string | null = photos?.items[3].image;
  const backupSourceFirstRowTwo: string = photos?.items[3].backupImage!;
  const imageSourceSecondRowOne: string | null = photos?.items[10].image;
  const backupSourceSecondRowOne: string = photos?.items[10].backupImage!;
  const imageSourceSecondRowTwo: string | null = photos?.items[11].image;
  const backupSourceSecondRowTwo: string = photos?.items[11].backupImage!;

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <div className={styles.galleryContainer}>
          <div className={styles.galleryRightContainer}>
            <div className={styles.galleryRowRound}>
              <img
                src={imageSourceFirstRowOne ? imageSourceFirstRowOne : backupSourceFirstRowOne}
                loading="lazy"
                alt=""
                className={cn(styles.galleryOneRound, styles.galleryOneRoundCorner)}
              />
              <img
                src={imageSourceFirstRowTwo ? imageSourceFirstRowTwo : backupSourceFirstRowTwo}
                loading="lazy"
                alt=""
                className={cn(styles.galleryTwoRound, styles.galleryTwoRoundCorner)}
              />
            </div>
            <div className={styles.galleryRowTwoRound}>
              <img
                src={imageSourceSecondRowOne ? imageSourceSecondRowOne : backupSourceSecondRowOne}
                loading="lazy"
                alt=""
                className={styles.galleryOneRound}
              />
              <img
                src={imageSourceSecondRowTwo ? imageSourceSecondRowTwo : backupSourceSecondRowTwo}
                loading="lazy"
                alt=""
                className={styles.galleryTwoRound}
              />
            </div>
          </div>
          <div className={styles.galleryCircle}>
            <div className={styles.galleryInnerCircle}>
              <h1 className={styles.galleryTitleRound}>Image Gallery with Big Statement</h1>
            </div>
            <img src={SquareIcon} loading="lazy" alt="" className={rootStyles.makeSquare} />
          </div>
        </div>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
        <SlideNumber slide="5" colorTheme={colorTheme} fontPair={fontPair} />
      </div>
    </>
  );
};

export default SlideFifth;
