import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginBottom: theme.spacing(4.75),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    minWidth: 78,
    minHeight: 78,
    padding: theme.spacing(2.5),
    marginRight: theme.spacing(2.75),
    borderRadius: '15px',
    backgroundColor: theme.palette.primary.main,

    color: theme.palette.common.white,

    '& svg': {
      width: 36,
      height: 36,
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#727272',
  },
  title: {
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.primary.main,
  },
}));
