import firebase from 'firebase/app';
import { from, of, forkJoin, EMPTY } from 'rxjs';
import { switchMap, map, mergeMap, catchError, takeUntil, withLatestFrom, filter, tap, pairwise } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { fromDocRef, collectionChanges } from 'rxfire/firestore';
import { getDownloadURL } from 'rxfire/storage';
import { push, replace } from 'connected-react-router';
import { routes } from 'const';
import { ofType } from 'modules/store/of-type';
import { Action } from 'modules/store/action';
import { downloadFile } from 'modules/presentation/utils';
import { SharedAction } from 'modules/shared/actions';
import type { RootState } from 'services/store/root-reducer';
import { apiClient } from 'services/api/api';
import { i18nClient } from 'services/translations';
import { firebaseFirestore, firebaseStorage } from 'services/firebase';
import { appLocalStorage } from 'services/storage/local-storage';
import { createPresentationErrorMessage } from './utils';
import { presentationConverter } from './converters';
import { PresentationDto } from './types';
import { PresentationAction, PresentationActionType } from './actions';
import { LayoutType } from 'modules/configuration/types';

const createInitialPresentationDocRef = (
  userId: string,
  layoutId: string
): Promise<firebase.firestore.DocumentReference> => {
  return firebaseFirestore
    .collection('users')
    .doc(userId)
    .collection('presentations')
    .add({
      createdAt: firebase.firestore.Timestamp.now(),
      modifiedAt: firebase.firestore.Timestamp.now(),
      layout: firebaseFirestore.doc(`admin/config/layouts/${layoutId}`),
    });
};

const createPresentationDocRef = (
  userId: string,
  presentationId: string
): firebase.firestore.DocumentReference<PresentationDto> => {
  return firebaseFirestore
    .collection('users')
    .doc(userId)
    .collection('presentations')
    .doc(presentationId)
    .withConverter(presentationConverter);
};

const createPreviewCollectionRef = (userId: string, presentationId: string): firebase.firestore.CollectionReference => {
  return firebaseFirestore
    .collection('users')
    .doc(userId)
    .collection('presentations')
    .doc(presentationId)
    .collection('images');
};

const initializePresentationEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(PresentationActionType.InitializePresentation),
    withLatestFrom(state$),
    switchMap(
      ([
        ,
        {
          auth: { user },
          configuration: { presentationLayouts },
        },
      ]) => {
        const blockLayout = presentationLayouts.data.find(layout => layout.type === LayoutType.Block);

        return from(createInitialPresentationDocRef(user.data!.uid, blockLayout?.id!)).pipe(
          mergeMap(presentationDocRef => {
            return of(
              PresentationAction.savePresentationLayout(blockLayout?.id, presentationDocRef.id),

              push(routes.creator.layout.link(presentationDocRef.id))
            );
          }),
          catchError(() =>
            of(replace(routes.dashboard.path), SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error'))
          )
        );
      }
    )
  );

const startPresentationObserverEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(PresentationActionType.StartPresentationObserver),
    withLatestFrom(state$),
    switchMap(([{ payload: { presentationId } }, { auth: { user } }]) =>
      fromDocRef<PresentationDto>(createPresentationDocRef(user.data!.uid, presentationId)).pipe(
        takeUntil(actions$.pipe(ofType(PresentationActionType.StopPresentationObserver))),
        map(documentSnapshot => {
          if (documentSnapshot.exists) {
            return PresentationAction.presentationObserverEmission(documentSnapshot.data()!);
          }

          throw new Error('Document doesnt exists');
        }),
        catchError(() =>
          of(
            replace(routes.dashboard.path),
            PresentationAction.startPresentationObserverFailure(),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const presentationObserverEmissionErrorEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(PresentationActionType.PresentationObserverEmission),
    pairwise(),
    mergeMap(([previous, current]) => {
      const previousPresentationError = previous.payload.presentationData.error;
      const currentPresentationError = current.payload.presentationData.error;

      if (!previousPresentationError && currentPresentationError) {
        return of(
          PresentationAction.previewObserverEmissionFailure(),
          SharedAction.notifyUser(createPresentationErrorMessage(current.payload.presentationData.error!), 'error')
        );
      }

      if (previousPresentationError && currentPresentationError) {
        return of(
          PresentationAction.previewObserverEmissionFailure(),
          SharedAction.notifyUser(createPresentationErrorMessage(current.payload.presentationData.error!), 'error')
        );
      }

      return EMPTY;
    })
  );

const presentationObserverEmissionDownloadURLEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(PresentationActionType.PresentationObserverEmission),
    withLatestFrom(state$),
    filter(([, { presentation }]) => !presentation.downloadURL.data),
    filter(([{ payload }]) => !!payload.presentationData.generationDetails?.fileUrl),
    switchMap(([{ payload }, { presentation }]) =>
      getDownloadURL(firebaseStorage.refFromURL(payload.presentationData.generationDetails?.fileUrl!)).pipe(
        tap(downloadURL => {
          if (presentation.downloadURL.downloadQueued) {
            downloadFile(downloadURL);
          }
        }),
        mergeMap(downloadURL => {
          const isConsultDialogDismissed = appLocalStorage.get<boolean>('consult-dialog-dismissed');
          const isDownloadQueued = presentation.downloadURL.downloadQueued;

          if (!isConsultDialogDismissed && isDownloadQueued) {
            return of(PresentationAction.setPresentationDownloadURL(downloadURL), SharedAction.showConsultDialog());
          }

          return of(PresentationAction.setPresentationDownloadURL(downloadURL));
        }),
        catchError(() => of(SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')))
      )
    )
  );

const startPreviewObserverEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(PresentationActionType.StartPreviewObserver),
    withLatestFrom(state$),
    switchMap(([{ payload: { presentationId } }, { auth: { user } }]) =>
      collectionChanges(createPreviewCollectionRef(user.data!.uid, presentationId), ['added']).pipe(
        takeUntil(actions$.pipe(ofType(PresentationActionType.StopPreviewObserver))),
        mergeMap(collectionChanges => {
          const previewImages$ = collectionChanges.map(documentChange =>
            getDownloadURL(firebaseStorage.refFromURL(documentChange.doc.data().url)).pipe(
              map(imageURL => ({ source: imageURL, index: documentChange.doc.data().index }))
            )
          );

          return forkJoin(previewImages$).pipe(
            map(previewImages => PresentationAction.previewObserverEmission(previewImages)),
            catchError(() =>
              of(
                PresentationAction.previewObserverEmissionFailure(),
                SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
              )
            )
          );
        }, 4),
        catchError(() =>
          of(
            PresentationAction.startPreviewObserverFailure(),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const savePresentationLayoutEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(PresentationActionType.SavePresentationLayout),
    switchMap(({ payload }) =>
      from(apiClient.put(`presentations/${payload.presentationId}/layout?layoutId=${payload.layoutId}`)).pipe(
        map(() => PresentationAction.savePresentationLayoutSuccess()),
        catchError(() =>
          of(
            PresentationAction.savePresentationLayoutFailure(),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const savePresentationLogoEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(PresentationActionType.SavePresentationLogo),
    switchMap(({ payload }) =>
      from(
        apiClient.put(
          payload.logo
            ? `presentations/${payload.presentationId}/logo?logoName=${payload.logo}`
            : `presentations/${payload.presentationId}/logo`
        )
      ).pipe(
        map(() => PresentationAction.savePresentationLogoSuccess()),
        catchError(() =>
          of(
            PresentationAction.savePresentationLogoFailure(),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const savePresentationColorsEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(PresentationActionType.SavePresentationColors),
    switchMap(({ payload }) =>
      from(apiClient.put(`presentations/${payload.presentationId}/colors`, payload.colorTheme)).pipe(
        map(() => PresentationAction.savePresentationColorsSuccess()),
        catchError(() =>
          of(
            PresentationAction.savePresentationColorsFailure(),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const savePresentationFontThemeEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(PresentationActionType.SavePresentationFontTheme),
    switchMap(({ payload }) =>
      from(apiClient.put(`presentations/${payload.presentationId}/fonts?fontPairId=${payload.fontThemeId}`)).pipe(
        map(() => PresentationAction.savePresentationFontThemeSuccess()),
        catchError(() =>
          of(
            PresentationAction.savePresentationFontThemeFailure(),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const savePresentationPhotoPackEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(PresentationActionType.SavePresentationPhotoPack),
    switchMap(({ payload }) =>
      from(apiClient.put(`presentations/${payload.presentationId}/photopack?photopackId=${payload.photoPackId}`)).pipe(
        map(() => PresentationAction.savePresentationPhotoPackSuccess()),
        catchError(() =>
          of(
            PresentationAction.savePresentationPhotoPackFailure(),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const generatePresentationFullPreviewEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(PresentationActionType.GeneratePresentationFullPreview),
    switchMap(({ payload }) =>
      from(apiClient.put(`presentations/${payload.presentationId}/preview`)).pipe(
        map(() => PresentationAction.generatePresentationFullPreviewSuccess()),
        catchError(() =>
          of(
            PresentationAction.generatePresentationFullPreviewFailure(),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

const generatePresentationDownloadLinkEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(PresentationActionType.GeneratePresentationDownloadLink),
    switchMap(({ payload }) =>
      from(apiClient.put(`presentations/${payload.presentationId}/generate`)).pipe(
        map(() => PresentationAction.generatePresentationDownloadLinkSuccess()),
        catchError(() =>
          of(
            PresentationAction.generatePresentationDownloadLinkFailure(),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

export const presentationEpics = combineEpics(
  initializePresentationEpic,
  startPresentationObserverEpic,
  presentationObserverEmissionErrorEpic,
  presentationObserverEmissionDownloadURLEpic,
  startPreviewObserverEpic,
  savePresentationLayoutEpic,
  savePresentationLogoEpic,
  savePresentationColorsEpic,
  savePresentationFontThemeEpic,
  savePresentationPhotoPackEpic,
  generatePresentationFullPreviewEpic,
  generatePresentationDownloadLinkEpic
);
