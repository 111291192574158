import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3.75),
    borderRadius: theme.shape.borderRadius * 5,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    fontSize: theme.typography.pxToRem(26),
    fontWeight: 600,
    color: theme.palette.utility.black1,
    marginBottom: theme.spacing(1.375),
  },
  heading: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(44),
    fontWeight: 800,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(7.5),
  },
  closeIcon: {
    alignSelf: 'flex-end',
    color: '#D7D7DD',

    '& svg': {
      width: 30,
      height: 30,
    },
  },

  innerContainer: {
    width: '65%',
  },
  perksContainer: {
    marginBottom: theme.spacing(9),
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
  },
  dismissText: {
    marginTop: theme.spacing(2),
    color: '#727272',
    textDecoration: 'underline',
    textAlign: 'center',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'none',
    },
  },
}));
