import ReactDOM from 'react-dom';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { AuthObserver, ProtectedRoute, AppPanel, NotificationOutlet } from 'components';
import { routes } from 'const';
import { createStore } from 'services/store/create-store';
import { LoginContainer, RegisterContainer, PasswordResetContainer, AppContainer } from './views';
import { theme } from './theme';
import reportWebVitals from './reportWebVitals';

const history = createBrowserHistory();
const store = createStore(history);

ReactDOM.render(
  <ReduxStoreProvider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <AuthObserver>
          <GTMProvider state={{ id: process.env.REACT_APP_GTM_ID || '' }}>
            <AppPanel />
            <NotificationOutlet />
            <Switch>
              <Route exact path={routes.login.path} component={LoginContainer} />
              <Route exact path={routes.register.path} component={RegisterContainer} />
              <Route exact path={routes.password.path} component={PasswordResetContainer} />
              <ProtectedRoute path={routes.root.path} component={AppContainer} />
            </Switch>
          </GTMProvider>
        </AuthObserver>
        <CssBaseline />
      </ThemeProvider>
    </ConnectedRouter>
  </ReduxStoreProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
