import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabPanel from '@material-ui/lab/TabPanel';
import { CreatorActionArea, CreatorTitle, CreatorActionTabs, Loader, ColorCustomize } from 'components';
import { useConfigurationManagement } from 'modules/configuration/hooks';
import { usePresentationManagement } from 'modules/presentation/hooks';
import type { CreatorRouteProps } from 'views/Creator/types';
import { ColorSchemes } from './components/ColorSchemes';
import { useStyles } from './CreatorColor.styles';

interface CreatorColorProps extends CreatorRouteProps {}

export const CreatorColor: React.FC<CreatorColorProps> = ({ match }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { curatedColorSchemes, userColorSchemes, isSchemeLoading } = useConfigurationManagement();
  const { presentationColorsId } = usePresentationManagement();
  const { presentationId } = match.params;

  const tabs = [
    t('creatorColorScheme.curatedSchemesTab'),
    t('creatorColorScheme.customizeTab'),
    t('creatorColorScheme.savedTab'),
  ];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const currentSelectedColorScheme = [...curatedColorSchemes, ...userColorSchemes].find(
    colorScheme => colorScheme.internalId === presentationColorsId
  );

  return (
    <CreatorActionArea>
      <CreatorTitle title={t('creatorColorScheme.title')} />
      <CreatorActionTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
        TabsProps={{ className: styles.actionTabs }}
      >
        <Loader loading={isSchemeLoading}>
          <TabPanel className={styles.colorAreaContainer} classes={{ root: styles.tabPanelRoot }} value={tabs[0]}>
            <ColorSchemes mode="curated" colors={curatedColorSchemes} presentationId={presentationId!} />
          </TabPanel>

          <TabPanel className={styles.colorAreaContainer} classes={{ root: styles.tabPanelRoot }} value={tabs[1]}>
            <ColorCustomize
              colors={curatedColorSchemes}
              currentColorScheme={currentSelectedColorScheme}
              setActiveTab={setActiveTab}
              tabs={tabs}
              presentationId={presentationId!}
            />
          </TabPanel>

          <TabPanel className={styles.colorAreaContainer} classes={{ root: styles.tabPanelRoot }} value={tabs[2]}>
            <ColorSchemes mode="user" colors={userColorSchemes} presentationId={presentationId!} />
          </TabPanel>
        </Loader>
      </CreatorActionTabs>
    </CreatorActionArea>
  );
};
