import cn from 'classnames';
import { useStyles } from './SlideSeventh.styles';
import { SlideProps } from '../../types';
import { MagIcon, PeopleIcon, PuzzleIcon, SpeachBubbleIcon, SupportIcon, ToolsIcon } from './icons';
import { useRootStyles } from '../../common.styles';

export const SlideSeventh: React.FC<SlideProps> = ({ logoUrl, colorTheme, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  return (
    <div className={cn(styles.slideCanvas, rootStyles.root)}>
      <h1 className={styles.slideTitle}>Six Icons</h1>
      <div className={styles.iconsRowOne}>
        <div className={styles.iconBlock}>
          <div className={cn(styles.iconContainer, styles.iconContainerTop)}>
            <div className={cn(styles.edgeIconBox, styles.one)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              >
                <polygon points="0,0 0,79 21,100 100,100 100,21 79,0" />
              </svg>
            </div>
            <img src={SpeachBubbleIcon} loading="lazy" alt="speachbubbleicon" className={styles.image} />
          </div>
          <div className={styles.iconLabel}>
            <h1 className={cn(styles.labelTitle, styles.labelTitleIcon)}>Icon Title</h1>
            <p className={cn(styles.labelText, styles.labelTextIcon)}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.iconBlock}>
          <div className={cn(styles.iconContainer, styles.iconContainerTop)}>
            <div className={cn(styles.edgeIconBox, styles.two)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                fill={colorTheme ? colorTheme?.accent2Color : 'CurrentColor'}
              >
                <polygon points="0,0 0,79 21,100 100,100 100,21 79,0" />
              </svg>
            </div>
            <img src={PeopleIcon} loading="lazy" alt="peopleicon" className={styles.image} />
          </div>
          <div className={styles.iconLabel}>
            <h1 className={cn(styles.labelTitle, styles.labelTitleIcon)}>Icon Title</h1>
            <p className={cn(styles.labelText, styles.labelTextIcon)}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.iconBlock}>
          <div className={cn(styles.iconContainer, styles.iconContainerTop)}>
            <div className={cn(styles.edgeIconBox, styles.three)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                fill={colorTheme ? colorTheme?.accent3Color : 'CurrentColor'}
              >
                <polygon points="0,0 0,79 21,100 100,100 100,21 79,0" />
              </svg>
            </div>
            <img src={ToolsIcon} loading="lazy" alt="toolsicon" className={styles.image} />
          </div>
          <div className={styles.iconLabel}>
            <h1 className={cn(styles.labelTitle, styles.labelTitleIcon)}>Icon Title</h1>
            <p className={cn(styles.labelText, styles.labelTextIcon)}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.iconsRowTwo}>
        <div className={styles.iconBlock}>
          <div className={cn(styles.iconContainer, styles.iconContainerFour)}>
            <div className={cn(styles.edgeIconBox, styles.four)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                fill={colorTheme ? colorTheme?.accent4Color : 'CurrentColor'}
              >
                <polygon points="0,0 0,79 21,100 100,100 100,21 79,0" />
              </svg>
            </div>
            <img src={MagIcon} loading="lazy" alt="magicon" className={styles.image} />
          </div>
          <div className={styles.iconLabel}>
            <h1 className={cn(styles.labelTitle, styles.labelTitleIcon)}>Icon Title</h1>
            <p className={cn(styles.labelText, styles.labelTextIcon)}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.iconBlock}>
          <div className={cn(styles.iconContainer, styles.iconContainerFive)}>
            <div className={cn(styles.edgeIconBox, styles.five)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                fill={colorTheme ? colorTheme?.accent5Color : 'CurrentColor'}
              >
                <polygon points="0,0 0,79 21,100 100,100 100,21 79,0" />
              </svg>
            </div>
            <img src={SupportIcon} loading="lazy" alt="supporticon" className={styles.image} />
          </div>
          <div className={styles.iconLabel}>
            <h1 className={cn(styles.labelTitle, styles.labelTitleIcon)}>Icon Title</h1>
            <p className={cn(styles.labelText, styles.labelTextIcon)}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
        <div className={styles.iconBlock}>
          <div className={cn(styles.iconContainer, styles.iconContainerSix)}>
            <div className={cn(styles.edgeIconBox, styles.six)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                fill={colorTheme ? colorTheme?.accent6Color : 'CurrentColor'}
              >
                <polygon points="0,0 0,79 21,100 100,100 100,21 79,0" />
              </svg>
            </div>
            <img src={PuzzleIcon} loading="lazy" alt="puzzleicon" className={styles.image} />
          </div>
          <div className={styles.iconLabel}>
            <h1 className={cn(styles.labelTitle, styles.labelTitleIcon)}>Icon Title</h1>
            <p className={cn(styles.labelText, styles.labelTextIcon)}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
            </p>
          </div>
        </div>
      </div>
      <div className={rootStyles.slideNumberEdge}>
        <div className={rootStyles.edgeSlideNumberShape}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 104" display="block">
            <polygon
              fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              points="22.5,0 0,104 82.5,104 105,0 "
            ></polygon>
          </svg>
        </div>
        <h1 className={cn(rootStyles.number, rootStyles.numberEdge)}>7</h1>
      </div>
      {logoUrl && (
        <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
          <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
        </div>
      )}
    </div>
  );
};

export default SlideSeventh;
