import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },

  toolbarTooltipText: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  toolbarNavLink: {
    position: 'relative',
    zIndex: theme.zIndex.drawer,
    display: 'block',
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  toolbarItem: {
    height: 45,
    justifyContent: 'center',
    willChange: 'transform',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard,
    }),

    '&:hover': {
      transform: 'scale(1.30)',
    },
  },
  toolbarItemActive: {
    opacity: 1,
    '& svg': {
      fill: theme.palette.primary.main,
    },
    pointerEvents: 'none',
  },
  toolbarItemIcon: {
    fontSize: theme.typography.pxToRem(20),
    fill: theme.palette.common.white,
  },
}));
