import type { Action } from 'modules/store/action';
import { SharedActionType } from './actions';
import type { SharedState } from './state';

export const sharedState: SharedState = {
  notificationQueue: [],
  showConsultDialog: false,
};

export function sharedReducer(state = sharedState, action: Action): SharedState {
  switch (action.type) {
    /**
     * NotifyUser
     */
    case SharedActionType.NotifyUser:
      return {
        ...state,
        notificationQueue: [...state.notificationQueue, action.payload],
      };

    /**
     * NotifyUserSuccess
     */
    case SharedActionType.NotifyUserSuccess:
      return {
        ...state,
        notificationQueue: state.notificationQueue.slice(1),
      };

    /**
     * ShowConsultDialog
     */
    case SharedActionType.ShowConsultDialog:
      return {
        ...state,
        showConsultDialog: true,
      };

    /**
     * HideConsultDialog
     */
    case SharedActionType.HideConsultDialog:
      return {
        ...state,
        showConsultDialog: false,
      };

    default:
      return state;
  }
}
