type StaticRoute = {
  path: string;
};

type DynamicRoute = StaticRoute & {
  link: (param: string) => string;
};

type MaybeDynamicRoute = StaticRoute & {
  link: (param?: string) => string;
};

type RoutesConfig = {
  root: StaticRoute;
  login: StaticRoute;
  register: StaticRoute;
  password: StaticRoute;
  dashboard: StaticRoute;
  account: StaticRoute;
  creator: {
    base: MaybeDynamicRoute;
    layout: DynamicRoute;
    logo: DynamicRoute;
    color: DynamicRoute;
    font: DynamicRoute;
    photo: DynamicRoute;
    panel: DynamicRoute;
    preview: DynamicRoute;
    download: DynamicRoute;
  };
};

export const routes: RoutesConfig = {
  root: {
    path: '/',
  },
  login: {
    path: '/login',
  },
  register: {
    path: '/register',
  },
  password: {
    path: '/password-reset',
  },
  dashboard: {
    path: '/dashboard',
  },
  account: {
    path: '/account',
  },
  creator: {
    base: {
      path: '/creator/:presentationId?',
      link: (presentationId?: string) => (presentationId ? `/creator/${presentationId}` : '/creator'),
    },
    layout: {
      path: '/creator/:presentationId?/layout',
      link: (presentationId: string) => `/creator/${presentationId}/layout`,
    },
    logo: {
      path: '/creator/:presentationId?/logo',
      link: (presentationId: string) => `/creator/${presentationId}/logo`,
    },
    color: {
      path: '/creator/:presentationId?/color',
      link: (presentationId: string) => `/creator/${presentationId}/color`,
    },
    font: {
      path: '/creator/:presentationId?/font',
      link: (presentationId: string) => `/creator/${presentationId}/font`,
    },
    photo: {
      path: '/creator/:presentationId?/photo',
      link: (presentationId: string) => `/creator/${presentationId}/photo`,
    },
    panel: {
      path: '/creator/:presentationId?/panel',
      link: (presentationId: string) => `/creator/${presentationId}/panel`,
    },
    preview: {
      path: '/creator/:presentationId?/preview',
      link: (presentationId: string) => `/creator/${presentationId}/preview`,
    },
    download: {
      path: '/creator/:presentationId?/download',
      link: (presentationId: string) => `/creator/${presentationId}/download`,
    },
  },
};
