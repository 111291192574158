import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Fade } from '@material-ui/core';
import { TabPanel } from '@material-ui/lab';
import { useConfigurationManagement } from 'modules/configuration/hooks';
import { usePresentationManagement } from 'modules/presentation/hooks';
import { CreatorActionArea, CreatorTitle, CreatorActionTabs, Message } from 'components';
import type { CreatorRouteProps } from 'views/Creator/types';
import { CreatorFontItem } from './components';
import { useStyles } from './CreatorFontScheme.styles';
import { isStatusPending } from 'modules/store/status';

interface CreatorFontSchemeProps extends CreatorRouteProps {}

export const CreatorFontScheme: React.FC<CreatorFontSchemeProps> = ({ match }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { nonSystemFonts, systemFonts } = useConfigurationManagement();
  const {
    presentationFontThemeId,
    savePresentationFontTheme,
    presentationFontSchemeStatus,
  } = usePresentationManagement();

  const { presentationId } = match.params;
  const tabs = [t('creatorFontScheme.nonSystemTab'), t('creatorFontScheme.systemTab')];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleSavePresentationFontTheme = (fontThemeId: string) => {
    if (!isStatusPending(presentationFontSchemeStatus)) {
      savePresentationFontTheme(fontThemeId, presentationId!);
    }
  };

  return (
    <CreatorActionArea>
      <CreatorTitle title={t('creatorFontScheme.title')} />

      <CreatorActionTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
        TabsProps={{ className: styles.actionTabs }}
      >
        <TabPanel classes={{ root: styles.tabPanelRoot }} value={tabs[0]}>
          <Fade in>
            <div>
              <div className={styles.nonSchemeInfoTitle}>{t('creatorFontScheme.nonSystemInfoTitle')}</div>
              <div className={styles.nonSchemeInfo}>{t('creatorFontScheme.nonSystemInfo')}</div>
              <div className={styles.fontListContainer}>
                {nonSystemFonts.length ? (
                  nonSystemFonts.map(({ id, majorFont, minorFont, order }) => (
                    <CreatorFontItem
                      key={id}
                      majorFont={majorFont}
                      minorFont={minorFont}
                      isActive={presentationFontThemeId ? id === presentationFontThemeId : order === 0}
                      onClick={() => handleSavePresentationFontTheme(id)}
                      isLoading={isStatusPending(presentationFontSchemeStatus)}
                    />
                  ))
                ) : (
                  <Message text={t('creatorFontScheme.emptyFontPairsMessage')} />
                )}
              </div>
            </div>
          </Fade>
        </TabPanel>

        <TabPanel classes={{ root: styles.tabPanelRoot }} value={tabs[1]}>
          <Fade in>
            <div className={styles.fontListContainer}>
              {systemFonts.length ? (
                systemFonts.map(({ id, majorFont, minorFont }) => (
                  <CreatorFontItem
                    key={id}
                    majorFont={majorFont}
                    minorFont={minorFont}
                    isActive={id === presentationFontThemeId}
                    onClick={() => handleSavePresentationFontTheme(id)}
                    isLoading={isStatusPending(presentationFontSchemeStatus)}
                  />
                ))
              ) : (
                <Message text={t('creatorFontScheme.emptyFontPairsMessage')} />
              )}
            </div>
          </Fade>
        </TabPanel>
      </CreatorActionTabs>
    </CreatorActionArea>
  );
};
