import React from 'react';
import type { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Container, Fade, Typography, TextField } from '@material-ui/core';
import { Button } from 'components';
import { routes } from 'const';
import { useAuthManagement } from 'modules/auth/hooks';
import { i18nClient } from 'services/translations';
import { useStyles } from './PasswordResetContainer.styles';

const registerFormSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .trim()
    .required()
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
      'string.email': i18nClient.t('auth.emailFormatErrorMessage'),
    }),
}).required();

interface PasswordResetFormData {
  email: string;
}

interface PasswordResetContainerProps extends RouteComponentProps {}

export const PasswordResetContainer: React.FC<PasswordResetContainerProps> = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { isLoading, resetUserPassword } = useAuthManagement();
  const { register, errors, handleSubmit } = useForm<PasswordResetFormData>({
    mode: 'onTouched',
    resolver: joiResolver(registerFormSchema),
  });

  const emailErrorMessage = errors.email?.message;

  return (
    <Container component="main" maxWidth="sm">
      <Fade in>
        <div className={styles.container}>
          <Typography className={styles.resetTitle} component="h1" variant="h5">
            {t('reset.resetTitle')}
          </Typography>

          <form noValidate onSubmit={handleSubmit(({ email }) => resetUserPassword(email))}>
            <TextField
              inputRef={register}
              name="email"
              variant="outlined"
              margin="normal"
              label={t('auth.emailLabel')}
              error={Boolean(errors.email)}
              helperText={emailErrorMessage}
              autoComplete="email"
              required
              fullWidth
            />

            <Button
              className={styles.submitButton}
              textClassName={styles.submitButtonText}
              loading={isLoading}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth
            >
              {t('reset.resetPassword')}
            </Button>
          </form>

          <Link className={styles.redirectBack} to={routes.login.path}>
            {t('reset.redirectBack')}
          </Link>
        </div>
      </Fade>
    </Container>
  );
};
