import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isStatusFailure, isStatusPending, isStatusSuccess } from 'modules/store/status';
import { usePrevious } from 'hooks';
import { ConfigurationAction } from './actions';
import {
  selectUserPresentations,
  selectDeleteUserPresentationStatus,
  selectCuratedColorSchemes,
  selectUserColorSchemes,
  selectFontPairs,
  selectPhotoPacks,
  selectSubscriptionOptions,
  selectPresentationLayouts,
} from './selectors';
import type { UserColorScheme } from './types';

export const useConfigurationManagement = () => {
  const dispatch = useDispatch();

  const userPresentations = useSelector(selectUserPresentations);
  const deleteUserPresentationStatus = useSelector(selectDeleteUserPresentationStatus);
  const curatedColorSchemes = useSelector(selectCuratedColorSchemes);
  const userColorSchemes = useSelector(selectUserColorSchemes);
  const fontPairs = useSelector(selectFontPairs);
  const photoPacks = useSelector(selectPhotoPacks);
  const subscriptionOptions = useSelector(selectSubscriptionOptions);
  const presentationLayouts = useSelector(selectPresentationLayouts);

  const isSchemeLoading = isStatusPending(userColorSchemes.status) || isStatusPending(curatedColorSchemes.status);
  const isSchemeErrored = isStatusFailure(userColorSchemes.status) || isStatusFailure(curatedColorSchemes.status);
  const isSchemeSucceed = isStatusSuccess(userColorSchemes.status) || isStatusSuccess(curatedColorSchemes.status);
  const prevIsSchemeSucceed = isStatusSuccess(usePrevious(userColorSchemes.status));

  const fetchUserPresentations = useCallback(() => {
    dispatch(ConfigurationAction.fetchUserPresentations());
  }, [dispatch]);

  const deleteUserPresentation = useCallback(
    (presentationId: string) => {
      dispatch(ConfigurationAction.deleteUserPresentation(presentationId));
    },
    [dispatch]
  );

  const setPresentationName = useCallback(
    (presentationName: string, presentationId: string) => {
      dispatch(ConfigurationAction.setPresentationName(presentationName, presentationId));
    },
    [dispatch]
  );

  const fetchCuratedColorSchemes = useCallback(() => {
    dispatch(ConfigurationAction.fetchCuratedColorSchemes());
  }, [dispatch]);

  const fetchUserColorSchemes = useCallback(() => {
    dispatch(ConfigurationAction.fetchUserColorSchemes());
  }, [dispatch]);

  const saveUserColorScheme = useCallback(
    (color: UserColorScheme) => {
      dispatch(ConfigurationAction.saveUserColorScheme(color));
    },
    [dispatch]
  );

  const fetchFontPairs = useCallback(() => {
    dispatch(ConfigurationAction.fetchFontPairs());
  }, [dispatch]);

  const fetchPhotoPacks = useCallback(() => {
    dispatch(ConfigurationAction.fetchPhotoPacks());
  }, [dispatch]);

  const fetchSubscriptionOptions = useCallback(() => {
    dispatch(ConfigurationAction.fetchSubscriptionOptions());
  }, [dispatch]);

  const fetchPresentationLayouts = useCallback(() => {
    dispatch(ConfigurationAction.fetchPresentationLayouts());
  }, [dispatch]);

  return {
    userPresentations: userPresentations.data,
    userPresentationsStatus: userPresentations.status,
    deleteUserPresentationStatus,
    curatedColorSchemes: curatedColorSchemes.data.sort((colorA, colorB) => colorA.order - colorB.order),
    userColorSchemes: userColorSchemes.data,
    nonSystemFonts: fontPairs.data
      .filter(font => !font.isSystemPair)
      .sort((fontPairA, fontPairB) => fontPairA.order - fontPairB.order),
    systemFonts: fontPairs.data
      .filter(font => font.isSystemPair)
      .sort((fontPairA, fontPairB) => fontPairA.order - fontPairB.order),
    fonts: fontPairs.data,
    photoPacks: photoPacks.data.sort((photoPackA, photoPackB) => photoPackA.order - photoPackB.order),
    photoPacksStatus: photoPacks.status,
    pricingOptions: subscriptionOptions.data,
    pricingOptionsStatus: subscriptionOptions.status,
    presentationLayouts: presentationLayouts.data.sort((layoutA, layoutB) => layoutA.order - layoutB.order),
    presentationLayoutsStatus: presentationLayouts.status,

    isSchemeLoading,
    isSchemeErrored,
    isSchemeSucceed,
    prevIsSchemeSucceed,

    fetchUserPresentations,
    deleteUserPresentation,
    setPresentationName,
    fetchCuratedColorSchemes,
    fetchUserColorSchemes,
    saveUserColorScheme,
    fetchFontPairs,
    fetchPhotoPacks,
    fetchSubscriptionOptions,
    fetchPresentationLayouts,
  };
};
