import { makeStyles, Theme } from '@material-ui/core/styles';

interface ColorBarStyles {
  firstAccent: string;
  secondAccent: string;
  thirdAccent: string;
  fourthAccent: string;
  fifthAccent: string;
  sixthAccent: string;
  active?: boolean;
}

export const useStyles = makeStyles<Theme, ColorBarStyles>(theme => ({
  barContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 435,
    height: 40,
    marginBottom: 40,
    position: 'relative',
    cursor: ({ active }) => (active ? 'default' : 'pointer'),
    borderRadius: 20,
    border: ({ active }) => (active ? `2px solid ${theme.palette.primary.main}` : ''),
    transition: 'all 300ms ease',
    '&:hover': {
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeOut,
      }),
      transform: ({ active }) => (active ? '' : 'scaleY(1.2)'),
    },
  },

  firstAccent: {
    width: '30%',
    left: '2%',
    height: 23,
    backgroundColor: ({ firstAccent }) => firstAccent,
    position: 'absolute',
    zIndex: 1,
    borderRadius: 15,
  },
  secondAccent: {
    width: '28%',
    height: 23,
    left: '27%',
    backgroundColor: ({ secondAccent }) => secondAccent,
    position: 'absolute',
    zIndex: 2,
    borderRadius: 15,
  },
  thirdAccent: {
    width: '27%',
    height: 23,
    left: '50%',
    backgroundColor: ({ thirdAccent }) => thirdAccent,
    position: 'absolute',
    zIndex: 3,
    borderRadius: 15,
  },
  fourthAccent: {
    width: '22%',
    height: 23,
    left: '70%',
    backgroundColor: ({ fourthAccent }) => fourthAccent,
    position: 'absolute',
    zIndex: 4,
    borderRadius: 15,
  },
  fifthAccent: {
    width: '14%',
    height: 23,
    left: '83%',
    backgroundColor: ({ fifthAccent }) => fifthAccent,
    position: 'absolute',
    zIndex: 5,
    borderRadius: 15,
  },
  sixthAccent: {
    width: '6%',
    height: 23,
    left: '92%',
    backgroundColor: ({ sixthAccent }) => sixthAccent,
    position: 'absolute',
    zIndex: 6,
    borderRadius: 15,
  },
}));
