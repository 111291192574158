import cn from 'classnames';
import { useStyles } from './SlideFifth.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';
import SlideNumber from '../components/SlideNumber';

export const SlideFifth: React.FC<SlideProps> = ({ colorTheme, photoPack, fontPair, logoUrl }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSourceFirstRowOne: string | null = photos?.items[9].image;
  const backupSourceFirstRowOne: string = photos?.items[9].backupImage!;
  const imageSourceFirstRowTwo: string | null = photos?.items[3].image;
  const backupSourceFirstRowTwo: string = photos?.items[3].backupImage!;
  const imageSourceSecondRowOne: string | null = photos?.items[10].image;
  const backupSourceSecondRowOne: string = photos?.items[10].backupImage!;
  const imageSourceSecondRowTwo: string | null = photos?.items[11].image;
  const backupSourceSecondRowTwo: string = photos?.items[11].backupImage!;

  return (
    <div className={cn(styles.slideCanvas, rootStyles.root)}>
      <div className={styles.galleryContainer}>
        <div className={styles.galleryRightContainer}>
          <div className={styles.galleryRowRound}>
            <img
              src={imageSourceFirstRowOne ? imageSourceFirstRowOne : backupSourceFirstRowOne}
              loading="lazy"
              alt=""
              className={cn(styles.galleryOneRound, styles.galleryOneRoundCorner)}
            />
            <img
              src={imageSourceFirstRowTwo ? imageSourceFirstRowTwo : backupSourceFirstRowTwo}
              loading="lazy"
              alt=""
              className={cn(styles.galleryTwoRound, styles.galleryTwoRoundCorner)}
            />
          </div>
          <div className={styles.galleryRowTwoRound}>
            <img
              src={imageSourceSecondRowOne ? imageSourceSecondRowOne : backupSourceSecondRowOne}
              loading="lazy"
              alt=""
              className={styles.galleryOneRound}
            />
            <img
              src={imageSourceSecondRowTwo ? imageSourceSecondRowTwo : backupSourceSecondRowTwo}
              loading="lazy"
              alt=""
              className={styles.galleryTwoRound}
            />
          </div>
        </div>
        <div className={rootStyles.svgWhite}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1600 900">
            <path
              fill="#fff"
              d="M812,123.8l-143.6,32.3l-64.5,14.5c-41.3,9.3-88.2,19.8-125,48.3c-43,33.3-64.9,71.5-64.9,113.6c0,0,0,0,0,0
	c0,54,35.8,113.3,106.4,176.3c62.6,55.9,135,99,172.5,119.7c3.3,1.9,6.7,3.7,10.2,5.6c67.2,37.3,150.8,83.8,230.8,56.6
	c79.9-27.1,118.4-113.1,148.2-191.5c21.4-56.3,45.6-120.2,48-185.5c0.1-3,0.2-6.1,0.2-9.1c0-70-27.8-131.9-74.4-165.6
	C987.3,89.7,894,105.3,812,123.8z"
            ></path>
            <g id="a"></g>
          </svg>
        </div>
        <div className={rootStyles.svgAccentOne}>
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
            <g id="a"></g>
            <g id="b">
              <g id="c">
                <path
                  fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
                  d="M813.4,133.4c-68.9,15.5-137.8,31-206.8,46.5c-42.5,9.6-86.6,19.8-121,46.5
			c-184.5,142.9,94.7,329.2,211.6,393.7c68.2,37.7,152.9,88.5,233.1,61.3c76.4-25.9,113.7-110.1,142.4-185.6
			c22.3-58.8,44.9-118.9,47.1-181.8s-19.5-130-70.5-166.8C983.3,99.7,892.7,115.5,813.4,133.4L813.4,133.4z"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <div className={styles.flowGalleryTitleContainer}>
          <h1 className={styles.galleryTitleFlow}>Image Gallery with Big Statement</h1>
        </div>
      </div>
      {logoUrl && (
        <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
          <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
        </div>
      )}
      <SlideNumber slide="5" colorTheme={colorTheme} fontPair={fontPair} />
    </div>
  );
};

export default SlideFifth;
