import { makeStyles, Theme } from '@material-ui/core/styles';
import { createStyles, withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

export const ColorButton = withStyles((theme: Theme) => ({
  root: {
    height: 30,
    borderRadius: 20,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.utility.black2,
    backgroundColor: theme.palette.utility.gray1,
    '&:hover': {
      cursor: 'pointer',
    },
    paddingInlineStart: 0,
  },
}))(Button);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconBackground: {
      height: 30,
      width: 30,
      borderRadius: 20,
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconContainer: {
      maxWidth: 15,
      maxHeight: 15,
    },
    labelContainer: {
      width: 60,
    },
  })
);
