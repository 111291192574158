import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  passwordForm: {
    width: 359,
    marginRight: theme.spacing(3),
  },
  submitButton: {
    padding: theme.spacing(1, 5),
  },
}));
