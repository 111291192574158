import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '@material-ui/lab';
import { CreatorActionArea, CreatorTitle, CreatorActionTabs, Loader } from 'components';
import { CreatorUploadArea, CreatorSavedImages } from './components';
import { useStorageManagement } from 'modules/storage/hooks';
import { useStyles } from './CreatorLogoUpload.styles';
import { CreatorRouteProps } from 'views/Creator/types';
import { usePresentationManagement } from 'modules/presentation/hooks';

export enum UploadStep {
  Upload = 1,
  Uploading,
  Preview,
}

interface CreatorLogoUploadProps extends CreatorRouteProps {}

export const CreatorLogoUpload: React.FC<CreatorLogoUploadProps> = ({ match }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { presentationId } = match.params;
  const {
    isLoading,
    isErrored,
    uploadFile,
    uploadProgress,
    savedImages,
    fetchSavedImages,
    setPreviewImageSource,
    previewImageSource,
  } = useStorageManagement();
  const { savePresentationLogo, presentationLogoId } = usePresentationManagement();
  const firestoreImageSource = savedImages
    .filter(item => item.name === presentationLogoId)
    .map(item => item.source)
    .join(',');

  const [uploadStep, setUploadStep] = useState(
    previewImageSource || firestoreImageSource ? UploadStep.Preview : UploadStep.Upload
  );

  const tabs = [t('creatorLogoUpload.uploadTab'), t('creatorLogoUpload.savedTab')];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    if (isErrored) {
      setUploadStep(UploadStep.Upload);
    }
  }, [isErrored]);

  return (
    <CreatorActionArea>
      <CreatorTitle title={t('creatorLogoUpload.title')} />

      <CreatorActionTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
        TabsProps={{ className: styles.actionTabs }}
      >
        <TabPanel className={styles.uploadAreaContainer} classes={{ root: styles.tabPanelRoot }} value={tabs[0]}>
          <CreatorUploadArea
            uploadStep={uploadStep}
            setUploadStep={setUploadStep}
            uploadFile={uploadFile}
            uploadProgress={uploadProgress}
            previewImageSource={firestoreImageSource ? firestoreImageSource : previewImageSource}
            setPreviewImageSource={setPreviewImageSource}
            presentationId={presentationId!}
            savePresentationLogo={savePresentationLogo}
          />
        </TabPanel>

        <TabPanel classes={{ root: styles.tabPanelRoot }} value={tabs[1]}>
          <Loader loading={isLoading}>
            <CreatorSavedImages
              savedImages={savedImages}
              fetchSavedImages={fetchSavedImages}
              setPreviewImageSource={setPreviewImageSource}
              setUploadStep={setUploadStep}
              setActiveTab={setActiveTab}
              tabs={tabs}
              presentationId={presentationId!}
              savePresentationLogo={savePresentationLogo}
            />
          </Loader>
        </TabPanel>
      </CreatorActionTabs>
    </CreatorActionArea>
  );
};
