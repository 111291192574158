import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SharedAction } from './actions';
import type { NotificationVariant } from './state';
import { selectNotificationQueue, selectShowConsultDialog } from './selectors';

export const useSharedManagement = () => {
  const dispatch = useDispatch();
  const notificationQueue = useSelector(selectNotificationQueue);
  const isConsultDialogVisible = useSelector(selectShowConsultDialog);

  const notifyUser = useCallback(
    (message: string, variant?: NotificationVariant) => {
      dispatch(SharedAction.notifyUser(message, variant));
    },
    [dispatch]
  );

  const notifyUserSuccess = useCallback(() => {
    dispatch(SharedAction.notifyUserSuccess());
  }, [dispatch]);

  const showConsultDialog = useCallback(() => {
    dispatch(SharedAction.showConsultDialog());
  }, [dispatch]);

  const hideConsultDialog = useCallback(() => {
    dispatch(SharedAction.hideConsultDialog());
  }, [dispatch]);

  return {
    notificationQueue,
    isConsultDialogVisible,

    notifyUser,
    notifyUserSuccess,
    showConsultDialog,
    hideConsultDialog,
  };
};
