import cn from 'classnames';
import { useStyles } from './SlideFifth.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';
import SquareIcon from '../assets/square-icon.svg';

export const SlideFifth: React.FC<SlideProps> = ({ colorTheme, photoPack, fontPair, logoUrl }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSourceFirstRowOne: string | null = photos?.items[7].image;
  const backupSourceFirstRowOne: string = photos?.items[7].backupImage!;
  const imageSourceFirstRowTwo: string | null = photos?.items[17].image;
  const backupSourceFirstRowTwo: string = photos?.items[17].backupImage!;
  const imageSourceSecondRowOne: string | null = photos?.items[18].image;
  const backupSourceSecondRowOne: string = photos?.items[18].backupImage!;
  const imageSourceSecondRowTwo: string | null = photos?.items[8].image;
  const backupSourceSecondRowTwo: string = photos?.items[8].backupImage!;

  return (
    <div className={cn(styles.slideCanvas, rootStyles.root)}>
      <div className={styles.galleryContainer}>
        <img src={SquareIcon} loading="lazy" alt="" className={styles.galleryOneEdge} />

        <div className={styles.galleryRightContainer}>
          <div className={styles.galleryRowEdge}>
            <img
              src={imageSourceFirstRowOne ? imageSourceFirstRowOne : backupSourceFirstRowOne}
              loading="lazy"
              alt=""
              sizes="100vw"
              className={styles.galleryTwo}
            />
            <img
              src={imageSourceFirstRowTwo ? imageSourceFirstRowTwo : backupSourceFirstRowTwo}
              loading="lazy"
              sizes="100vw"
              alt=""
              className={cn(styles.galleryThree, styles.galleryThreeTopRight)}
            />
          </div>

          <div className={styles.galleryRowTwo}>
            <img
              src={imageSourceSecondRowOne ? imageSourceSecondRowOne : backupSourceSecondRowOne}
              loading="lazy"
              alt=""
              sizes="100vw"
              className={styles.galleryThree}
            />
            <img
              src={imageSourceSecondRowTwo ? imageSourceSecondRowTwo : backupSourceSecondRowTwo}
              loading="lazy"
              alt=""
              sizes="100vw"
              className={styles.galleryTwo}
            />
          </div>
        </div>
      </div>
      <div className={rootStyles.svgWhite}>
        <svg className={styles.svgWhiteFifth} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
          <polygon fill="#fff" points="0,0 0,900 462.5,900 658,0 "></polygon>
        </svg>
      </div>
      <div className={cn(rootStyles.svgAccentOne)}>
        <svg
          className={styles.svgWhiteFifth}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1600 900"
        >
          <polygon
            fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
            points="0,0 0,900 450.9,900 646.4,0 "
          ></polygon>
        </svg>
      </div>

      <div className={rootStyles.divBlock} />
      <h1 className={styles.galleryTitle}>Image Gallery with Big Statement</h1>
      {logoUrl && (
        <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
          <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
        </div>
      )}
      <div className={rootStyles.slideNumberEdge}>
        <div className={rootStyles.edgeSlideNumberShape}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 104" display="block">
            <polygon
              fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              points="22.5,0 0,104 82.5,104 105,0 "
            ></polygon>
          </svg>
        </div>
        <h1 className={cn(rootStyles.number, rootStyles.numberEdge)}>5</h1>
      </div>
    </div>
  );
};

export default SlideFifth;
