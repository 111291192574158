import { useStyles } from './SlideTen.styles';
import { SlideProps } from '../../types';
import square from './images/square.svg';
import { useRootStyles } from '../../common.styles';
import cn from 'classnames';

export const SlideTen: React.FC<SlideProps> = ({ logoUrl, colorTheme, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  const fourStats = [
    {
      heading: '72%',
      colorTheme: colorTheme ? colorTheme?.accent1Color : 'CurrentColor',
      points: '50,50 0,59.7 0,100 100,100 100,0 50,0',
    },
    {
      heading: '55%',
      colorTheme: colorTheme ? colorTheme?.accent2Color : 'CurrentColor',
      points: '50,50 33.8,100 100,100 100,0 50,0',
    },
    {
      heading: '39%',
      colorTheme: colorTheme ? colorTheme?.accent3Color : 'CurrentColor',
      points: '50,50 92,100 100,100 100,0 50,0',
    },
    {
      heading: '84%',
      colorTheme: colorTheme ? colorTheme?.accent4Color : 'CurrentColor',
      points: '52,50 0,17.5 0,100 100,100 100,0 52,0',
    },
  ];
  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <h1 className={styles.slideTitle}>Four Stats</h1>
        <div className={styles.pieRow}>
          {fourStats.map(stat => {
            return (
              <div className={styles.pieBlock} key={stat.heading}>
                <div className={styles.pieContainer}>
                  <div className={styles.pieback}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill={stat.colorTheme}>
                      <g id="a"></g>
                      <rect width="100" height="100"></rect>
                    </svg>
                  </div>
                  <div className={styles.pieSlice}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill={stat.colorTheme}>
                      <g id="a"></g>
                      <polygon points={stat.points}></polygon>
                    </svg>
                  </div>
                  <img src={square} loading="lazy" alt="square" className={styles.image} />
                  <div className={styles.pieCenter}>
                    <h1 className={styles.heading}>{stat.heading}</h1>
                  </div>
                </div>
                <div className={styles.arrowDown}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 79.33 32.42"
                    fill={stat.colorTheme}
                    xmlSpace="preserve"
                  >
                    <g id="a">
                      <g id="b">
                        <g id="c">
                          <path d="M39.66,32.42c-.62,0-1.23-.19-1.75-.57L1.25,5.43C-.1,4.46-.4,2.59,.57,1.25S3.41-.4,4.75,.57L39.66,25.72,74.57,.57c1.34-.97,3.22-.66,4.19,.68,.97,1.34,.66,3.22-.68,4.19L41.42,31.86c-.52,.38-1.14,.57-1.75,.57Z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <div className={styles.pieLabel}>
                  <h1 className={styles.labelTitle}>Stat Title</h1>
                  <p className={styles.labelText}>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean
                    massa.
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className={rootStyles.slideNumber}>
          <h1 className={rootStyles.number}>10</h1>
        </div>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
      </div>
    </>
  );
};

export default SlideTen;
