import React from 'react';
import { useRootStyles } from '../../common.styles';
import { useStyles } from './SlideNumber.styles';
import { SlideStyleProps } from '../../types';
import cn from 'classnames';

interface SlideNumberProps extends SlideStyleProps {
  slide: string;
}

const SlideNumber: React.FC<SlideNumberProps> = ({ slide, colorTheme, fontPair }) => {
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const styles = useStyles({ colorTheme, fontPair });
  return (
    <div className={cn(styles.placment, rootStyles.root)}>
      <h1 className={cn(rootStyles.number, rootStyles.numberRound, styles.number)}>{slide}</h1>
      <div className={cn(styles.svgNumber)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1600 900"
          fill={colorTheme ? colorTheme.accent1Color : 'CurrentColor'}
        >
          <circle cx="800" cy="450" r="406"></circle>
        </svg>
      </div>
    </div>
  );
};

export default SlideNumber;
