import React, { useEffect } from 'react';
import { useRouteMatch, Switch } from 'react-router-dom';
import cn from 'classnames';
import {
  ProtectedRoute,
  CreatorToolbar,
  CreatorPreview,
  CreatorLayout,
  CreatorLogoUpload,
  CreatorColor,
  CreatorFontScheme,
  CreatorPhotoPack,
  CreatorPreviewPanel,
  CreatorDownload,
} from 'components';
import { routes } from 'const';
import { usePresentationManagement } from 'modules/presentation/hooks';
import { useConfigurationManagement } from 'modules/configuration/hooks';
import { useStorageManagement } from 'modules/storage/hooks';
import type { CreatorRouteProps } from './types';
import { useStyles } from './CreatorContainer.styles';

interface CreatorContainerProps extends CreatorRouteProps {}

export const CreatorContainer: React.FC<CreatorContainerProps> = ({ match }) => {
  const styles = useStyles();
  const {
    fetchCuratedColorSchemes,
    fetchUserColorSchemes,
    fetchFontPairs,
    fetchPhotoPacks,
    fetchSubscriptionOptions,
  } = useConfigurationManagement();
  const {
    initializePresentation,
    startPresentationObserver,
    stopPresentationObserver,
    resetPresentationState,
  } = usePresentationManagement();
  const { fetchSavedImages } = useStorageManagement();
  const fullPreviewMatch = useRouteMatch({ path: routes.creator.preview.path });

  const isFullSizePreview = fullPreviewMatch?.isExact;
  const { presentationId } = match.params;

  useEffect(() => {
    if (presentationId) {
      startPresentationObserver(presentationId);
    } else {
      initializePresentation();
    }

    return () => {
      if (presentationId) {
        stopPresentationObserver();
        resetPresentationState();
      }
    };
  }, [
    presentationId,
    initializePresentation,
    startPresentationObserver,
    stopPresentationObserver,
    resetPresentationState,
  ]);

  useEffect(() => {
    fetchSavedImages();
    fetchCuratedColorSchemes();
    fetchUserColorSchemes();
    fetchFontPairs();
    fetchPhotoPacks();
    fetchSubscriptionOptions();
  }, [
    fetchSavedImages,
    fetchCuratedColorSchemes,
    fetchUserColorSchemes,
    fetchFontPairs,
    fetchPhotoPacks,
    fetchSubscriptionOptions,
  ]);

  return (
    <main className={styles.container}>
      <CreatorToolbar className={styles.creatorToolbar} isFullSize={isFullSizePreview} />

      <div
        className={cn(styles.creatorAction, {
          [styles.creatorActionHide]: isFullSizePreview,
        })}
      >
        <Switch>
          <ProtectedRoute exact path={routes.creator.layout.path} component={CreatorLayout} />
          <ProtectedRoute exact path={routes.creator.logo.path} component={CreatorLogoUpload} />
          <ProtectedRoute exact path={routes.creator.color.path} component={CreatorColor} />
          <ProtectedRoute exact path={routes.creator.font.path} component={CreatorFontScheme} />
          <ProtectedRoute exact path={routes.creator.photo.path} component={CreatorPhotoPack} />
          <ProtectedRoute exact path={routes.creator.panel.path} component={CreatorPreviewPanel} />
          <ProtectedRoute exact path={routes.creator.preview.path} component={() => <div />} />
          <ProtectedRoute exact path={routes.creator.download.path} component={CreatorDownload} />
        </Switch>
      </div>

      <CreatorPreview
        className={cn(styles.creatorPreview, { [styles.creatorPreviewFullSize]: isFullSizePreview })}
        isFullSize={isFullSizePreview}
      />
    </main>
  );
};
