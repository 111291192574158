import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CheckedIcon } from 'assets/icons';
import { Loader, Button } from 'components';
import { useConfigurationManagement } from 'modules/configuration/hooks';
import type { StripePriceObject } from 'modules/configuration/types';
import { isStatusPending } from 'modules/store/status';
import { CheckoutSessionErrors, sendToCheckout } from 'modules/auth/utils';
import { useAuthManagement } from 'modules/auth/hooks';
import { PricingSelection } from '../PricingSelection/PricingSelection';
import { useStyles } from './PricingOptions.styles';

const PERKS = [
  '100+ expertly-crafted layouts',
  'Built-in photo library',
  'Built-in icon library',
  'Unlimited template variations',
  'Unlimited template downloads',
];

const centsToDollars = (cents: number) => cents / 100;

export const PricingOptions: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { pricingOptions, pricingOptionsStatus } = useConfigurationManagement();
  const { user } = useAuthManagement();
  const [error, setError] = useState<CheckoutSessionErrors | undefined>(undefined);
  const [isLoading, setLoading] = useState(false);

  const [weekOption, yearOption, lifetimeOption] = [...pricingOptions].sort((a, b) => a.unit_amount - b.unit_amount);
  const [selectedOption, setSelectedOption] = useState<StripePriceObject | undefined>(undefined);

  const isOptionSelected = (option: StripePriceObject) => option.id === selectedOption?.id;

  useEffect(() => {
    setSelectedOption(lifetimeOption);
  }, [lifetimeOption]);

  return (
    <Loader loading={isStatusPending(pricingOptionsStatus)}>
      <Typography className={styles.subtitle}>{t('creatorDownload.subtitle')}</Typography>

      {error && (
        <Alert variant="outlined" severity="error" className={styles.subscriptionErrorBar}>
          {error === CheckoutSessionErrors.SESSION_ERROR
            ? t('creatorDownload.notification.sessionError')
            : t('creatorDownload.notification.noStripeKey')}
        </Alert>
      )}

      <div className={styles.pricingContainer}>
        {weekOption && (
          <PricingSelection
            period={t('creatorDownload.pricingSelection.oneWeek')}
            price={centsToDollars(weekOption.unit_amount)}
            isSelected={isOptionSelected(weekOption)}
            onClick={() => setSelectedOption(weekOption)}
          />
        )}
        {yearOption && (
          <PricingSelection
            period={t('creatorDownload.pricingSelection.oneYear')}
            price={centsToDollars(yearOption.unit_amount)}
            isSelected={isOptionSelected(yearOption)}
            onClick={() => setSelectedOption(yearOption)}
          />
        )}
        {lifetimeOption && (
          <PricingSelection
            period={t('creatorDownload.pricingSelection.lifetime')}
            price={centsToDollars(lifetimeOption.unit_amount)}
            isBestValue
            isSelected={isOptionSelected(lifetimeOption)}
            onClick={() => setSelectedOption(lifetimeOption)}
          />
        )}
      </div>

      <Divider className={styles.divider} component="div" />

      <div className={styles.perksContainer}>
        {PERKS.map((perk, index) => (
          <div key={index} className={styles.subscriptionPerk}>
            <div className={styles.checkedWrapper}>
              <CheckedIcon className={styles.checkedIcon} />
            </div>
            <Typography className={styles.subscriptionPerkText}>{perk}</Typography>
          </div>
        ))}
      </div>

      <Button
        className={styles.subscriptionContinueButton}
        textClassName={styles.subscriptionContinueButtonText}
        variant="contained"
        fullWidth
        disabled={isLoading}
        loading={isLoading}
        onClick={() => sendToCheckout(user!.uid, selectedOption!.id, window.location.href, setError, setLoading)}
      >
        {t('creatorDownload.continue')}
      </Button>
    </Loader>
  );
};
