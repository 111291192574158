import { useStyles } from './SlideThird.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';
import cn from 'classnames';

export const SlideThird: React.FC<SlideProps> = ({ colorTheme, logoUrl, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[7].image;
  const backupImageSource: string = photos?.items[7].backupImage!;

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img
          src={imageSource ? imageSource : backupImageSource}
          loading="lazy"
          alt=""
          className={cn(rootStyles.fullBackgroundImage, styles.fullBackgroundImageRounded)}
        />
        <div className={styles.backgroundOverlay} />

        <div className={rootStyles.svgWhite}>
          <svg
            className={styles.svgWhiteFlowThree}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1600 900"
          >
            <path fill="#fff" d="M0,0v209.5c324,199.1,415.8-37.4,538.2-121.8c98.2-67.7,214-65.5,309.7-87.7H0z"></path>
            <path
              fill="#fff"
              d="M1600,900V679.1c-82.7,62.8-222.8,84.3-390.4,54c-224.4-40.7-243,98.1-475.3,166.9H1600z"
            ></path>
          </svg>
        </div>
        <div className={styles.slideContainer}>
          <h1 className={styles.transition}>Image Section Title</h1>
        </div>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerSvg)}>
            <div className={styles.logoBoxFlowThree}>
              <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SlideThird;
