import { Button, TextField } from '@material-ui/core';
import { AccountSubtitle } from 'components/AccountSubtitle';
import Joi from 'joi';
import { useAuthManagement } from 'modules/auth/hooks';
import { useTranslation } from 'react-i18next';
import { useStyles } from './AccountEmailForm.styles';
import { i18nClient } from 'services/translations';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';

const changeEmailFormSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .trim()
    .required()
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
      'string.email': i18nClient.t('auth.emailFormatErrorMessage'),
    }),
});

interface EmailChangeFormData {
  email: string;
}

export const AccountEmailForm = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { changeUserEmail } = useAuthManagement();

  const { register, errors, handleSubmit } = useForm<EmailChangeFormData>({
    mode: 'onTouched',
    resolver: joiResolver(changeEmailFormSchema),
  });

  return (
    <>
      <AccountSubtitle subtitle={t('account.subtitle.email')} />
      <form noValidate onSubmit={handleSubmit(({ email }) => changeUserEmail(email))}>
        <TextField
          inputRef={register}
          variant="outlined"
          fullWidth
          id="email"
          label={t('account.changeMail.newMailLabel')}
          name="email"
          autoComplete="email"
          size="small"
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          className={styles.emailForm}
        />
        <Button variant="contained" color="primary" className={styles.submitButton} type="submit">
          {t('account.button.update')}
        </Button>
      </form>
    </>
  );
};
