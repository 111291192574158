import { Fade } from '@material-ui/core';
import { usePresentationState } from 'modules/presentation/hooks';
import { RenderSlide } from './RenderSlide';
import { CreatorPreviewHTMLProps } from './types';
import { useStyles } from './CreatorPreviewHTML.styles';

export const CreatorPreviewHTML: React.FC<CreatorPreviewHTMLProps> = ({ slideNumber }) => {
  const { layout, presentationColorTheme, logoUrl, fontPair, photoPacks } = usePresentationState();
  const styles = useStyles();

  return (
    <div className={styles.slideContainer}>
      <Fade in timeout={300}>
        <RenderSlide
          slideNumber={slideNumber}
          colorTheme={presentationColorTheme}
          logoUrl={logoUrl}
          fontPair={fontPair}
          photoPack={photoPacks}
          layout={layout}
        />
      </Fade>
    </div>
  );
};
