import React, { useEffect } from 'react';
import type { RouteComponentProps } from 'react-router';
import { Container, Fade, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './Account.styles';
import { useAuthManagement } from 'modules/auth/hooks';
import { AccountBilling } from 'components/AccountBilling';
import { AccountPlan } from 'components/AccountPlan';
import { useConfigurationManagement } from 'modules/configuration/hooks';
import { AccountDelete } from 'components/AccountDelete';
import { AccountEmailForm } from 'components/AccountEmailForm';
import { AccountPasswordForm } from 'components/AccountPasswordForm';
import { AccountInfo } from 'components/AccountInfo';

interface AccountContainerProps extends RouteComponentProps {}

export const AccountContainer: React.FC<AccountContainerProps> = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { isPremiumUser, isCustomLifetime } = useAuthManagement();
  const { fetchSubscriptionOptions } = useConfigurationManagement();

  // NOTE: we are hiding account plan section for phase 1
  const isAccountPlanSectionVisible = false;

  useEffect(() => fetchSubscriptionOptions(), [fetchSubscriptionOptions]);

  return (
    <Fade in>
      <Container maxWidth="xl">
        <Typography className={styles.accountTitle}>{t('account.accountTitle')}</Typography>
        <AccountInfo />
        <AccountEmailForm />
        <AccountPasswordForm />
        {!isCustomLifetime && <AccountBilling />}
        {!isPremiumUser && isAccountPlanSectionVisible && <AccountPlan />}
        <AccountDelete />
      </Container>
    </Fade>
  );
};
