import React from 'react';
import { ColorTheme } from 'components/CreatorPreview/templates/types';

const TwitterBoxIcon: React.FC<ColorTheme> = ({ colorTheme }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 342.9 342.9"
      fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
      style={{ borderRadius: '100%', width: '16%', height: '100%' }}
    >
      <g>
        <path
          d="M171.5,0C76.8,0,0,76.8,0,171.4s76.8,171.5,171.5,171.5s171.5-76.8,171.5-171.5S266.1,0,171.5,0z M240.9,135.7
             c0.1,1.5,0.1,3.1,0.1,4.7c0,47.6-36.3,102.5-102.5,102.5c-20.4,0-39.3-6-55.3-16.2c2.8,0.3,5.7,0.5,8.6,0.5
             c16.9,0,32.4-5.8,44.8-15.5c-15.8-0.3-29.1-10.7-33.7-25.1c2.2,0.5,4.5,0.7,6.8,0.7c3.3,0,6.4-0.5,9.5-1.3
             c-16.5-3.3-28.9-17.9-28.9-35.4c0-0.2,0-0.3,0-0.5c4.8,2.7,10.5,4.3,16.4,4.5c-9.7-6.4-16.1-17.5-16.1-30c0-6.6,1.7-12.8,4.8-18.1
             c17.8,21.8,44.4,36.1,74.3,37.6c-0.6-2.7-0.9-5.4-0.9-8.2c0-19.9,16.1-36.1,36.1-36.1c10.4,0,19.8,4.4,26.3,11.4
             c8.2-1.6,15.9-4.6,22.9-8.8c-2.7,8.4-8.4,15.5-15.8,19.9c7.3-0.8,14.2-2.8,20.7-5.7C254,124.3,247.9,130.6,240.9,135.7z"
        />
      </g>
    </svg>
  );
};

export default TwitterBoxIcon;
