import { LayoutsDocumentData } from 'modules/configuration/types';
import { SlideComponentsProps } from './types';
import {
  BlockSlideFirst,
  BlockSlideSecond,
  BlockSlideThird,
  BlockSlideFifth,
  BlockSlideFourth,
  BlockSlideSixth,
  BlockSlideSeventh,
  BlockSlideEight,
  BlockSlideNineth,
  BlockSlideTenth,
  BlockSlideEleven,
  BlockSlideTwelth,
} from '../../templates/block';
import {
  RoundSlideFirst,
  RoundSlideSecond,
  RoundSlideThird,
  RoundSlideFifth,
  RoundSlideFourth,
  RoundSlideSixth,
  RoundSlideSeventh,
  RoundSlideEight,
  RoundSlideNineth,
  RoundSlideTenth,
  RoundSlideEleven,
  RoundSlideTwelth,
} from '../../templates/round';
import {
  EdgeSlideFirst,
  EdgeSlideSecond,
  EdgeSlideThird,
  EdgeSlideFifth,
  EdgeSlideFourth,
  EdgeSlideSixth,
  EdgeSlideSeventh,
  EdgeSlideEight,
  EdgeSlideNineth,
  EdgeSlideTenth,
  EdgeSlideEleven,
  EdgeSlideTwelth,
} from '../../templates/edge';
import {
  FlowSlideFirst,
  FlowSlideSecond,
  FlowSlideThird,
  FlowSlideFifth,
  FlowSlideFourth,
  FlowSlideSixth,
  FlowSlideSeventh,
  FlowSlideEight,
  FlowSlideNineth,
  FlowSlideTenth,
  FlowSlideEleven,
  FlowSlideTwelth,
} from '../../templates/flow';

const BlockSlideComponents: SlideComponentsProps = {
  1: BlockSlideFirst,
  2: BlockSlideSecond,
  3: BlockSlideThird,
  4: BlockSlideFourth,
  5: BlockSlideFifth,
  6: BlockSlideSixth,
  7: BlockSlideSeventh,
  8: BlockSlideEight,
  9: BlockSlideNineth,
  10: BlockSlideTenth,
  11: BlockSlideEleven,
  12: BlockSlideTwelth,
};

const RoundSlideComponents: SlideComponentsProps = {
  1: RoundSlideFirst,
  2: RoundSlideSecond,
  3: RoundSlideThird,
  4: RoundSlideFourth,
  5: RoundSlideFifth,
  6: RoundSlideSixth,
  7: RoundSlideSeventh,
  8: RoundSlideEight,
  9: RoundSlideNineth,
  10: RoundSlideTenth,
  11: RoundSlideEleven,
  12: RoundSlideTwelth,
};

const EdgeSlideComponents: SlideComponentsProps = {
  1: EdgeSlideFirst,
  2: EdgeSlideSecond,
  3: EdgeSlideThird,
  4: EdgeSlideFourth,
  5: EdgeSlideFifth,
  6: EdgeSlideSixth,
  7: EdgeSlideSeventh,
  8: EdgeSlideEight,
  9: EdgeSlideNineth,
  10: EdgeSlideTenth,
  11: EdgeSlideEleven,
  12: EdgeSlideTwelth,
};

const FlowSlideComponents: SlideComponentsProps = {
  1: FlowSlideFirst,
  2: FlowSlideSecond,
  3: FlowSlideThird,
  4: FlowSlideFourth,
  5: FlowSlideFifth,
  6: FlowSlideSixth,
  7: FlowSlideSeventh,
  8: FlowSlideEight,
  9: FlowSlideNineth,
  10: FlowSlideTenth,
  11: FlowSlideEleven,
  12: FlowSlideTwelth,
};

export const renderSlideComponent = (slideNumber: number, layout: LayoutsDocumentData | undefined) => {
  return layout?.type === 'ROUND'
    ? RoundSlideComponents[slideNumber]
    : layout?.type === 'EDGE'
    ? EdgeSlideComponents[slideNumber]
    : layout?.type === 'FLOW'
    ? FlowSlideComponents[slideNumber]
    : BlockSlideComponents[slideNumber];
};
