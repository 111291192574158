import React from 'react';
import { useRootStyles } from '../../common.styles';
import { useStyles } from './SlideNumber.styles';
import { SlideStyleProps } from '../../types';
import cn from 'classnames';

interface SlideNumberProps extends SlideStyleProps {
  slide: string;
}

const SlideNumber: React.FC<SlideNumberProps> = ({ slide, colorTheme, fontPair }) => {
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const styles = useStyles({ colorTheme, fontPair });
  return (
    <div className={cn(styles.slideNumberFlow, rootStyles.slideNumber)}>
      <div className={cn(styles.htmlEmbed)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 152.51 145.22"
          fill={colorTheme ? colorTheme.accent1Color : 'CurrentColor'}
        >
          <g id="a">
            <g id="b">
              <g id="c">
                <path d="M141.87,53.3c-8.04-15.12-19.66-28.58-31.45-37.45C98.4,6.81,83.65,1.08,68.6,.14,50.92-.97,39.46,4.67,26.75,16.2,13.34,28.37,3.64,44.85,.87,62.86c-3.12,20.27,2.15,42.18,16.04,57.27,14.69,15.95,36.99,22.76,58.59,24.63,23.73,2.06,50.27-2.3,65.53-20.59,17.07-20.45,13.19-47.62,.84-70.86Z" />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <h1 className={cn(rootStyles.number, rootStyles.numberRound, styles.number)}>{slide}</h1>
    </div>
  );
};

export default SlideNumber;
