import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Typography, LinearProgress, Fade } from '@material-ui/core';
import { Button } from 'components';
import { UploadIcon } from 'assets/icons';
import { ALLOWED_FORMATS } from 'modules/storage/const';
import { validateFile } from 'modules/storage/utils';
import { UploadStep } from 'components/CreatorLogoUpload';
import { useStyles } from './CreatorUploadArea.styles';

interface CreatorUploadAreaProps {
  uploadStep: UploadStep;
  setUploadStep: React.Dispatch<React.SetStateAction<UploadStep>>;
  uploadFile: (file: File) => void;
  uploadProgress: number;
  previewImageSource: string | null;
  setPreviewImageSource: (source: string | null) => void;
  savePresentationLogo: (name: string | null, presentationId: string) => void;
  presentationId: string;
}

export const CreatorUploadArea: React.FC<CreatorUploadAreaProps> = ({
  uploadStep,
  setUploadStep,
  uploadFile,
  uploadProgress,
  previewImageSource,
  setPreviewImageSource,
  savePresentationLogo,
  presentationId,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [uploadAreaActive, setUploadAreaActive] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);

  useEffect(() => {
    if (uploadedFile && UploadStep.Preview && previewImageSource) {
      savePresentationLogo(uploadedFile, presentationId);
    }
  }, [savePresentationLogo, presentationId, uploadedFile, previewImageSource]);

  useEffect(() => {
    if (previewImageSource) {
      setUploadStep(UploadStep.Preview);
    }
  }, [previewImageSource, setUploadStep]);

  const handleDragEnter: React.DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault();
    setUploadAreaActive(true);
    setValidationError(false);
  };

  const handleDragOver: React.DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault();
  };

  const handleDragLeave: React.DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault();
    setUploadAreaActive(false);
  };

  const handleDrop: React.DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault();
    setUploadAreaActive(false);

    const file = event.dataTransfer.files.length > 0 && event.dataTransfer.files.item(0);

    if (file && validateFile(file)) {
      setUploadStep(UploadStep.Uploading);
      uploadFile(file);
      setUploadedFile(file.name);
    } else {
      setValidationError(true);
    }
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setValidationError(false);

    const file = event.target.files && event.target.files.length > 0 && event.target.files.item(0);
    if (file && validateFile(file)) {
      setUploadStep(UploadStep.Uploading);
      uploadFile(file);
      setUploadedFile(file.name);
    } else {
      setValidationError(true);
    }
  };

  const handleReset = (): void => {
    setUploadStep(UploadStep.Upload);
    savePresentationLogo(null, presentationId);
    setPreviewImageSource(null);
  };

  return (
    <>
      {uploadStep === UploadStep.Upload && (
        <div
          className={cn(styles.uploadArea, {
            [styles.uploadAreaActive]: uploadAreaActive,
            [styles.uploadAreaErrored]: validationError,
          })}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <UploadIcon className={cn(styles.uploadIcon, styles.uploadIconStepUpload, styles.uploadAreaElements)} />
          <Typography className={cn(styles.uploadPrimaryMessage, styles.uploadAreaElements)}>
            {t('creatorLogoUpload.uploadPrimaryMessage')}
          </Typography>
          <Typography className={cn(styles.uploadSecondaryMessage, styles.uploadAreaElements)}>
            {t('creatorLogoUpload.uploadSecondaryMessage')}
          </Typography>
          <Button
            className={cn(styles.uploadButton, { [styles.uploadAreaElements]: uploadAreaActive })}
            acceptFormats={ALLOWED_FORMATS}
            onInputChange={handleChange}
            uploadMode
          >
            {t('creatorLogoUpload.chooseFile')}
          </Button>

          {validationError && (
            <Typography className={cn(styles.uploadErrorMessage, styles.uploadAreaElements)}>
              {t('creatorLogoUpload.uploadErrorMessage')}
            </Typography>
          )}
        </div>
      )}

      {uploadStep === UploadStep.Uploading && (
        <Fade in>
          <div className={styles.uploadArea}>
            <UploadIcon className={cn(styles.uploadIcon, styles.uploadIconStepUploading)} />
            <LinearProgress
              classes={{ root: styles.uploadProgressBarRoot }}
              variant="determinate"
              value={uploadProgress}
            />
            <Typography color="primary">{t('creatorLogoUpload.uploading', { uploadValue: uploadProgress })}</Typography>
          </div>
        </Fade>
      )}

      {uploadStep === UploadStep.Preview && previewImageSource && (
        <Fade in>
          <div>
            <div className={styles.uploadPreview}>
              <img className={styles.uploadPreviewImage} src={previewImageSource} alt="Company logotype" />
            </div>
            <Button
              className={styles.resetButton}
              // startIcon={<ResetIcon className={styles.resetIcon} />}
              onClick={handleReset}
            >
              {t('creatorLogoUpload.resetPreview')}
            </Button>
          </div>
        </Fade>
      )}

      {uploadStep !== UploadStep.Preview && (
        <Typography className={styles.uploadHint}>{t('creatorLogoUpload.uploadHint')}</Typography>
      )}
    </>
  );
};
