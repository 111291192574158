import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CreatorActionArea, CreatorTitle } from 'components';
import type { CreatorRouteParams, CreatorRouteProps } from 'views/Creator/types';
import { Typography } from '@material-ui/core';
import { routes } from 'const';
import { useParams } from 'react-router-dom';
import { Button } from 'components/Button';
import { useStyles } from './CreatorPreviewPanel.styles';

interface CreatorPreviewProps extends CreatorRouteProps {}

export const CreatorPreviewPanel: React.FC<CreatorPreviewProps> = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { presentationId } = useParams<CreatorRouteParams>();

  return (
    <CreatorActionArea>
      <CreatorTitle className={styles.previewTitle} title={t('creatorComprehensivePreviewPanel.title')} />
      <Typography>{t('creatorComprehensivePreviewPanel.subtitle')}</Typography>
      <div className={styles.previewInfoWrapper}>
        <Typography component="span" className={styles.previewInfoTitle}>
          {t('creatorComprehensivePreviewPanel.infoTitle')}
        </Typography>
        <Typography component="span" className={styles.previewInfoNote}>
          {t('creatorComprehensivePreviewPanel.infoNote')}
        </Typography>
      </div>
      <Button
        className={styles.previewButton}
        onClick={() => history.push(routes.creator.preview.link(presentationId!))}
      >
        Generate Preview
      </Button>
    </CreatorActionArea>
  );
};
