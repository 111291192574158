import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { appLocalStorage } from 'services/storage/local-storage';
import { useAuthManagement } from 'modules/auth/hooks';
import { useSharedManagement } from 'modules/shared/hooks';
import { CreatorActionArea, CreatorTitle } from 'components';
import { PricingOptions, DownloadOptions, ConsultDialog } from './components';
import type { CreatorRouteProps } from 'views/Creator/types';

const mailTo = 'services@sharpslide.com';
const mailSubject = 'Request for Expert Presentation Design Consultation';
const mailBody =
  "Dear Sharpslide,%0DAI'm a Sharpslide customer and I'm looking for additional presentation design assistance. I'd like to arrange a consult with one of your presentation design experts.%0DAAdditional details:";

interface CreatorDownloadProps extends CreatorRouteProps {}

export const CreatorDownload: React.FC<CreatorDownloadProps> = ({ match, location }) => {
  const { t } = useTranslation();
  const { user, isPremiumUser } = useAuthManagement();
  const { isConsultDialogVisible, hideConsultDialog, notifyUser } = useSharedManagement();

  const { presentationId } = match.params;

  const handleCloseDialog = (): void => {
    hideConsultDialog();
  };

  const handlePermanentlyCloseDialog = (): void => {
    hideConsultDialog();
    appLocalStorage.put('consult-dialog-dismissed', true);
  };

  const handleConsultConfirm = (): void => {
    handleCloseDialog();

    if (user) {
      window.open(`mailto:${mailTo}?subject=${mailSubject}&body=${mailBody}`, '_self');
    }
  };

  useEffect(() => {
    const { paymentSucceed } = queryString.parse(location.search, { parseBooleans: true });

    if (paymentSucceed) notifyUser(t('creatorDownload.notification.paymentSuccessed'), 'success');
    if (paymentSucceed === false) notifyUser(t('creatorDownload.notification.paymentFailed'), 'error');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <CreatorActionArea>
      <CreatorTitle title={t('creatorDownload.title')} />

      {isPremiumUser ? <DownloadOptions presentationId={presentationId!} /> : <PricingOptions />}

      <ConsultDialog
        isDialogOpen={isConsultDialogVisible}
        onClose={handleCloseDialog}
        onConfirm={handleConsultConfirm}
        onPermanentClose={handlePermanentlyCloseDialog}
      />
    </CreatorActionArea>
  );
};
