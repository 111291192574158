import { useStyles } from './SlideFirst.styles';
import { useRootStyles } from '../../common.styles';
import { SlideProps } from '../../types';
import cn from 'classnames';

export const SlideFirst: React.FC<SlideProps> = ({ colorTheme, logoUrl, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[0].image;
  const backupImageSource: string = photos?.items[0].backupImage!;
  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img
          src={imageSource ? imageSource : backupImageSource}
          loading="lazy"
          alt=""
          className={rootStyles.fullBackgroundImage}
        />
        <div className={cn(rootStyles.svgAccentOne, rootStyles.opacitySeventy)}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1600 900" display="block">
            <path
              fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              d="M1135,0H465C327.8,102.3,239,265.8,239,450s88.8,347.7,226,450H1135c137.1-102.3,226-265.8,226-450
	S1272.2,102.3,1135,0z M800,856c-224.2,0-406-181.8-406-406S575.8,44,800,44s406,181.8,406,406S1024.2,856,800,856z"
            ></path>
          </svg>
        </div>

        <div className={cn(rootStyles.svgDark, rootStyles.opacityEighty)}>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1600 900" display="block">
            <circle cx="800" cy="450" r="406"></circle>
          </svg>
        </div>
        <h1 className={styles.titleOneRound}>
          Image
          <br />
          Title Slide
        </h1>
        <h2 className={styles.subtitleOneRound}>Presenter Name / Date</h2>
        {logoUrl && (
          <div className={(rootStyles.logoContainer, rootStyles.logoContainerSvgOne)}>
            <div className={rootStyles.svgWhite}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900" display="block">
                <path
                  fill="#fff"
                  d="M469.3,0H176.7C136.8,38.4,112,92.3,112,152c0,116.5,94.5,211,211,211s211-94.5,211-211
	C534,92.3,509.2,38.4,469.3,0z"
                ></path>
              </svg>
            </div>
            <div className={styles.logoBoxRoundTitleOne}>
              <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SlideFirst;
