import React from 'react';
import { EyeDropperIcon } from 'assets/icons';
import { useStyles, ColorButton } from './ColorPickerButton.styles';

const EyeDropper = () => {
  const styles = useStyles();

  return (
    <div className={styles.iconBackground}>
      <EyeDropperIcon className={styles.iconContainer} />
    </div>
  );
};

interface ColorPickerButtonProps {
  color: string;
  onClick: () => void;
}

export const ColorPickerButton = ({ color, onClick }: ColorPickerButtonProps) => {
  const styles = useStyles();

  return (
    <ColorButton fullWidth startIcon={<EyeDropper />} onClick={onClick}>
      <div className={styles.labelContainer}> {color}</div>
    </ColorButton>
  );
};
