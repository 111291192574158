import { Button, CircularProgress } from '@material-ui/core';
import { AccountSubtitle } from 'components/AccountSubtitle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendToSubscriptionPortal } from './AccountBilling.helper';
import { useStyles } from './AccountBilling.styles';

export const AccountBilling = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const styles = useStyles();

  return (
    <>
      <AccountSubtitle subtitle={t('account.subtitle.billing')} />
      <Button
        disabled={isLoading}
        variant="contained"
        color={'primary'}
        onClick={() => sendToSubscriptionPortal(setLoading)}
        className={styles.submitButton}
      >
        {t('account.button.billingInfo')}
        {isLoading && <CircularProgress size={16} className={styles.subscriptionLoader} />}
      </Button>
    </>
  );
};
