import type firebase from 'firebase';

export type UserCredentials = {
  email: string;
  password: string;
};

export enum UserAuthStatus {
  UNKNOWN = 'unknown',
  SIGNED_IN = 'signed-in',
  SIGNED_OUT = 'signed-out',
}

export enum UserPremiumStatus {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
}

export enum UserPremiumStatusV2 {
  ONE_WEEK = 'ONE_WEEK',
  ONE_YEAR = 'ONE_YEAR',
  LIFETIME = 'PAID_LIFETIME',
}

export type UserClaims = {
  stripeRole?: UserPremiumStatus | UserPremiumStatusV2;
  lifetime?: boolean;
};

export type CheckoutSessionDocumentData = Partial<{
  sessionId: string;
  error: firebase.firestore.FirestoreError;
}>;
