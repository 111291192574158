import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  popper: {
    filter: `drop-shadow(7px 7px 6px ${theme.palette.utility.grayscale[400]})`,
  },
  tooltip: {
    padding: theme.spacing(1.25, 2.75),
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    background: `transparent linear-gradient(270deg, ${theme.palette.primary.dark} 0%, ${theme.palette.secondary.dark} 100%) 0% 0%`,
    clipPath: 'polygon(20px 0%, 100% 0%, 100% 100%, 20px 100%, 0% 50%)',
  },
}));
