import { makeStyles, Theme } from '@material-ui/core/styles';

export interface StyleProps {
  width: number;
  color: string;
}

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  bar: {
    height: 30,
    width: ({ width }) => width,
    backgroundColor: ({ color }) => color,
    borderRadius: 20,
  },
}));
