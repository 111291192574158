import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  subscriptionLoader: {
    marginLeft: theme.spacing(0.75),
  },
  submitButton: {
    width: 359,
    padding: theme.spacing(1, 5),
  },
}));
