import React from 'react';
import cn from 'classnames';
import { CircularProgress } from '@material-ui/core';
import { useStyles } from './Loader.styles';

interface LoaderProps {
  className?: string;
  loading: boolean;
  centered?: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ children, className, loading, centered = true }) => {
  const styles = useStyles({ centered });

  return (
    <div className={cn(className, styles.root)}>
      {loading && (
        <div className={styles.spinnerWrapper}>
          <CircularProgress />
        </div>
      )}
      <div className={cn(styles.content, { [styles.contentLoading]: loading })}>{children}</div>
    </div>
  );
};
