import { selectFontPairs, selectPhotoPacks, selectPresentationLayouts } from './../configuration/selectors';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PresentationAction } from './actions';
import { selectPresentationState, selectPreviewImages } from './selectors';
import type { PresentationDto } from './types';
import { selectPreviewImageSource, selectSavedImages } from 'modules/storage/selectors';
import { useConfigurationManagement } from 'modules/configuration/hooks';

export const usePresentationManagement = () => {
  const dispatch = useDispatch();

  const presentationState = useSelector(selectPresentationState);

  const initializePresentation = useCallback(() => {
    dispatch(PresentationAction.initializePresentation());
  }, [dispatch]);

  const startPresentationObserver = useCallback(
    (presentationId: string) => {
      dispatch(PresentationAction.startPresentationObserver(presentationId));
    },
    [dispatch]
  );

  const stopPresentationObserver = useCallback(() => {
    dispatch(PresentationAction.stopPresentationObserver());
  }, [dispatch]);

  const savePresentationLayout = useCallback(
    (layoutId: PresentationDto['layout'], presentationId: string) => {
      dispatch(PresentationAction.savePresentationLayout(layoutId, presentationId));
    },
    [dispatch]
  );

  const savePresentationLogo = useCallback(
    (logo: PresentationDto['logo'], presentationId: string) => {
      dispatch(PresentationAction.savePresentationLogo(logo, presentationId));
    },
    [dispatch]
  );

  const savePresentationColors = useCallback(
    (colorTheme: PresentationDto['colorTheme'], presentationId: string) => {
      dispatch(PresentationAction.savePresentationColors(colorTheme, presentationId));
    },
    [dispatch]
  );

  const savePresentationFontTheme = useCallback(
    (fontThemeId: PresentationDto['fontTheme'], presentationId: string) => {
      dispatch(PresentationAction.savePresentationFontTheme(fontThemeId, presentationId));
    },
    [dispatch]
  );

  const savePresentationPhotoPack = useCallback(
    (photoPackId: PresentationDto['photoPackId'], presentationId: string) => {
      dispatch(PresentationAction.savePresentationPhotoPack(photoPackId, presentationId));
    },
    [dispatch]
  );

  const generatePresentationDownloadLink = useCallback(
    (presentationId: string) => {
      dispatch(PresentationAction.generatePresentationDownloadLink(presentationId));
    },
    [dispatch]
  );

  const resetPresentationDownloadLink = useCallback(() => {
    dispatch(PresentationAction.resetPresentationDownloadLink());
  }, [dispatch]);

  const resetPresentationState = useCallback(() => {
    dispatch(PresentationAction.resetPresentationState());
  }, [dispatch]);

  return {
    presentationLayoutId: presentationState.layout.data,
    presentationLogoId: presentationState.logo.data,
    presentationColorsId: presentationState.colorTheme.data?.internalId,
    presentationFontThemeId: presentationState.fontTheme.data,
    presentationPhotoPackId: presentationState.photoPack.data,
    presentationDownloadURL: presentationState.downloadURL.data,
    presentationDownloadURLStatus: presentationState.downloadURL.status,
    presentationFontSchemeStatus: presentationState.fontTheme.status,

    initializePresentation,
    startPresentationObserver,
    stopPresentationObserver,

    savePresentationLayout,
    savePresentationLogo,
    savePresentationColors,
    savePresentationFontTheme,
    savePresentationPhotoPack,
    generatePresentationDownloadLink,
    resetPresentationDownloadLink,
    resetPresentationState,
  };
};

export const usePreviewManagement = () => {
  const dispatch = useDispatch();

  const previewImages = useSelector(selectPreviewImages);

  const startPreviewObserver = useCallback(
    (presentationId: string) => {
      dispatch(PresentationAction.startPreviewObserver(presentationId));
    },
    [dispatch]
  );

  const stopPreviewObserver = useCallback(() => {
    dispatch(PresentationAction.stopPreviewObserver());
  }, [dispatch]);

  const generatePresentationFullPreview = useCallback(
    (presentationId: string) => {
      dispatch(PresentationAction.generatePresentationFullPreview(presentationId));
    },
    [dispatch]
  );
  return {
    presentationPreviewImages: previewImages.data,
    presentationPreviewStatus: previewImages.status,
    startPreviewObserver,
    stopPreviewObserver,
    generatePresentationFullPreview,
  };
};

export const usePresentationState = () => {
  const presentationState = useSelector(selectPresentationState);
  const photoPacks = useSelector(selectPhotoPacks);
  const allFonts = useSelector(selectFontPairs);
  const savedLogoImages = useSelector(selectSavedImages);
  const previewImageSource = useSelector(selectPreviewImageSource);
  const presentationLayouts = useSelector(selectPresentationLayouts);
  const imageSource = savedLogoImages.imageItems
    .filter(o => o.name === presentationState.logo.data)
    .map(img => img.source)
    .join(',');
  const photos = photoPacks.data.find(o => o.id === presentationState.photoPack.data);
  const fonts = allFonts.data.find(o => o.id === presentationState.fontTheme.data);
  const presentationLayout = presentationLayouts.data.find(layout => layout.id === presentationState.layout.data);
  const { curatedColorSchemes } = useConfigurationManagement();
  const firstColorScheme = curatedColorSchemes.find(colorScheme => colorScheme.order === 0);
  const firstFontPair = allFonts.data.find(fontPair => !fontPair.isSystemPair && fontPair.order === 0);
  return {
    layout: presentationLayout,
    logoUrl: previewImageSource || imageSource,
    presentationColorTheme: presentationState.colorTheme.data || firstColorScheme,
    photoPacks: photos || photoPacks?.data[0],
    fontPair: fonts || firstFontPair,
    allFonts: allFonts.data,
  };
};
