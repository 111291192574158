import { memo, useEffect, useRef, useState } from 'react';
import { useCurrentWidth } from 'hooks';
import { RenderSlideProps } from './types';
import { renderSlideComponent } from './utils';

export const RenderSlide: React.FC<RenderSlideProps> = memo(
  ({ slideNumber, colorTheme, logoUrl, photoPack, fontPair, layout }) => {
    const SlideComponent = renderSlideComponent(slideNumber, layout);
    const containerRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const screenWidth = useCurrentWidth();
    const [fontSize, setFontSize] = useState<string>();
    useEffect(() => {
      if (containerRef && containerRef.current) {
        setWidth(containerRef.current.getBoundingClientRect().width);
        const size = width * 0.01 + 'px';
        setFontSize(size);
      }
    }, [width, screenWidth]);

    return (
      <div ref={containerRef} style={{ fontSize: fontSize }}>
        {SlideComponent ? (
          <SlideComponent colorTheme={colorTheme} logoUrl={logoUrl} photoPack={photoPack} fontPair={fontPair} />
        ) : null}
      </div>
    );
  }
);
