import { useStyles } from './SlideNine.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';
import cn from 'classnames';

export const SlideNine: React.FC<SlideProps> = ({ colorTheme, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  const photos = photoPack;
  const imageSource: string | null = photos?.items[18].image;
  const backupImageSource: string = photos?.items[18].backupImage!;

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img
          src={imageSource ? imageSource : backupImageSource}
          loading="lazy"
          alt=""
          className={styles.leftImageEdge}
        />
        <div className={rootStyles.svgWhite}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900" fill="#fff">
            <polygon points="694.7,0 499.1,900 1600,900 1600,0 " />
          </svg>
        </div>
        <div className={rootStyles.svgAccentOne}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1600 900"
            fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
          >
            <polygon points="749.7,101.8 599.9,101.8 570.2,238.7 720,238.7 " />
          </svg>
        </div>
        <div className={rootStyles.svgWhite}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900" fill="#fff">
            <g id="b">
              <g id="c">
                <path
                  d="M623.8,195.3c-3.3-4.9-4.9-10.7-4.9-17.3c0-9,2.5-16.4,6.6-23.8c4.9-7.4,11.5-13.2,20.6-17.3l2.5,4.9
			c-5.8,2.5-9.9,5.8-14,10.7c-4.1,4.9-5.8,9.9-5.8,14.8c0,2.5,0,4.1,0.8,5.8c2.5-2.5,5.8-3.3,9.9-3.3c4.1,0,8.2,1.6,11.5,4.9
			c3.3,3.3,4.9,7.4,4.9,12.3c0,3.3-0.8,5.8-2.5,9c-1.6,1.6-3.3,4.1-5.8,5.8c-2.5,0.8-5.8,1.6-8.2,1.6
			C632,203.6,627.1,201.1,623.8,195.3L623.8,195.3z M668.2,195.3c-2.5-4.9-4.1-10.7-4.1-17.3c0-9,2.5-16.4,6.6-23.8
			c4.9-7.4,11.5-13.2,20.6-17.3l2.5,4.9c-5.8,2.5-9.9,5.8-14,10.7c-4.1,4.9-6.6,9.9-6.6,14.8c0,2.5,0,4.1,0.8,5.8
			c3.3-2.5,6.6-3.3,9.9-3.3c4.9,0,9,1.6,12.3,4.9c3.3,2.5,4.9,6.6,4.9,12.3c0,4.9-1.6,9-4.9,12.3c-3.3,2.5-7.4,4.1-12.3,4.1
			C677.2,203.6,672.3,201.1,668.2,195.3L668.2,195.3z"
                />
              </g>
            </g>
          </svg>
        </div>
        <p className={styles.quoteTextEdge}>
          A hundred times every day I remind myself that my inner and outer life are based on the labors of other men,
          living and dead, and that I must exert myself in order to give in the same measure as I have received and am
          still receiving. "
        </p>
        <h1 className={styles.quoteAuthorEdge}>Albert Einstein</h1>
        <div className={rootStyles.slideNumberEdge}>
          <div className={rootStyles.edgeSlideNumberShape}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 104" display="block">
              <polygon
                fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
                points="22.5,0 0,104 82.5,104 105,0 "
              ></polygon>
            </svg>
          </div>
          <h1 className={cn(rootStyles.number, rootStyles.numberEdge)}>9</h1>
        </div>
      </div>
    </>
  );
};

export default SlideNine;
