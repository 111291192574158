import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontWeightSemiBold: 700,
  },
  palette: {
    primary: {
      light: '#1FBFFB',
      main: '#3A5DF6',
      dark: '#1293F9',
    },
    secondary: {
      light: '#4B30E9', // not used
      main: '#4B30E9', // not used
      dark: '#4B30E9',
    },
    utility: {
      black1: '#0E0E1E',
      black2: '#434353',
      gray1: '#ECECF0',
      gray2: '#C9C9CE',
      gray3: '#A1A1A3',
      grayscale: {
        100: '#00000012',
        200: '#00000027',
        300: '#00000029',
        400: '#00000041',
        500: '#00000043',
        600: '#0000004D',
        700: '#00000074',
      },
    },
    slide: {
      black: '#0e0e1e',
      modal: '#0e0e1ebf!important',
      accent1: '#3a5df6',
      accent2: '#17befc',
      accent3: '#1293f9',
      accent4: '#4b30e9',
      accent5: '#7474f7',
      accent6: '#b1b1ff',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  /**
   * value         |0px     600px    960px    1366px   1920px
   * key           |xs      sm       md       lg       xl
   * screen width  |--------|--------|--------|--------|-------->
   * range         |   xs   |   sm   |   md   |   lg   |   xl
   */
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1800,
    },
  },
});
