import cn from 'classnames';
import { useStyles } from './SlideFifth.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';

export const SlideFifth: React.FC<SlideProps> = ({ colorTheme, photoPack, fontPair, logoUrl }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[20].image;
  const backupImageSource: string = photos?.items[20].backupImage!;
  const imageSourceFirstRowOne: string | null = photos?.items[7].image;
  const backupSourceFirstRowOne: string = photos?.items[7].backupImage!;
  const imageSourceFirstRowTwo: string | null = photos?.items[17].image;
  const backupSourceFirstRowTwo: string = photos?.items[17].backupImage!;
  const imageSourceSecondRowOne: string | null = photos?.items[18].image;
  const backupSourceSecondRowOne: string = photos?.items[18].backupImage!;
  const imageSourceSecondRowTwo: string | null = photos?.items[8].image;
  const backupSourceSecondRowTwo: string = photos?.items[8].backupImage!;

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <div className={styles.galleryContainer}>
          <img src={imageSource ? imageSource : backupImageSource} loading="lazy" alt="" className={styles.galeryOne} />
          <div className={styles.galleryRightContainer}>
            <div className={styles.galleryRow}>
              <img
                src={imageSourceFirstRowOne ? imageSourceFirstRowOne : backupSourceFirstRowOne}
                loading="lazy"
                alt=""
                className={styles.galeryTwo}
              />
              <img
                src={imageSourceFirstRowTwo ? imageSourceFirstRowTwo : backupSourceFirstRowTwo}
                loading="lazy"
                alt=""
                className={cn(styles.galeryThree, styles.galeryThreeRight)}
              />
            </div>
            <div className={styles.galleryRowTwo}>
              <img
                src={imageSourceSecondRowOne ? imageSourceSecondRowOne : backupSourceSecondRowOne}
                loading="lazy"
                alt=""
                className={styles.galeryThree}
              />
              <img
                src={imageSourceSecondRowTwo ? imageSourceSecondRowTwo : backupSourceSecondRowTwo}
                loading="lazy"
                alt=""
                className={styles.galeryTwo}
              />
            </div>
          </div>
        </div>
        <div className={styles.galleryOverlay} />
        <h1 className={styles.galleryTitle}>Image Gallery with Big Statement</h1>
        <div className={rootStyles.slideNumber}>
          <h1 className={rootStyles.number}>5</h1>
        </div>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
      </div>
    </>
  );
};

export default SlideFifth;
