import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import cn from 'classnames';
import { Typography, Tooltip, Grow, Fade } from '@material-ui/core';
import { usePresentationState, usePreviewManagement } from 'modules/presentation/hooks';
import { SAMPLE_PREVIEW_SIZE_LIMIT } from 'modules/presentation/const';
import { isStatusPending } from 'modules/store/status';
import { clamp } from 'modules/presentation/utils';
import { InfoIcon } from 'assets/icons';
import { usePrevious } from 'hooks';
import type { CreatorRouteParams } from 'views/Creator/types';
import { CreatorPreviewSlide } from './components';
import { useStyles } from './CreatorPreview.styles';
import { CreatorPreviewHTML } from './components/CreatorPreviewHTML/CreatorPreviewHTML';
import firebase from 'firebase';
import { SharedAction } from 'modules/shared/actions';
import { i18nClient } from 'services/translations';
import { Skeleton } from '@material-ui/lab';
import { loadFont } from './utils';
import CreatorPreviewModal from './components/CreatorPreviewModal/CreatorPreviewModal';

interface CreatorPreviewProps {
  className?: string;
  isFullSize?: boolean;
}

export const CreatorPreview: React.FC<CreatorPreviewProps> = ({ className, isFullSize }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerHeight = containerRef.current?.getBoundingClientRect().height;
  const styles = useStyles({ height: Math.max(containerHeight || 0, 205) });
  const { t } = useTranslation();
  const {
    presentationPreviewImages,
    generatePresentationFullPreview,
    presentationPreviewStatus,
    startPreviewObserver,
    stopPreviewObserver,
  } = usePreviewManagement();
  const { presentationId } = useParams<CreatorRouteParams>();
  const { allFonts } = usePresentationState();
  const previewTitle = isFullSize ? t('creatorPreview.fullSizeTitle') : t('creatorPreview.title');
  const previewLimit = isFullSize ? undefined : SAMPLE_PREVIEW_SIZE_LIMIT;
  const previewImages = presentationPreviewImages.slice(0, previewLimit);
  const isTransitioningFromFullSize = usePrevious(isFullSize);
  const storage = firebase.storage();

  useEffect(() => {
    if (presentationId) {
      startPreviewObserver(presentationId);
    }

    return () => {
      if (presentationId) {
        stopPreviewObserver();
      }
    };
  }, [presentationId, startPreviewObserver, stopPreviewObserver]);

  useEffect(() => {
    if (isFullSize) {
      previewImages.forEach(image => {
        if (image?.source) {
          image.source = '';
        }
      });

      generatePresentationFullPreview(presentationId!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullSize, presentationId, generatePresentationFullPreview]);

  useEffect(() => {
    if (allFonts.length > 1) {
      allFonts.forEach(font => {
        try {
          storage
            .refFromURL(font?.majorFont?.reference!)
            .getDownloadURL()
            .then(url => {
              fetch(url);
              loadFont(font?.majorFont.familyName!, url);
            });
          storage
            .refFromURL(font?.minorFont?.reference!)
            .getDownloadURL()
            .then(url => {
              fetch(url);
              loadFont(font?.minorFont.familyName!, url);
            });
        } catch {
          SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error');
        }
      });
    }
  }, [allFonts, storage]);

  const emptyPreviewImages = presentationPreviewImages.filter(image => image?.source === '');
  const hasImagesToLoad = emptyPreviewImages.length !== 0;
  const imagesToLoadCount = emptyPreviewImages.length;
  const previewImagesCount = previewImages.length;
  const pendingPresentationStatus = isStatusPending(presentationPreviewStatus);

  return (
    <div className={cn(styles.previewContainer, className)}>
      <div className={styles.previewTitleWrapper}>
        <Typography className={styles.previewTitle} component="span">
          {previewTitle}
        </Typography>
        <Tooltip title={t('creatorPreview.tooltip')!} arrow>
          <InfoIcon className={styles.infoIcon} />
        </Tooltip>
      </div>
      {isFullSize ? (
        <>
          <CreatorPreviewModal
            onOpen={hasImagesToLoad}
            onClose={() => !hasImagesToLoad}
            imagesToLoadCount={imagesToLoadCount}
            previewImagesCount={previewImagesCount}
            pendingPresentationStatus={pendingPresentationStatus}
          />
          <div
            className={cn(styles.slidesContainer, {
              [styles.slidesContainerFullSize]: isFullSize,
              [styles.slidesContainerFadeIn]: isFullSize,
              [styles.slidesContainerFadeOut]: isTransitioningFromFullSize,
            })}
          >
            {previewImages.map((previewImage, index) => (
              <Fade key={index} in timeout={{ enter: clamp((index + 1) * 150, 150, 1800) }}>
                {!isStatusPending(presentationPreviewStatus) ? (
                  <CreatorPreviewSlide source={previewImage?.source} isLoading={!previewImage?.source} />
                ) : (
                  <Skeleton className={styles.skeleton} variant="rect" animation="wave" />
                )}
              </Fade>
            ))}
          </div>
        </>
      ) : (
        <div className={styles.slidesContainer}>
          {[...Array(previewLimit)].map((_, index) => (
            <Grow key={index} in timeout={{ enter: clamp((index + 1) * 150, 150, 1800) }}>
              <CreatorPreviewHTML slideNumber={index + 1} />
            </Grow>
          ))}
        </div>
      )}
    </div>
  );
};
