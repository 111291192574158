import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, Partial<SlideStyleProps>>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#fff',
  },
  slideTitle: {
    position: 'absolute',
    left: '0%',
    top: '7%',
    right: '0%',
    bottom: 'auto',
    marginTop: '0px',
    marginBottom: '0px',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    fontSize: '3.8em',
    lineHeight: '110%',
    textAlign: 'center',
  },
  iconsRowOne: {
    position: 'absolute',
    left: '0%',
    top: '23%',
    right: '0%',
    bottom: 'auto',
    display: 'flex',
    paddingLeft: '6%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  iconsRowTwo: {
    position: 'absolute',
    left: '0%',
    top: '58%',
    right: '0%',
    bottom: 'auto',
    display: 'flex',
    paddingLeft: '6%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconBlock: {
    position: 'relative',
    display: 'flex',
    width: '32.5%',
  },
  iconContainer: {
    position: 'absolute',
    display: 'flex',
    width: '30%',
    padding: '6%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
  },
  iconContainerTop: {
    height: '72%',
  },
  iconContainerTwo: {
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent2Color : theme.palette.slide.accent2),
  },
  iconContainerThree: {
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent3Color : theme.palette.slide.accent3),
  },
  iconContainerFour: {
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent4Color : theme.palette.slide.accent4),
  },
  iconContainerFive: {
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent5Color : theme.palette.slide.accent5),
  },
  iconContainerSix: {
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent6Color : theme.palette.slide.accent6),
  },
  iconLabel: {
    position: 'relative',
    right: 'auto',
    width: '52%',
    marginLeft: '35%',
  },
  labelTitle: {
    marginTop: '0px',
    marginBottom: '5%',
    fontSize: '1.5em',
    lineHeight: '120%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
  },
  labelText: {
    marginBottom: '0px',
    fontFamily: ({ fontPair }) => fontPair?.majorFont.familyName,
    fontWeight: 400,
    fontSize: '1.1em',
    lineHeight: '125%',
  },
  labelTitleIcon: {
    marginBottom: '12%',
    fontSize: '1.7em',
  },
  labelTextIcon: {
    fontSize: '1.4em',
  },
  image: {
    height: '100%',
    width: '100%',
  },
}));
