import cn from 'classnames';
import { useStyles } from './SlideSecond.styles';
import { useRootStyles } from '../../common.styles';
import { SlideProps } from '../../types';

export const SlideSecond: React.FC<SlideProps> = ({ colorTheme, logoUrl, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[1].image;
  const backupImageSource: string = photos?.items[1].backupImage!;

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img src={imageSource ? imageSource : backupImageSource} loading="lazy" alt="" className={styles.image} />
        <div className={styles.titleTwoBlock}></div>
        <div className={styles.accentLine}></div>
        <h1 className={styles.titleTwo}>Image Title Slide</h1>
        <h2 className={styles.subtitleTwo}>Presenter Name / Date</h2>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerTitleTwo)}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
      </div>
    </>
  );
};

export default SlideSecond;
