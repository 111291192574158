import cn from 'classnames';
import { useStyles } from './SlideTwelth.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';

const SlideTwelth: React.FC<SlideProps> = ({ logoUrl, colorTheme, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <div className={styles.accentLineSocial} />
        <h1 className={styles.leftTitle}>Follow Us on Social Media</h1>
        <h2 className={styles.leftSubtitle}>Subtitle Text</h2>
        <div className={rootStyles.svgAccentOne}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1600 900"
            fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
          >
            <polygon points="1600,0 846.1,0 650.6,900 1600,900" />
          </svg>
        </div>
        <div className={rootStyles.svgDark}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
            <g>
              <polygon points="745.4,131 717.8,258 856.8,258 884.4,131" />
              <polygon points="606.7,769.4 745.7,769.4 773.3,642.3 634.3,642.3" />
              <polygon points="643.7,598.9 782.7,598.9 810.3,471.9 671.3,471.9" />
              <polygon points="680.7,428.5 819.7,428.5 847.3,301.4 708.3,301.4" />
            </g>
          </svg>
        </div>
        <div className={rootStyles.svgWhite}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900" fill="#fff">
            <g>
              <path
                d="M839.1,170.9L839.1,170.9c-2.8,1.2-5.8,2.1-9,2.5c3.2-1.9,5.7-5,6.9-8.6c-3,1.8-6.3,3.1-9.9,3.8
		c-2.8-3-6.9-4.9-11.4-4.9c-8.6,0-15.6,7-15.6,15.6c0,1.2,0.1,2.4,0.4,3.6c-13-0.6-24.4-6.9-32.1-16.3c-1.3,2.3-2.1,5-2.1,7.8
		c0,5.4,2.8,10.2,6.9,13c-2.6-0.1-5-0.8-7.1-2c0,0.1,0,0.1,0,0.2c0,7.6,5.4,13.9,12.5,15.3c-1.3,0.4-2.7,0.5-4.1,0.5
		c-1,0-2-0.1-2.9-0.3c2,6.2,7.7,10.7,14.6,10.8c-5.3,4.2-12.1,6.7-19.4,6.7c-1.3,0-2.5-0.1-3.7-0.2c6.9,4.4,15.1,7,23.9,7
		c28.7,0,44.4-23.8,44.4-44.4c0-0.7,0-1.3,0-2C834.3,176.8,837,174.1,839.1,170.9z"
              />
              <path
                d="M769.8,349.6v-8.1c0-3,2-3.7,3.4-3.7h8.7v-13.3l-12,0c-13.3,0-16.3,9.9-16.3,16.3v8.9H746v13.7h7.7v38.9h16.2
		v-38.9h10.9l1.4-13.7h0H769.8z"
              />
              <path
                d="M727,516.1c-10.5,0-19.1,8.5-19.1,19.1s8.5,19.1,19.1,19.1c10.5,0,19.1-8.5,19.1-19.1S737.5,516.1,727,516.1z
		 M727,547.4c-6.7,0-12.2-5.5-12.2-12.2c0-6.7,5.5-12.2,12.2-12.2c6.7,0,12.2,5.5,12.2,12.2C739.2,542,733.8,547.4,727,547.4z"
              />
              <path
                d="M744.9,511.8c-2.2,1-3.1,3.6-2.1,5.8c1,2.2,3.6,3.1,5.8,2.1c2.2-1,3.1-3.6,2.1-5.8
		C749.7,511.7,747.1,510.8,744.9,511.8z"
              />
              <path
                d="M758.1,504.4c-3.9-4-9.4-6.1-15.7-6.1h-30.8c-13,0-21.7,8.7-21.7,21.7v30.6c0,6.5,2.2,12,6.2,15.9
		c4,3.8,9.4,5.9,15.6,5.9h30.4c6.4,0,11.8-2,15.7-5.9c4-3.9,6.1-9.4,6.1-15.8V520C764,513.8,762,508.4,758.1,504.4L758.1,504.4z
		 M757.2,550.8c0,4.6-1.4,8.3-4,10.9c-2.6,2.6-6.4,3.9-11,3.9h-30.4c-4.4,0-8.2-1.4-10.8-4c-2.7-2.6-4.2-6.5-4.2-11.1V520
		c0-4.5,1.4-8.3,4-10.9c2.6-2.6,6.4-4,10.8-4h30.8c4.5,0,8.2,1.4,10.8,4c2.6,2.6,4,6.4,4,10.8V550.8z"
              />
              <path
                d="M720.2,680.2c-6-1.5-30.2-1.5-30.2-1.5s-24.2,0-30.2,1.6c-3.4,0.9-6,3.6-6.8,7c-1.1,6.2-1.6,12.4-1.6,18.6
		c0,6.3,0.5,12.6,1.6,18.7c0.9,3.3,3.5,5.8,6.8,6.8c6,1.6,30.2,1.6,30.2,1.6s24.2,0,30.2-1.6c3.4-0.9,6-3.6,6.8-7
		c1.1-6.1,1.7-12.3,1.6-18.5c0-6.3-0.5-12.6-1.6-18.7C726.2,683.8,723.6,681.1,720.2,680.2z M680.8,718.8v-25.7l22.6,12.9
		L680.8,718.8z"
              />
            </g>
          </svg>
        </div>
        <div className={cn(styles.socialTextBlockEdge, styles.socialTextBlockEdgeOne)}>
          <h1 className={styles.socialPlatformHeading}>Twitter</h1>
          <h2 className={styles.socialUrl}>https://twitter.com/name</h2>
        </div>
        <div className={cn(styles.socialTextBlockEdge, styles.socialTextBlockEdgeTwo)}>
          <h1 className={styles.socialPlatformHeading}>Facebook</h1>
          <h2 className={styles.socialUrl}>https://facebook.com/name</h2>
        </div>
        <div className={cn(styles.socialTextBlockEdge, styles.socialTextBlockEdgeThree)}>
          <h1 className={styles.socialPlatformHeading}>Instagram</h1>
          <h2 className={styles.socialUrl}>https://instagram.com/name</h2>
        </div>
        <div className={cn(styles.socialTextBlockEdge, styles.socialTextBlockEdgeFour)}>
          <h1 className={styles.socialPlatformHeading}>Youtube</h1>
          <h2 className={styles.socialUrl}>https://youtube.com/channel/name</h2>
        </div>
        <div className={rootStyles.slideNumberEdge}>
          <div className={rootStyles.edgeSlideNumberShape}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 104" display="block">
              <polygon fill="#fff" points="22.5,0 0,104 82.5,104 105,0 "></polygon>
            </svg>
          </div>
          <h1 className={cn(styles.number, rootStyles.numberEdge)}>12</h1>
        </div>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
      </div>
    </>
  );
};

export default SlideTwelth;
