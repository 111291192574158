import { createStore as createReduxStore, compose, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'connected-react-router';
import { rootReducer } from './root-reducer';
import { rootEpic } from './root-epic';

import type { History } from 'history';

export const createStore = (history: History) => {
  const epicMiddleware = createEpicMiddleware();
  const middlewares = [epicMiddleware, routerMiddleware(history)];
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createReduxStore(rootReducer(history), composeEnhancers(applyMiddleware(...middlewares)));
  epicMiddleware.run(rootEpic);

  return store;
};
