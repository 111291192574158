import type { Action } from 'modules/store/action';
import { Status } from 'modules/store/status';
import { AuthActionType } from './actions';
import type { AuthState } from './state';
import { UserAuthStatus } from './types';

export const authState: AuthState = {
  user: {
    authStatus: UserAuthStatus.UNKNOWN,
    data: null,
    claims: { stripeRole: undefined },
    error: null,
  },
  login: {
    status: Status.DEFAULT,
    error: null,
  },
  register: {
    status: Status.DEFAULT,
    error: null,
  },
  logout: {
    status: Status.DEFAULT,
    error: null,
  },
  delete: {
    status: Status.DEFAULT,
    error: null,
  },
  resetPassword: {
    status: Status.DEFAULT,
    error: null,
  },
  changeUserEmail: {
    status: Status.DEFAULT,
    error: null,
  },
  changeUserPassword: {
    status: Status.DEFAULT,
    error: null,
  },
};

export function authReducer(state = authState, action: Action): AuthState {
  switch (action.type) {
    /**
     * AuthObserverEmission
     */
    case AuthActionType.AuthObserverEmission:
      return {
        ...state,
        user: {
          ...state.user,
          data: action.payload.user,
          error: null,
        },
      };

    case AuthActionType.StartAuthObserverFailure:
      return {
        ...state,
        user: {
          ...state.user,
          error: action.payload.error,
        },
      };

    /**
     * SetUserAuthStatus
     */
    case AuthActionType.SetUserAuthStatus:
      return {
        ...state,
        user: {
          ...state.user,
          authStatus: action.payload.userAuthStatus,
        },
      };

    /**
     * SetUserClaims
     */
    case AuthActionType.SetUserClaims:
      return {
        ...state,
        user: {
          ...state.user,
          claims: action.payload.userClaims,
        },
      };

    /**
     * LoginUser
     */
    case AuthActionType.LoginUser:
      return {
        ...state,
        login: {
          status: Status.PENDING,
          error: null,
        },
      };

    case AuthActionType.LoginUserSuccess:
      return {
        ...state,
        login: {
          ...state.login,
          status: Status.SUCCESS,
        },
      };

    case AuthActionType.LoginUserFailure:
      return {
        ...state,
        login: {
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * RegisterUser
     */
    case AuthActionType.RegisterUser:
      return {
        ...state,
        register: {
          status: Status.PENDING,
          error: null,
        },
      };

    case AuthActionType.RegisterUserSuccess:
      return {
        ...state,
        user: {
          ...state.user,
          isNewUser: action.payload.isNewUser,
        },
        register: {
          ...state.register,
          status: Status.SUCCESS,
        },
      };

    case AuthActionType.RegisterUserFailure:
      return {
        ...state,
        register: {
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * LogoutUser
     */
    case AuthActionType.LogoutUser:
      return {
        ...state,
        logout: {
          status: Status.PENDING,
          error: null,
        },
      };

    case AuthActionType.LogoutUserSuccess:
      return {
        ...state,
        logout: {
          ...state.logout,
          status: Status.SUCCESS,
        },
      };

    case AuthActionType.LogoutUserFailure:
      return {
        ...state,
        logout: {
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * Delete User
     */
    case AuthActionType.DeleteUser:
      return {
        ...state,
        delete: {
          status: Status.PENDING,
          error: null,
        },
      };

    case AuthActionType.DeleteUserSuccess:
      return {
        ...state,
        delete: {
          ...state.delete,
          status: Status.SUCCESS,
        },
      };

    case AuthActionType.DeleteUserFailure:
      return {
        ...state,
        delete: {
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * ResetUserPassword
     */
    case AuthActionType.ResetUserPassword:
      return {
        ...state,
        resetPassword: {
          status: Status.PENDING,
          error: null,
        },
      };

    case AuthActionType.ResetUserPasswordSuccess:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          status: Status.SUCCESS,
        },
      };

    case AuthActionType.ResetUserPasswordFailure:
      return {
        ...state,
        resetPassword: {
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * Change User Email
     */
    case AuthActionType.ChangeUserEmail:
      return {
        ...state,
        changeUserEmail: {
          status: Status.PENDING,
          error: null,
        },
      };

    case AuthActionType.ChangeUserEmailSuccess:
      return {
        ...state,
        changeUserEmail: {
          ...state.changeUserEmail,
          status: Status.SUCCESS,
        },
      };

    case AuthActionType.ChangeUserEmailFailure:
      return {
        ...state,
        changeUserEmail: {
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    /**
     * Change User Password
     */
    case AuthActionType.ChangeUserPassword:
      return {
        ...state,
        changeUserPassword: {
          status: Status.PENDING,
          error: null,
        },
      };

    case AuthActionType.ChangeUserPasswordSuccess:
      return {
        ...state,
        changeUserPassword: {
          ...state.changeUserPassword,
          status: Status.SUCCESS,
        },
      };

    case AuthActionType.ChangeUserPasswordFailure:
      return {
        ...state,
        changeUserPassword: {
          status: Status.FAILURE,
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
}
