import { makeStyles, Theme } from '@material-ui/core/styles';

interface NavigationLinkStyles {
  active: boolean;
}

export const useStyles = makeStyles<Theme, NavigationLinkStyles>(theme => ({
  link: {
    fontWeight: 500,
    color: theme.palette.utility.black1,
    textDecoration: 'none',

    position: 'relative',
    paddingBottom: theme.spacing(0.5),

    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 2,
      backgroundColor: theme.palette.primary.main,
      width: ({ active }) => (active ? '100%' : 0),

      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.standard,
      }),
    },

    '&:hover::after': {
      width: '100%',
    },
  },
}));
