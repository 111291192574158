import type { Action } from 'modules/store/action';
import { Status } from 'modules/store/status';
import { SAMPLE_PREVIEW_SIZE_LIMIT } from './const';
import { PresentationActionType } from './actions';
import type { PresentationState } from './state';
import type { PreviewImage } from './types';

const INITIAL_PREVIEW = Array.from<PreviewImage>({ length: SAMPLE_PREVIEW_SIZE_LIMIT }).fill({
  source: '',
  index: -1,
});

const mergeStateWithIncoming = (
  statePreviewImages: Array<PreviewImage | null>,
  incomingPreviewImages: PreviewImage[]
) => {
  const previewImages: PreviewImage[] = JSON.parse(JSON.stringify(statePreviewImages));
  incomingPreviewImages.forEach(previewImage => (previewImages[previewImage.index - 1] = previewImage));

  return previewImages;
};

export const presentationState: PresentationState = {
  previewImages: {
    data: INITIAL_PREVIEW,
    status: Status.DEFAULT,
  },

  layout: {
    data: null,
    prevData: null,
    status: Status.DEFAULT,
  },
  logo: {
    data: null,
    prevData: null,
    status: Status.DEFAULT,
  },
  colorTheme: {
    data: null,
    prevData: null,
    status: Status.DEFAULT,
  },
  fontTheme: {
    data: null,
    prevData: null,
    status: Status.DEFAULT,
  },
  photoPack: {
    data: null,
    prevData: null,
    status: Status.DEFAULT,
  },
  downloadURL: {
    data: null,
    downloadQueued: false,
    status: Status.DEFAULT,
  },
};

export function presentationReducer(state = presentationState, action: Action): PresentationState {
  switch (action.type) {
    /**
     * PresentationObserverEmission
     */
    case PresentationActionType.PresentationObserverEmission:
      return {
        ...state,
        layout: {
          data: action.payload.presentationData.layout,
          prevData: state.layout.prevData ?? action.payload.presentationData.layout,
          status: Status.SUCCESS,
        },
        logo: {
          data: action.payload.presentationData.logo,
          prevData: state.logo.prevData ?? action.payload.presentationData.logo,
          status: Status.SUCCESS,
        },
        colorTheme: {
          data: action.payload.presentationData.colorTheme,
          prevData: state.colorTheme.prevData ?? action.payload.presentationData.colorTheme,
          status: Status.SUCCESS,
        },
        fontTheme: {
          data: action.payload.presentationData.fontTheme,
          prevData: state.fontTheme.prevData ?? action.payload.presentationData.fontTheme,
          status: Status.SUCCESS,
        },
        photoPack: {
          data: action.payload.presentationData.photoPackId,
          prevData: state.photoPack.prevData ?? action.payload.presentationData.photoPackId,
          status: Status.SUCCESS,
        },
      };

    /**
     * SetPresentationDownloadURL
     */
    case PresentationActionType.SetPresentationDownloadURL:
      return {
        ...state,
        downloadURL: {
          data: action.payload.downloadURL,
          downloadQueued: false,
          status: Status.SUCCESS,
        },
      };

    /**
     * StartPreviewObserver
     */
    case PresentationActionType.StartPreviewObserver:
      return {
        ...state,
        previewImages: {
          ...state.previewImages,
          status: Status.PENDING,
        },
      };

    case PresentationActionType.StartPreviewObserverFailure:
      return {
        ...state,
        previewImages: {
          ...state.previewImages,
          status: Status.FAILURE,
        },
      };

    /**
     * StopPreviewObserver
     */
    case PresentationActionType.StopPreviewObserver:
      return {
        ...state,
        previewImages: {
          ...state.previewImages,
          status: Status.DEFAULT,
        },
      };

    /**
     * PreviewObserverEmission
     */
    case PresentationActionType.PreviewObserverEmission:
      return {
        ...state,
        previewImages: {
          ...state.previewImages,
          data: mergeStateWithIncoming(state.previewImages.data, action.payload.previewImages),
          status: Status.SUCCESS,
        },
      };

    case PresentationActionType.PreviewObserverEmissionFailure:
      return {
        ...state,
        previewImages: {
          ...state.previewImages,
          status: Status.FAILURE,
        },
      };

    /**
     * SavePresentationLayout
     */
    case PresentationActionType.SavePresentationLayout:
      return {
        ...state,
        layout: {
          data: action.payload.layoutId,
          prevData: state.layout.data,
          status: Status.PENDING,
        },
        previewImages: {
          data: state.previewImages.data.slice(0, SAMPLE_PREVIEW_SIZE_LIMIT),
          status: Status.PENDING,
        },
      };

    case PresentationActionType.SavePresentationLayoutSuccess:
      return {
        ...state,
        layout: {
          ...state.layout,
          status: Status.SUCCESS,
        },
      };

    case PresentationActionType.SavePresentationLayoutFailure:
      return {
        ...state,
        layout: {
          ...state.layout,
          data: state.layout.prevData,
          status: Status.FAILURE,
        },
        previewImages: {
          ...state.previewImages,
          status: Status.FAILURE,
        },
      };

    /**
     * SavePresentationLogo
     */
    case PresentationActionType.SavePresentationLogo:
      return {
        ...state,
        logo: {
          data: action.payload.logo,
          prevData: state.logo.data,
          status: Status.PENDING,
        },
        previewImages: {
          data: state.previewImages.data.slice(0, SAMPLE_PREVIEW_SIZE_LIMIT),
          status: Status.PENDING,
        },
      };

    case PresentationActionType.SavePresentationLogoSuccess:
      return {
        ...state,
        logo: {
          ...state.logo,
          status: Status.SUCCESS,
        },
      };

    case PresentationActionType.SavePresentationLogoFailure:
      return {
        ...state,
        logo: {
          ...state.logo,
          data: state.logo.prevData,
          status: Status.FAILURE,
        },
        previewImages: {
          ...state.previewImages,
          status: Status.FAILURE,
        },
      };

    /**
     * SavePresentationColors
     */
    case PresentationActionType.SavePresentationColors:
      return {
        ...state,
        colorTheme: {
          data: action.payload.colorTheme,
          prevData: state.colorTheme.data,
          status: Status.PENDING,
        },
        previewImages: {
          data: state.previewImages.data.slice(0, SAMPLE_PREVIEW_SIZE_LIMIT),
          status: Status.PENDING,
        },
      };

    case PresentationActionType.SavePresentationColorsSuccess:
      return {
        ...state,
        colorTheme: {
          ...state.colorTheme,
          status: Status.SUCCESS,
        },
      };

    case PresentationActionType.SavePresentationColorsFailure:
      return {
        ...state,
        colorTheme: {
          ...state.colorTheme,
          data: state.colorTheme.prevData,
          status: Status.FAILURE,
        },
        previewImages: {
          ...state.previewImages,
          status: Status.FAILURE,
        },
      };

    /**
     * SavePresentationFontTheme
     */
    case PresentationActionType.SavePresentationFontTheme:
      return {
        ...state,
        fontTheme: {
          data: action.payload.fontThemeId,
          prevData: state.fontTheme.data,
          status: Status.PENDING,
        },
        previewImages: {
          data: state.previewImages.data.slice(0, SAMPLE_PREVIEW_SIZE_LIMIT),
          status: Status.PENDING,
        },
      };

    case PresentationActionType.SavePresentationFontThemeSuccess:
      return {
        ...state,
        fontTheme: {
          ...state.fontTheme,
          status: Status.SUCCESS,
        },
      };

    case PresentationActionType.SavePresentationFontThemeFailure:
      return {
        ...state,
        fontTheme: {
          ...state.fontTheme,
          data: state.fontTheme.prevData,
          status: Status.FAILURE,
        },
        previewImages: {
          ...state.previewImages,
          status: Status.FAILURE,
        },
      };

    /**
     * SavePresentationPhotoPack
     */
    case PresentationActionType.SavePresentationPhotoPack:
      return {
        ...state,
        photoPack: {
          data: action.payload.photoPackId,
          prevData: state.photoPack.data,
          status: Status.PENDING,
        },
        previewImages: {
          data: state.previewImages.data.slice(0, SAMPLE_PREVIEW_SIZE_LIMIT),
          status: Status.PENDING,
        },
      };

    case PresentationActionType.SavePresentationPhotoPackSuccess:
      return {
        ...state,
        photoPack: {
          ...state.photoPack,
          status: Status.SUCCESS,
        },
      };

    case PresentationActionType.SavePresentationPhotoPackFailure:
      return {
        ...state,
        photoPack: {
          ...state.photoPack,
          data: state.photoPack.prevData,
          status: Status.FAILURE,
        },
        previewImages: {
          ...state.previewImages,
          status: Status.FAILURE,
        },
      };

    /**
     * GeneratePresentationFullPreview
     */
    case PresentationActionType.GeneratePresentationFullPreview:
      return {
        ...state,
        previewImages: {
          ...state.previewImages,
          status: Status.PENDING,
        },
      };

    /**
     * GeneratePresentationDownloadLink
     */
    case PresentationActionType.GeneratePresentationDownloadLink:
      return {
        ...state,
        downloadURL: {
          ...state.downloadURL,
          downloadQueued: true,
          status: Status.PENDING,
        },
      };

    case PresentationActionType.GeneratePresentationDownloadLinkFailure:
      return {
        ...state,
        downloadURL: {
          ...state.downloadURL,
          downloadQueued: false,
          status: Status.FAILURE,
        },
      };

    /**
     * ResetPresentationDownloadLink
     */
    case PresentationActionType.ResetPresentationDownloadLink:
      return {
        ...state,
        downloadURL: {
          data: null,
          downloadQueued: false,
          status: Status.DEFAULT,
        },
      };

    /**
     * ResetPresentationState
     */
    case PresentationActionType.ResetPresentationState:
      return presentationState;

    default:
      return state;
  }
}
