import { useStyles } from './SlideFirst.styles';
import { useRootStyles } from '../../common.styles';
import { SlideProps } from '../../types';
import cn from 'classnames';

export const SlideFirst: React.FC<SlideProps> = ({ colorTheme, logoUrl, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[0].image;
  const backupImageSource: string = photos?.items[0].backupImage!;
  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img
          src={imageSource ? imageSource : backupImageSource}
          loading="lazy"
          alt=""
          className={rootStyles.fullBackgroundImage}
        />

        <div className={cn(rootStyles.svgAccentOne, rootStyles.opacitySeventy)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
            <polygon
              fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              points="1246.8,450 0,450 0,853.1 1159.2,853.1 "
            ></polygon>
          </svg>
        </div>

        <h1 className={styles.titleOneEdge}>Image Title Slide</h1>
        <h2 className={styles.subtitleOneEdge}>Presenter Name / Date</h2>
        {logoUrl && (
          <div className={(rootStyles.logoContainer, rootStyles.logoContainerSvgOne)}>
            <div className={rootStyles.svgWhite}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
                <polygon fill="#fff" points="0,268 0,450 452.5,450 492,268"></polygon>
              </svg>
            </div>
            <div className={styles.logoBoxEdgeTitleOne}>
              <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SlideFirst;
