import { SlideStyleProps } from './../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, SlideStyleProps>(theme => ({
  placment: {
    position: 'absolute',
    left: 'auto',
    top: 'auto',
    right: '0%',
    bottom: '8%',
    display: 'flex',
    width: '8%',
    height: 'auto',
    paddingTop: '2%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  svgNumber: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    height: '7%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
  },
  number: {
    zIndex: 10,
    fontSize: '1.3em',
  },
}));
