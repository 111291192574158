import React from 'react';
import cn from 'classnames';
import { Typography } from '@material-ui/core';
import { useStyles } from './CreatorTitle.styles';
import { Tooltip } from 'components/Tooltip';
import { InfoIcon } from 'assets/icons';

interface CreatorTitleProps {
  className?: string;
  title: string;
  tooltipTitle?: string;
}

export const CreatorTitle = ({ className, title, tooltipTitle }: CreatorTitleProps) => {
  const styles = useStyles();

  return (
    <div className={cn(styles.container, className)}>
      <Typography className={styles.title} component="span">
        {title}
        {tooltipTitle && (
          <Tooltip title={tooltipTitle}>
            <InfoIcon className={styles.infoIcon} />
          </Tooltip>
        )}
      </Typography>
    </div>
  );
};
