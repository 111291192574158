import { makeStyles, Theme } from '@material-ui/core/styles';

type StyleProps = {
  isSelected: boolean;
};

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    borderRadius: '20px',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  bestValueContainer: {
    height: '40px',
    backgroundColor: '#17BEFC',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bestValue: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 800,
    color: theme.palette.common.white,
  },
  pricingDetailsContainer: {
    backgroundColor: ({ isSelected }) => (isSelected ? theme.palette.primary.main : theme.palette.utility.gray1),
    padding: theme.spacing(1.75, 0.75, 0.75),

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    transition: theme.transitions.create('background', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.short,
    }),
  },
  period: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 800,
    color: ({ isSelected }) => (isSelected ? theme.palette.common.white : theme.palette.primary.main),
    textTransform: 'uppercase',
    marginBottom: theme.spacing(0.75),
  },
  perk: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    color: ({ isSelected }) => (isSelected ? theme.palette.common.white : theme.palette.utility.black1),
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  price: {
    fontSize: theme.typography.pxToRem(40),
    fontWeight: 800,
    lineHeight: '60px',
    letterSpacing: '-1.6px',
    color: ({ isSelected }) => (isSelected ? theme.palette.common.white : theme.palette.primary.main),
  },
  fee: {
    fontSize: theme.typography.pxToRem(14),
    color: ({ isSelected }) => (isSelected ? theme.palette.common.white : '#93939E'),
    marginBottom: theme.spacing(2.5),
  },
  selectContainer: {
    width: '100%',
    height: '28px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '14px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  select: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: ({ isSelected }) => (isSelected ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular),
    color: ({ isSelected }) => (isSelected ? theme.palette.primary.main : theme.palette.utility.black1),
  },
}));
