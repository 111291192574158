import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  submitButton: {
    padding: theme.spacing(1, 5),
    marginRight: theme.spacing(1),
  },
  loading: {
    verticalAlign: 'middle',
  },
  buttonsContainer: {
    marginBottom: theme.spacing(2),
  },
}));
