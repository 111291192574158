import type firebase from 'firebase';
import type { ColorScheme } from 'modules/configuration/types';

export enum PresentationError {
  LayoutChangeFailure = 'LayoutChangeFailure',
  LogoChangeFailure = 'LogoChangeFailure',
  ColorChangeFailure = 'ColorChangeFailure',
  FontChangeFailure = 'FontChangeFailure',
  PhotopackChangeFailure = 'PhotopackChangeFailure',
  PreviewFailure = 'PreviewFailure',
  GeneratePackageFailure = 'GeneratePackageFailure',
}

export type PresentationDocumentData = Partial<{
  layout: firebase.firestore.DocumentReference | null;
  logo: string | null;
  colorTheme: ColorScheme | null;
  fontTheme: {
    fontPair: firebase.firestore.DocumentReference | null;
    majorFont: firebase.firestore.DocumentReference | null;
    minorFont: firebase.firestore.DocumentReference | null;
  } | null;
  photoPackId: string | null;
  name: string | null;
  generationDetails: { fileUrl: string } | null;
  error: PresentationError | null;
  createdAt: firebase.firestore.Timestamp;
  modifiedAt: firebase.firestore.Timestamp;
}>;

export type PresentationDto = Omit<PresentationDocumentData, 'layout' | 'fontTheme'> & {
  layout?: string | null;
  fontTheme?: string | null;
};

export type PreviewImageDocumentData = {
  createdDate: firebase.firestore.Timestamp;
  index: number;
  url: string;
};

export type PreviewImage = {
  source: string;
  index: number;
};
