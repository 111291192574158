import { i18nClient } from 'services/translations/i18n';
import type { ColorScheme } from 'modules/configuration/types';

type CustomizeSchemeValues = {
  id: number;
  title: string;
  color: string;
  width: number;
};

export const mergeColorSchemeWithDefault = (
  colorScheme: ColorScheme,
  defaultScheme: CustomizeSchemeValues[]
): CustomizeSchemeValues[] => {
  return defaultScheme.map(schemeValue => {
    if (schemeValue.id === 0) {
      return { ...schemeValue, color: colorScheme.textColor };
    }
    if (schemeValue.id === 1) {
      return { ...schemeValue, color: colorScheme.accent1Color };
    }
    if (schemeValue.id === 2) {
      return { ...schemeValue, color: colorScheme.accent2Color };
    }
    if (schemeValue.id === 3) {
      return { ...schemeValue, color: colorScheme.accent3Color };
    }
    if (schemeValue.id === 4) {
      return { ...schemeValue, color: colorScheme.accent4Color };
    }
    if (schemeValue.id === 5) {
      return { ...schemeValue, color: colorScheme.accent5Color };
    }
    if (schemeValue.id === 6) {
      return { ...schemeValue, color: colorScheme.accent6Color };
    }

    return schemeValue;
  });
};

export const defaultSchemeValues: CustomizeSchemeValues[] = [
  { id: 0, title: i18nClient.t('creatorColorScheme.text'), color: '#000000', width: 194 },
  {
    id: 1,
    title: i18nClient.t('creatorColorScheme.accent1'),
    color: '#3A5DF6',
    width: 194,
  },
  {
    id: 2,
    title: i18nClient.t('creatorColorScheme.accent2'),
    color: '#17BEFC',
    width: 161,
  },
  {
    id: 3,
    title: i18nClient.t('creatorColorScheme.accent3'),
    color: '#1293F9',
    width: 128,
  },
  {
    id: 4,
    title: i18nClient.t('creatorColorScheme.accent4'),
    color: '#4B30E9',
    width: 96,
  },
  {
    id: 5,
    title: i18nClient.t('creatorColorScheme.accent5'),
    color: '#7474F7',
    width: 63,
  },
  {
    id: 6,
    title: i18nClient.t('creatorColorScheme.accent6'),
    color: '#B1B1FF',
    width: 30,
  },
];
