import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(5),
  },
  deleteLink: {
    color: 'red',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
