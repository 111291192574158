import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  subscriptionLoader: {
    marginLeft: theme.spacing(0.75),
  },
  accountTitle: {
    fontSize: theme.typography.pxToRem(48),
    color: theme.palette.primary.main,
    letterSpacing: -1.36,
  },
}));
