import { useStyles } from './SlideNine.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';
import cn from 'classnames';

export const SlideNine: React.FC<SlideProps> = ({ colorTheme, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  const photos = photoPack;
  const imageSource: string | null = photos?.items[18].image;
  const backupImageSource: string = photos?.items[18].backupImage!;

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img src={imageSource ? imageSource : backupImageSource} loading="lazy" alt="" className={styles.leftImage} />
        <div className={styles.quoteIcon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10 8.1"
            fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
          >
            <g id="a"></g>
            <g id="b">
              <g id="c">
                <path
                  d="M0.6,7.1C0.2,6.5,0,5.8,0,5c0-1.1,0.3-2,0.8-2.9C1.4,1.2,2.2,0.5,3.3,0l0.3,0.6C2.9,0.9,2.4,1.3,1.9,1.9S1.2,3.1,1.2,3.7
			c0,0.3,0,0.5,0.1,0.7C1.6,4.1,2,4,2.5,4C3,4,3.5,4.2,3.9,4.6c0.4,0.4,0.6,0.9,0.6,1.5c0,0.4-0.1,0.7-0.3,1.1
			C4,7.4,3.8,7.7,3.5,7.9C3.2,8,2.8,8.1,2.5,8.1C1.6,8.1,1,7.8,0.6,7.1L0.6,7.1z M6,7.1C5.7,6.5,5.5,5.8,5.5,5c0-1.1,0.3-2,0.8-2.9
			C6.9,1.2,7.7,0.5,8.8,0l0.3,0.6C8.4,0.9,7.9,1.3,7.4,1.9S6.6,3.1,6.6,3.7c0,0.3,0,0.5,0.1,0.7C7.1,4.1,7.5,4,7.9,4
			C8.5,4,9,4.2,9.4,4.6C9.8,4.9,10,5.4,10,6.1c0,0.6-0.2,1.1-0.6,1.5C9,7.9,8.5,8.1,7.9,8.1C7.1,8.1,6.5,7.8,6,7.1L6,7.1z"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <p className={styles.quoteText}>
          A hundred times every day I remind myself that my inner and outer life are based on the labors of other men,
          living and dead, and that I must exert myself in order to give in the same measure as I have received and am
          still receiving.
        </p>
        <h1 className={styles.quoteAuthor}>Albert Einstein</h1>
        <div className={rootStyles.slideNumber}>
          <h1 className={rootStyles.number}>9</h1>
        </div>
      </div>
    </>
  );
};

export default SlideNine;
