import React from 'react';
import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip';
import { TooltipTitle } from './components';
import { useStyles } from './Tooltip.styles';

interface TooltipProps extends MuiTooltipProps {
  title: string;
  titleClassName?: string;
  disabled?: boolean;
}

export const Tooltip: React.FC<TooltipProps> = ({ children, title, titleClassName, disabled, ...tooltipProps }) => {
  const styles = useStyles();

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <MuiTooltip
      classes={{ popper: styles.popper, tooltip: styles.tooltip }}
      title={<TooltipTitle className={titleClassName} title={title} />}
      {...tooltipProps}
    >
      {children}
    </MuiTooltip>
  );
};
