import { from, of, forkJoin } from 'rxjs';
import { switchMap, map, mergeMap, filter, endWith, catchError, withLatestFrom, defaultIfEmpty } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { fromTask, getDownloadURL } from 'rxfire/storage';
import { ofType } from 'modules/store/of-type';
import { Action } from 'modules/store/action';
import { Status } from 'modules/store/status';
import { SharedAction } from 'modules/shared/actions';
import { i18nClient } from 'services/translations';
import { firebaseAuth, firebaseStorage } from 'services/firebase';
import { StorageAction, StorageActionType } from './actions';

import type { RootState } from 'services/store/root-reducer';
import type firebase from 'firebase';
import { ImageItem } from './types';

const createUserLogoStoragePathDownload = (userId: string): string => {
  return `users/${userId}/logos`;
};

const createUserLogoStoragePathUpload = (userId: string, fileName?: string): string => {
  return `users/${userId}/logos/${fileName}`;
};

const createUploadTask = (file: File): firebase.storage.UploadTask => {
  return firebaseStorage.ref(createUserLogoStoragePathUpload(firebaseAuth.currentUser!.uid, file.name)).put(file);
};

const uploadFileEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(StorageActionType.UploadFile),
    switchMap(({ payload }) => {
      const uploadTask = createUploadTask(payload.file);

      return fromTask(uploadTask).pipe(
        map(snapshot => {
          const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

          if (progress === 100) {
            return StorageAction.uploadFileSuccess(snapshot.ref);
          }

          return StorageAction.uploadFileProgress(progress);
        }),
        catchError(() =>
          of(
            StorageAction.uploadFileFailure(),
            SharedAction.notifyUser(i18nClient.t('creatorLogoUpload.uploadFileFailureMessage'), 'error')
          )
        ),
        endWith(StorageAction.uploadFileGetSource())
      );
    })
  );

const uploadFileSuccessEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(StorageActionType.UploadFileGetSource),
    withLatestFrom(state$),
    filter(([, { storage }]) => !!storage.uploadFile.reference && storage.uploadFile.status === Status.SUCCESS),
    switchMap(([, { storage }]) =>
      getDownloadURL(storage.uploadFile.reference!).pipe(
        mergeMap(source => of(StorageAction.uploadFileSetSource(source), StorageAction.setPreviewImageSource(source)))
      )
    )
  );

const fetchSavedImagesEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(StorageActionType.FetchSavedImages),
    switchMap(() =>
      from(firebaseStorage.ref(createUserLogoStoragePathDownload(firebaseAuth.currentUser!.uid)).listAll()).pipe(
        mergeMap(({ items }) => {
          const savedImages$ = items.flatMap(savedImage => [
            forkJoin([savedImage.getDownloadURL()]).pipe(
              map(imageUrl => ({ source: imageUrl[0], name: savedImage.name }))
            ),
          ]);

          return forkJoin(savedImages$).pipe(
            defaultIfEmpty([] as ImageItem[]),
            map(items => StorageAction.fetchSavedImagesSuccess(items)),
            catchError(error =>
              of(
                StorageAction.fetchSavedImagesFailure(error),
                SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
              )
            )
          );
        }),
        catchError(error =>
          of(
            StorageAction.fetchSavedImagesFailure(error),
            SharedAction.notifyUser(i18nClient.t('general.failureMessage'), 'error')
          )
        )
      )
    )
  );

export const storageEpics = combineEpics(uploadFileEpic, uploadFileSuccessEpic, fetchSavedImagesEpic);
