import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    width: 200,
    height: 120,
    overflow: 'hidden',
    position: 'relative',
    borderRadius: theme.shape.borderRadius * 2,
    willChange: 'transform',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),

    '&:hover': {
      transform: 'scale(1.1)',
    },

    '&:hover $thumbnail': {
      transform: 'scale(1.05)',
    },
  },
  thumbnail: {
    willChange: 'transform',
    objectFit: 'cover',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  activeContainer: {
    willChange: 'transform',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    willChange: 'transform',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    opacity: 0.76,
  },
  stripe: {
    willChange: 'transform',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 155,
    height: 50,
    borderTopRightRadius: theme.shape.borderRadius * 2,
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  name: {
    willChange: 'transform',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
  },
}));
