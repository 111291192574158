import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, SlideStyleProps>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius * 2,
  },
  leftImage: {
    position: 'absolute',
    left: 0,
    right: 'auto',
    top: 0,
    bottom: 'auto',
    width: '40%',
    height: '100%',
    objectFit: 'cover',
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
  },
  quoteIcon: {
    position: 'absolute',
    left: '46%',
    top: '11%',
    right: 'auto',
    bottom: 'auto',
    width: '5%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : '#3a5df6'),
  },
  quoteText: {
    position: 'absolute',
    left: '46%',
    top: '20%',
    right: 'auto',
    bottom: 'auto',
    width: '47%',
    fontSize: '2.7em',
    lineHeight: '123%',
  },
  quoteAuthor: {
    position: 'absolute',
    left: '46%',
    top: '78%',
    right: 'auto',
    bottom: 'auto',
    marginTop: 0,
    marginBottom: 0,
    width: '47%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : '#3a5df6'),
    fontSize: '2.8em',
    lineHeight: '100%',
  },
}));
