import React from 'react';
import { useTranslation } from 'react-i18next';
import { Fade, Typography } from '@material-ui/core';
import { ColorBar } from 'components/ColorBar';
import { MediaIcon } from 'assets/icons';
import { usePresentationManagement } from 'modules/presentation/hooks';
import type { CuratedColorScheme, UserColorScheme, ColorScheme } from 'modules/configuration/types';
import { useStyles } from './ColorSchemes.styles';

type ColorSchemesProps =
  | {
      mode: 'curated';
      colors: CuratedColorScheme[];
      presentationId: string;
    }
  | {
      mode: 'user';
      colors: UserColorScheme[];
      presentationId: string;
    };

export const ColorSchemes: React.FC<ColorSchemesProps> = props => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { presentationColorsId, savePresentationColors } = usePresentationManagement();

  const colorSchemes = [...props.colors];

  const handleSaveColorScheme = (colorScheme: ColorScheme): void => {
    savePresentationColors(colorScheme, props.presentationId);
  };

  return (
    <Fade in>
      {colorSchemes.length ? (
        <div>
          {colorSchemes.map(colorItem => (
            <ColorBar
              key={colorItem.internalId}
              colorScheme={colorItem}
              active={presentationColorsId ? presentationColorsId === colorItem.internalId : colorItem.order === 0}
              onClick={() => handleSaveColorScheme(colorItem)}
            />
          ))}
        </div>
      ) : (
        <div className={styles.emptyContainer}>
          <MediaIcon className={styles.emptyIcon} />
          <Typography className={styles.emptyMessage}>{t('creatorColorScheme.emptyShemesMessage')}</Typography>
        </div>
      )}
    </Fade>
  );
};
