import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { authReducer } from 'modules/auth/reducer';
import { storageReducer } from 'modules/storage/reducer';
import { configurationReducer } from 'modules/configuration/reducers';
import { presentationReducer } from 'modules/presentation/reducer';
import { sharedReducer } from 'modules/shared/reducer';

import type { History } from 'history';
import type { Action } from 'modules/store/action';
import { AuthActionType } from 'modules/auth/actions';

export const rootReducer = (history: History) => (state: any, action: Action) => {
  if (action.type === AuthActionType.LogoutUser) {
    state = undefined;
  }

  return combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    storage: storageReducer,
    configuration: configurationReducer,
    presentation: presentationReducer,
    shared: sharedReducer,
  })(state, action);
};

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;
