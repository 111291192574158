import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, Partial<SlideStyleProps>>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    justifyContent: 'center',
  },
  transitionTitleEdge: {
    position: 'absolute',
    left: '14.5%',
    top: '34%',
    right: 'auto',
    bottom: 'auto',
    width: '71%',
    height: '32%',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '6.2em',
    lineHeight: '110%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoBoxEdgeThree: {
    position: 'absolute',
    left: '41.7%',
    top: 'auto',
    right: 'auto',
    bottom: '18%',
    zIndex: 100,
    width: '16.7%',
    height: '11%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
