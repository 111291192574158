import { TwitterIcon, FacebookIcon, InstaIcon, YoutubeIcon } from './icons';

export const socialIcons = [
  {
    name: 'Twitter',
    src: TwitterIcon,
    url: 'https://twitter.com/name',
  },
  {
    name: 'Facebook',
    src: FacebookIcon,
    url: 'https://facebook.com/name',
  },
  {
    name: 'Instagram',
    src: InstaIcon,
    url: 'https://instagram.com/name',
  },
  {
    name: 'Youtube',
    src: YoutubeIcon,
    url: 'https://youtube.com/channel/name',
  },
];
