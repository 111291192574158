import type { ColorScheme } from 'modules/configuration/types';
import { useStyles } from './ColorBar.styles';

interface ColorBarProps {
  colorScheme: ColorScheme;
  active: boolean;
  onClick: () => void;
}

export const ColorBar: React.FC<ColorBarProps> = ({ colorScheme, active, onClick }) => {
  const styles = useStyles({
    firstAccent: colorScheme.accent1Color,
    secondAccent: colorScheme.accent2Color,
    thirdAccent: colorScheme.accent3Color,
    fourthAccent: colorScheme.accent4Color,
    fifthAccent: colorScheme.accent5Color,
    sixthAccent: colorScheme.accent6Color,
    active,
  });

  return (
    <div className={styles.barContainer} onClick={onClick}>
      <div className={styles.firstAccent} />
      <div className={styles.secondAccent} />
      <div className={styles.thirdAccent} />
      <div className={styles.fourthAccent} />
      <div className={styles.fifthAccent} />
      <div className={styles.sixthAccent} />
    </div>
  );
};
