import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './ConsultPerk.styles';

interface ConsultPerkProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

export const ConsultPerk: React.FC<ConsultPerkProps> = ({ icon, title, description }) => {
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <Box className={styles.iconContainer}>{icon}</Box>

      <Typography className={styles.description}>
        <span className={styles.title}>{title}</span> {description}
      </Typography>
    </Box>
  );
};
