import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const UPLOAD_AREA_WIDTH = 480;

export const useStyles = makeStyles(theme => ({
  uploadArea: {
    width: UPLOAD_AREA_WIDTH,
    height: 270,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: '#8787A0',
    borderRadius: theme.shape.borderRadius * 2,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    transition: theme.transitions.create(['background', 'transform'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  uploadAreaActive: {
    background: fade(theme.palette.primary.main, 0.1),
    transform: 'scale(0.98)',
  },
  uploadAreaErrored: {
    borderColor: theme.palette.error.main,
  },
  uploadAreaElements: {
    pointerEvents: 'none',
  },

  uploadIcon: {
    stroke: theme.palette.primary.main,
  },
  uploadIconStepUpload: {
    marginBottom: theme.spacing(2.25),
    width: 40,
    height: 33,
  },
  uploadIconStepUploading: {
    marginBottom: theme.spacing(2.75),
    width: 53,
    height: 53,
  },
  resetIcon: {
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),
  },

  uploadPrimaryMessage: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  uploadSecondaryMessage: {
    color: theme.palette.utility.black2,
    fontSize: theme.typography.pxToRem(14),
  },
  uploadErrorMessage: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightSemiBold,
    marginTop: theme.spacing(2),
  },

  uploadButton: {
    marginTop: theme.spacing(3),
  },
  resetButton: {
    marginTop: theme.spacing(4),

    '&:hover svg': {
      transform: 'rotate(-180deg)',
    },
  },

  uploadProgressBarRoot: {
    width: 304,
    height: 18,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(1.375),
  },

  uploadPreview: {
    width: 435,
    height: 215,
    boxShadow: `0px 5px 16px ${theme.palette.utility.grayscale[300]}`,
    borderRadius: theme.shape.borderRadius * 2,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  uploadPreviewImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },

  uploadHint: {
    width: UPLOAD_AREA_WIDTH,
    textAlign: 'center',
    marginTop: theme.spacing(3.125),
    color: theme.palette.utility.grayscale[600],
    fontSize: theme.typography.pxToRem(14),
  },
}));
