import React from 'react';
import cn from 'classnames';
import { Typography, CircularProgress } from '@material-ui/core';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { useStyles } from './Button.styles';

interface ButtonProps extends MuiButtonProps {
  textClassName?: string;
  loading?: boolean;
  uploadMode?: boolean;
  acceptFormats?: string[];
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  textClassName,
  loading = false,
  uploadMode = false,
  acceptFormats,
  onInputChange,
  ...buttonProps
}) => {
  const styles = useStyles();
  const accept = acceptFormats && acceptFormats.join(', ');

  return (
    <MuiButton
      className={cn(styles.button, className)}
      variant="outlined"
      component={uploadMode ? 'label' : 'button'}
      disabled={loading}
      disableElevation
      {...buttonProps}
    >
      {loading ? (
        <CircularProgress className={styles.progress} size={23} />
      ) : (
        <Typography className={cn(styles.buttonText, textClassName)}>{children}</Typography>
      )}
      {uploadMode && <input type="file" accept={accept} onChange={onInputChange} multiple={false} hidden />}
    </MuiButton>
  );
};
