import React from 'react';
import type { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Container, Fade, Typography, TextField, Link } from '@material-ui/core';
import { Button } from 'components';
import { useAuthManagement } from 'modules/auth/hooks';
import { i18nClient } from 'services/translations';
import { useStyles } from './RegisterContainer.styles';

const registerFormSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .trim()
    .required()
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
      'string.email': i18nClient.t('auth.emailFormatErrorMessage'),
    }),
  password: Joi.string()
    .trim()
    .min(6)
    .required()
    .messages({
      'any.required': i18nClient.t('general.requiredFieldMessage'),
      'string.empty': i18nClient.t('general.requiredFieldMessage'),
      'string.min': i18nClient.t('register.minLenghtPasswordErrorMessage'),
    }),
}).required();

interface RegisterFormData {
  email: string;
  password: string;
}

interface RegisterContainerProps extends RouteComponentProps {}

export const RegisterContainer: React.FC<RegisterContainerProps> = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { isLoading, registerUser } = useAuthManagement();
  const { register, errors, handleSubmit } = useForm<RegisterFormData>({
    mode: 'onTouched',
    resolver: joiResolver(registerFormSchema),
  });

  const emailErrorMessage = errors.email?.message;
  const passwordErrorMessage = errors.password?.message;

  return (
    <Container component="main" maxWidth="sm">
      <Fade in>
        <div className={styles.container}>
          <Typography className={styles.signUpTitle} component="h1" variant="h5">
            {t('register.registerTitle')}
          </Typography>

          <form noValidate onSubmit={handleSubmit(registerUser)}>
            <TextField
              inputRef={register}
              name="email"
              variant="outlined"
              margin="normal"
              label={t('auth.emailLabel')}
              error={Boolean(errors.email)}
              helperText={emailErrorMessage}
              autoComplete="email"
              required
              fullWidth
            />
            <TextField
              inputRef={register}
              name="password"
              variant="outlined"
              margin="normal"
              label={t('auth.passwordLabel')}
              type="password"
              error={Boolean(errors.password)}
              helperText={passwordErrorMessage}
              autoComplete="new-password"
              required
              fullWidth
            />

            <Button
              className={styles.submitButton}
              textClassName={styles.submitButtonText}
              loading={isLoading}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth
            >
              {t('register.singUp')}
            </Button>
          </form>

          <div className={styles.registerPromptContainer}>
            <Typography className={cn(styles.registerPrompt, styles.registerPromptColor)} component="span">
              {t('register.bySignUp')}{' '}
            </Typography>
            <Link
              className={styles.registerPrompt}
              href="https://www.sharpslide.com/terms"
              variant="body2"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('register.termsOfService')}{' '}
            </Link>
            <Typography className={cn(styles.registerPrompt, styles.registerPromptColor)} component="span">
              {t('register.and')}{' '}
            </Typography>
            <Link
              className={styles.registerPrompt}
              href="https://www.sharpslide.com/privacy"
              variant="body2"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('register.privacyPolicy')}
              {'.'}
            </Link>
          </div>
        </div>
      </Fade>
    </Container>
  );
};
