import { SlideStyleProps } from './../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, SlideStyleProps>(theme => ({
  slideNumberFlow: {
    bottom: '3.5%',
    height: 'auto',
    paddingTop: '0%',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  htmlEmbed: {
    width: '100%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
  },
  number: {
    zIndex: 10,
  },
}));
