import React, { useState } from 'react';
import { IconButton, Box } from '@material-ui/core';
import { EditIcon, CheckCircleIcon, CancelIcon } from 'assets/icons';
import { useStyles } from './Editable.styles';

interface EditableProps {
  children: (props: {
    editing: boolean;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  }) => React.ReactNode;
  defaultValue: string;
  onSubmit: (value: any) => void;
}
export const Editable: React.FC<EditableProps> = ({ children, defaultValue, onSubmit }) => {
  const [value, setValue] = useState(defaultValue);
  const [editing, setEditing] = useState(false);
  const styles = useStyles({ editing });

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setValue(event.target.value);
  };

  const handleOnSubmit = () => {
    onSubmit(value);
    setEditing(false);
  };

  return (
    <div className={styles.container}>
      {children({ editing, value, onChange: handleOnChange })}

      {!editing && (
        <IconButton className={styles.iconButton} size="small" onClick={() => setEditing(true)}>
          <EditIcon className={styles.icon} />
        </IconButton>
      )}

      {editing && (
        <Box display="flex" marginTop={1}>
          <IconButton className={styles.iconButton} size="small" onClick={handleOnSubmit}>
            <CheckCircleIcon className={styles.icon} />
          </IconButton>

          <IconButton size="small" onClick={() => setEditing(false)}>
            <CancelIcon className={styles.icon} />
          </IconButton>
        </Box>
      )}
    </div>
  );
};
