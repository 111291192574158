import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en_US from './locales/en_US.json';

export const ISO6391Code = {
  english: 'en',
};

const resources = {
  en: {
    translation: en_US,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: ISO6391Code.english,
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.NODE_ENV !== 'production',
});

export const i18nClient = i18n;
