import { loadStripe } from '@stripe/stripe-js';
import { firebaseFirestore } from 'services/firebase';
import { CheckoutSessionDocumentData } from './types';

export enum CheckoutSessionErrors {
  STRIPE_KEY_NOT_FOUND = 1,
  SESSION_ERROR,
}

export const sendToCheckout = (
  userId: string,
  priceId: string,
  url: string,
  setError: React.Dispatch<React.SetStateAction<CheckoutSessionErrors | undefined>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const stripeKey = process.env.REACT_APP_STRIPE_KEY;

  if (!stripeKey) setError(CheckoutSessionErrors.STRIPE_KEY_NOT_FOUND);
  if (stripeKey) setLoading(true);

  const successUrl = `${url}?paymentSucceed=true`;
  const cancelUrl = `${url}?paymentSucceed=false`;

  // TODO: Investigate the issue regarding Rewardful integration (not able to proceed to checkout)
  // const getClientReferenceId = () => {
  //   return (window.Rewardful && window.Rewardful.refferal) || `checkout_${new Date().getTime()}`;
  // };

  stripeKey &&
    firebaseFirestore
      .collection('users')
      .doc(userId)
      .collection('checkout_sessions')
      .add({
        mode: 'payment',
        price: priceId,
        success_url: successUrl,
        cancel_url: cancelUrl,
        allow_promotion_codes: true,
      })
      .then(docRef => {
        docRef.onSnapshot(async snapshot => {
          const { error, sessionId } = snapshot.data() as CheckoutSessionDocumentData;

          if (error) {
            setError(CheckoutSessionErrors.SESSION_ERROR);
            setLoading(false);
          }

          if (sessionId) {
            const stripe = await loadStripe(stripeKey);
            await stripe!.redirectToCheckout({ sessionId });
          }
        });
      });
};
