import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const ToolbarHighlight: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="0" height="0" viewBox="0 0 69 105" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <clipPath id="toolbar-highlight">
          <path
            d="M69,0c0,2.9-0.6,5.8-1.7,8.5c-1.1,2.6-2.7,5-4.7,7c-2,2-4.4,3.6-7,4.7C52.9,21.4,50,22,47,22H20c0,0,0,0,0,0
	c-11,0-20,9-20,20v21c0,0,0,0,0,0c0,11,9,20,20,20h27c0,0,0,0,0.1,0C59.2,83,69,92.9,69,105V0z"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
