import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, SlideStyleProps>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    justifyContent: 'center',
  },
  fullBackgroundImageRounded: {
    borderTopRightRadius: theme.shape.borderRadius * 4,
    borderBottomLeftRadius: theme.shape.borderRadius * 4,
  },
  titleFlowOne: {
    position: 'absolute',
    left: '5%',
    top: 'auto',
    right: 'auto',
    bottom: '17%',
    width: '40%',
    marginTop: '0px',
    marginBottom: '0px',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
    fontSize: '5.5em',
    lineHeight: '110%',
  },
  subtitleFlowOne: {
    position: 'absolute',
    left: '5%',
    top: '86.5%',
    right: 'auto',
    bottom: 'auto',
    width: '40%',
    marginTop: '0',
    marginBottom: '0',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    fontSize: '2em',
    lineHeight: '110%',
  },
  logoBoxFlowOne: {
    position: 'absolute',
    left: 'auto',
    top: '6%',
    right: '4%',
    bottom: 'auto',
    zIndex: 100,
    width: '17.5%',
    height: '15%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  svgWhiteFlowOne: {
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
  },
  svgWhiteFlowTwo: {
    borderTopRightRadius: theme.shape.borderRadius * 2,
  },
  svgAccentFlowOne: {
    borderTopRightRadius: theme.shape.borderRadius * 2,
  },
}));
