import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  actionTabs: {
    marginBottom: theme.spacing(3.5),
  },
  tabPanelRoot: {
    padding: 0,
  },
  fontListContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  nonSchemeInfoTitle: {
    marginBottom: theme.spacing(0.75),
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
  },
  nonSchemeInfo: {
    marginBottom: theme.spacing(3.5),
    fontSize: theme.typography.pxToRem(14),
  },
}));
