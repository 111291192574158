import { makeStyles, Theme } from '@material-ui/core/styles';

type CreatorFontItemStyles = {
  isErrored: boolean;
};

export const useStyles = makeStyles<Theme, CreatorFontItemStyles>(theme => ({
  fontItemContainer: {
    width: 435,
    height: 78,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    marginBottom: theme.spacing(5.75),
    willChange: 'transform',

    transition: theme.transitions.create(['transform', 'box-shadow'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  fontItemContainerHover: {
    cursor: 'pointer',

    '&:hover': {
      boxShadow: `1px 1px 5px ${theme.palette.utility.grayscale[500]}`,
      transform: 'scale(1.05)',
    },
  },
  fontItemContainerActive: {
    transform: 'scale(1.05)',
    boxShadow: `0px 5px 16px ${theme.palette.utility.grayscale[300]}`,
  },

  fontItemPreviewLetters: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: 100,
    flexGrow: 0,
    flexShrink: 0,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(56),
    padding: theme.spacing(0, 1.5),
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    userSelect: 'none',

    transition: theme.transitions.create('background', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  fontItemPreviewLettersActive: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
  },

  fontItemPreviewLetter: {
    fontSize: 'unset',
  },
  fontItemPreviewLetterHidden: {
    display: 'none',
  },
  fontItemPreviewLetterVisible: {
    display: 'block',
  },

  fontItemPreviewText: {
    flexGrow: 1,
    color: theme.palette.utility.black1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: theme.spacing(1.5, 2.875),
    borderTopRightRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
  },
  fontItemPreviewTextPrimary: {
    alignSelf: 'flex-start',
    fontSize: theme.typography.pxToRem(20),
  },
  fontItemPreviewTextSecondary: {
    alignSelf: 'flex-start',
    fontSize: theme.typography.pxToRem(16),
  },
  fontItemLoader: {
    position: 'absolute!important' as 'absolute',
    minHeight: 'auto!important' as 'auto',
    top: '50%',
    left: '40%',
  },
}));
