import cn from 'classnames';
import { SlideProps } from '../../types';
import headshotImage from '../assets/images/headshot2.jpg';
import { useRootStyles } from '../../common.styles';
import { useStyles } from './SlideSixth.styles';
import SlideNumber from '../components/SlideNumber';

export const SlideSixth: React.FC<SlideProps> = ({ logoUrl, colorTheme, fontPair }) => {
  const styles = useStyles({ fontPair, colorTheme });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img src={headshotImage} loading="lazy" alt="" className={styles.flowTeamImage} />
        <div className={rootStyles.svgWhite}>
          <svg
            className={styles.svgWhiteFlowSix}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1600 900"
            fill="#fff"
          >
            <path
              d="M0,0v218.9c12.9-25.7,29.6-48.8,51.1-67.8c84-74.7,195.3-57.5,290.5-3.2l0,0c61.9,35.4,117,86.4,153.3,138.1
	c37,52.8,60.4,117.5,64.1,183.5c4.4,77.5-18.8,127.7-66.1,183.4C442.9,711.5,375.3,754,301.4,766c-83.1,13.5-172.9-9.7-234.7-70.7
	c-29.3-28.9-51-64.6-66.7-103.6V900h1600V0H0z"
            />
          </svg>
        </div>
        <h1 className={styles.teamTitleRound}>Jane Smith</h1>
        <h2 className={styles.teamMemberTitleRound}>Chief Executive Officer</h2>
        <div className={styles.accentLineTeamRound} />
        <p className={styles.teamText}>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula egetdolor. Aenean massa. Cum
          sociis natoque. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa
          quis enim.
        </p>
        <p className={cn(styles.teamText, styles.teamTextSecond)}>
          In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pedemollis pretium.
          Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean commodo ligula eget dolor. Donec quam
          felis, ultricies nec, pellentesque eu, pretium quis, sem.
        </p>
        <SlideNumber slide="6" colorTheme={colorTheme} fontPair={fontPair} />
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerStandard)}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
      </div>
    </>
  );
};

export default SlideSixth;
