import { useStyles } from './SlideNine.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';
import cn from 'classnames';
import SlideNumber from '../components/SlideNumber';

export const SlideNine: React.FC<SlideProps> = ({ colorTheme, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });

  const photos = photoPack;
  const imageSource: string | null = photos?.items[18].image;
  const backupImageSource: string = photos?.items[18].backupImage!;

  return (
    <div className={cn(styles.slideCanvas, rootStyles.root)}>
      <img src={imageSource ? imageSource : backupImageSource} loading="lazy" alt="" className={styles.leftImage} />
      <div className={rootStyles.svgWhite}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900" fill="#fff" className={styles.svgWhiteRounded}>
          <path d="M459,0c109.2,162.8,173,358.7,173,569.5c0,115.7-19.2,226.8-54.5,330.5H1600V0H459z" />
        </svg>
      </div>
      <div className={rootStyles.svgAccentOne}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1600 900"
          fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
        >
          <path
            d="M610,62c-36.8,0-68.9,20.5-85.3,50.8c22.3,44.6,41.4,91,57,139c8.9,2.7,18.4,4.2,28.3,4.2c53.6,0,97-43.4,97-97
	S663.6,62,610,62z M604.5,180.3c-1.4,2.4-3.3,4.2-5.7,5.6c-2.4,1.4-5,2-7.7,2c-6.3,0-11.1-2.6-14.4-7.7v0c-2.8-4.3-4.2-9.6-4.2-16.1
	c0-8.2,2.1-15.5,6.2-22c4.2-6.5,10.4-11.7,18.9-15.6l2.3,4.4c-5.1,2.1-9.4,5.5-13.1,10s-5.5,9.2-5.5,13.9c0,2,0.2,3.7,0.7,5.1
	c2.7-2.1,5.7-3.2,9-3.2c4.4,0,8,1.4,11.1,4.2c3,2.8,4.5,6.6,4.5,11.4C606.6,175.3,605.9,178,604.5,180.3z M643.5,183.5
	c-3,3-6.7,4.5-11.1,4.5c-6.3,0-11.1-2.6-14.4-7.7h0c-2.8-4.3-4.2-9.6-4.2-16.1c0-8.2,2.1-15.5,6.2-22c4.2-6.5,10.4-11.7,18.9-15.6
	l2.3,4.4c-5.1,2.1-9.4,5.5-13.1,10c-3.6,4.6-5.5,9.2-5.5,13.9c0,2,0.2,3.7,0.7,5.1c2.8-2.1,5.8-3.2,9-3.2c4.4,0,8.1,1.4,11.1,4.2
	c3,2.8,4.5,6.6,4.5,11.4C648,176.8,646.5,180.5,643.5,183.5z"
          />
        </svg>
      </div>
      <p className={styles.quoteTextRound}>
        A hundred times every day I remind myself that my inner and outer life are based on the labors of other men,
        living and dead, and that I must exert myself in order to give in the same measure as I have received and am
        still receiving.
      </p>
      <h1 className={styles.quoteAuthorRound}>Albert Einstein</h1>
      <SlideNumber slide="9" colorTheme={colorTheme} fontPair={fontPair} />
    </div>
  );
};

export default SlideNine;
