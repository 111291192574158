import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const LayoutIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19,2H5A2.946,2.946,0,0,0,2,5V19a2.946,2.946,0,0,0,3,3H19a2.946,2.946,0,0,0,3-3V5A2.946,2.946,0,0,0,19,2ZM5,4H19a.945.945,0,0,1,1,1V8H4V5A.945.945,0,0,1,5,4ZM4,19V10H8V20H5A.945.945,0,0,1,4,19Zm15,1H10V10H20v9A.945.945,0,0,1,19,20Z"
        transform="translate(-2 -2)"
      />
    </SvgIcon>
  );
};
