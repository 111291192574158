import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { RouteComponentProps } from 'react-router';
import { Fade, Container, Typography, Grow } from '@material-ui/core';
import { Loader, DashboardPresentation, DashboardPresentationAddNew } from 'components';
import { useConfigurationManagement } from 'modules/configuration/hooks';
import { clamp } from 'modules/presentation/utils';
import { isStatusPending } from 'modules/store/status';
import { useStyles } from './DashboardContainer.styles';

interface DashboardContainerProps extends RouteComponentProps {}

export const DashboardContainer: React.FC<DashboardContainerProps> = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const {
    userPresentations,
    userPresentationsStatus,
    fetchUserPresentations,
    deleteUserPresentation,
    deleteUserPresentationStatus,
    setPresentationName,
  } = useConfigurationManagement();

  const isLoading = isStatusPending(userPresentationsStatus) || isStatusPending(deleteUserPresentationStatus);
  const sortedUserPresentations = [...userPresentations].sort((a, b) => b.createdAt?.seconds! - a.createdAt?.seconds!);

  const formatter = useMemo(
    () =>
      new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }),
    []
  );

  useEffect(() => {
    fetchUserPresentations();
  }, [fetchUserPresentations]);

  return (
    <Fade in>
      <Container className={styles.container} component="main" maxWidth="xl">
        <Loader loading={isLoading}>
          <Typography className={styles.templatesTitle}>{t('dashboard.templates')}</Typography>

          <div className={styles.presentationsWrapper}>
            <DashboardPresentationAddNew />

            {sortedUserPresentations.map((presentation, index) => (
              <Grow key={presentation.uid} in timeout={{ enter: clamp((index + 1) * 150, 150, 1800) }}>
                <DashboardPresentation
                  presentation={presentation}
                  formatter={formatter}
                  onNameChange={setPresentationName}
                  onDelete={deleteUserPresentation}
                />
              </Grow>
            ))}
          </div>
        </Loader>
      </Container>
    </Fade>
  );
};
