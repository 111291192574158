import { useStyles } from './SlideFirst.styles';
import { useRootStyles } from '../../common.styles';
import { SlideProps } from '../../types';
import cn from 'classnames';

export const SlideFirst: React.FC<SlideProps> = ({ colorTheme, logoUrl, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[0].image;
  const backupImageSource: string = photos?.items[0].backupImage!;
  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img
          src={imageSource ? imageSource : backupImageSource}
          loading="lazy"
          alt=""
          className={cn(rootStyles.fullBackgroundImage, styles.fullBackgroundImageRounded)}
        />

        <div className={cn(rootStyles.svgAccentOne, rootStyles.opacityEighty)}>
          <svg className={styles.svgAccentFlowOne} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
            <path
              fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              d="M1044.9,835.3C917.2,732.6,874.5,553.4,752.1,444.6C596.9,306.7,323.4,331.3,190.5,495
	C99.1,607.7,87.1,770.4,143.9,900H1159C1118.1,883.6,1079.5,863.1,1044.9,835.3z"
            ></path>
            <path
              fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
              d="M1190.7,0c1,15.6,5,31.6,12.4,47.3c21.6,46.1,70.7,86.7,124,102.4c55.7,16.4,114.1,7.5,166.5,35.2
	c43.8,23.1,73.5,70.6,106.5,111.7V0H1190.7z"
            ></path>
          </svg>
        </div>

        <div className={rootStyles.svgWhite}>
          <svg className={styles.svgWhiteFlowOne} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
            <path
              fill="#fff"
              d="M905.4,751.6C850.3,595.3,707.1,473.4,544,443.8C373.4,413,186.9,475.4,29.7,402.1C19.5,397.4,9.6,392,0,386.1
              V900h931.1C930.6,849.5,922.2,799.2,905.4,751.6z"
            ></path>
            <path
              fill="#fff"
              d="M1172,0c28.7,33.6,65.4,60.3,106.8,76c91.4,34.8,201.3,19.5,280.8,76.4c15.3,11,28.6,24.5,40.5,39.8V0H1172z"
            ></path>
          </svg>
        </div>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerSvg)}>
            <div className={cn(rootStyles.svgAccentOne, rootStyles.opacityEighty)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
                <path
                  fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
                  d="M1600,104.8c-49.9-24.5-103.6-40.7-155.6-48.7c-77.1-11.9-176.2-12.7-224.5,32.8c-31.8,30-38.5,79.9-16.8,126
                  c21.6,46.1,70.7,86.7,124,102.4c55.7,16.4,114.1,7.5,166.5,35.2c43.8,23.1,73.5,70.6,106.5,111.7L1600,104.8L1600,104.8z"
                ></path>
              </svg>
            </div>
            <div className={cn(rootStyles.svgWhite)}>
              <svg className={styles.svgWhiteFlowTwo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
                <path
                  fill="#fff"
                  d="M1600,0h-492.7c0.1,11.9,1.1,23.9,2.9,35.7c14.4,92.4,81.1,174.8,168.5,208.1c91.4,34.8,201.3,19.5,280.8,76.4
                  c15.3,11,28.6,24.5,40.5,39.8V0z"
                ></path>
              </svg>
            </div>
            <div className={styles.logoBoxFlowOne}>
              <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
            </div>
          </div>
        )}
        <h1 className={styles.titleFlowOne}>Image Title Slide</h1>
        <h2 className={styles.subtitleFlowOne}>Presenter Name / Date</h2>
      </div>
    </>
  );
};

export default SlideFirst;
