import { createAction } from 'services/store/create-action';
import type firebase from 'firebase';
import { ImageItem } from './types';

export enum StorageActionType {
  UploadFile = '[Storage]: Upload file',
  UploadFileProgress = '[Storage]: Upload file - progress',
  UploadFileSuccess = '[Storage]: Upload file - success',
  UploadFileFailure = '[Storage]: Upload file - failure',

  UploadFileGetSource = '[Storage]: Upload file get source',
  UploadFileSetSource = '[Storage]: Upload file set source',

  FetchSavedImages = '[Storage]: Fetch saved images',
  FetchSavedImagesSuccess = '[Storage]: Fetch saved images - success',
  FetchSavedImagesFailure = '[Storage]: Fetch saved images - failure',

  SetPreviewImageSource = '[Storage]: Set preview image source',
}

export const StorageAction = {
  uploadFile: (file: File) => createAction(StorageActionType.UploadFile, { file }),
  uploadFileProgress: (progress: number) => createAction(StorageActionType.UploadFileProgress, { progress }),
  uploadFileSuccess: (ref: firebase.storage.Reference) => createAction(StorageActionType.UploadFileSuccess, { ref }),
  uploadFileFailure: () => createAction(StorageActionType.UploadFileFailure),

  uploadFileGetSource: () => createAction(StorageActionType.UploadFileGetSource),
  uploadFileSetSource: (source: string) => createAction(StorageActionType.UploadFileSetSource, { source }),

  fetchSavedImages: () => createAction(StorageActionType.FetchSavedImages),
  fetchSavedImagesSuccess: (imageItems: ImageItem[]) =>
    createAction(StorageActionType.FetchSavedImagesSuccess, { imageItems }),
  fetchSavedImagesFailure: (error: any) => createAction(StorageActionType.FetchSavedImagesFailure, { error }),

  setPreviewImageSource: (source: string | null) => createAction(StorageActionType.SetPreviewImageSource, { source }),
};

export type StorageActionCreator = typeof StorageAction;
