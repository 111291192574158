import { firebaseFunctions } from 'services/firebase';

export const sendToSubscriptionPortal = async (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
  setLoading(true);

  const functionRef = firebaseFunctions.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
  const { data } = await functionRef({ returnUrl: window.location.href });

  window.location.assign(data.url);
};
