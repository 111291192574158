import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Button';

interface FormDialogProps extends DialogProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  handleSubmit: (formValue: string) => void;
  dialogTitle: string;
  dialogContextText: string;
  textFieldLabel: string;
  error?: string;
}

export const FormDialog: React.FC<FormDialogProps> = ({
  setModalOpen,
  open,
  handleSubmit,
  dialogTitle,
  dialogContextText,
  textFieldLabel,
  error,
  ...formDialogProps
}) => {
  const { t } = useTranslation();
  const [formValue, setFormValue] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby={dialogTitle} {...formDialogProps}>
      <DialogTitle id={dialogTitle}>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContextText}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={textFieldLabel}
          type="email"
          fullWidth
          value={formValue}
          onChange={e => setFormValue(e.target.value)}
          error={errorMessage ? true : false}
          helperText={errorMessage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('formDialog.cancel')}</Button>
        <Button onClick={() => handleSubmit(formValue)}>{t('formDialog.submit')}</Button>
      </DialogActions>
    </Dialog>
  );
};
