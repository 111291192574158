import cn from 'classnames';
import { useStyles } from './SlideSecond.styles';
import { useRootStyles } from '../../common.styles';
import { SlideProps } from '../../types';

export const SlideSecond: React.FC<SlideProps> = ({ colorTheme, logoUrl, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[1].image;
  const backupImageSource: string = photos?.items[1].backupImage!;

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img
          src={imageSource ? imageSource : backupImageSource}
          loading="lazy"
          alt=""
          className={rootStyles.fullBackgroundImage}
        />
        {/* svg 1 */}
        <div className={cn(rootStyles.svgDark, rootStyles.opacityEightyFive)}>
          <svg
            className={styles.svgDarkSecond}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1600 900"
            display="block"
          >
            <path
              d="M817,18c0-6,0.1-12,0.2-18H0v638.7C68.7,599.5,148.2,577,233,577c208.2,0,384.8,135.4,446.5,323H1600v-90.1
	C1166.7,805.1,817,452.4,817,18z"
            ></path>
          </svg>
        </div>
        {/* svg 2 */}
        <div className={cn(rootStyles.svgAccentOne, rootStyles.opacityEighty)}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1600 900" display="block">
            <g>
              <path
                fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
                d="M1558.5,696c-339.9,0-628.1-221.9-727.5-528.7c69.3,363.2,386.6,638.4,769,642.7V694.9
              C1586.3,695.6,1572.4,696,1558.5,696z"
              ></path>
              <path
                fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
                d="M233,577c-22.2,0-44,1.5-65.3,4.5c7.1-0.3,14.2-0.5,21.3-0.5c200.8,0,370.2,134.9,422.4,319h68.1
                C617.8,712.4,441.2,577,233,577z"
              ></path>
            </g>
          </svg>
        </div>

        <div className={cn(styles.svgDark, styles.svgDarkEighty)}>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1600 900" display="block">
            <circle cx="800" cy="450" r="406"></circle>
          </svg>
        </div>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerSvg)}>
            <div className={rootStyles.svgWhite}>
              <svg
                className={styles.svgWhiteSecond}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1600 900"
                display="block"
              >
                <path
                  fill="#fff"
                  d="M189,581c-7.2,0-14.3,0.2-21.3,0.5c-60.3,8.4-117,28.2-167.7,57.2V900h611.4C559.2,715.9,389.8,581,189,581z"
                ></path>
              </svg>
            </div>
            <div className={styles.logoBoxRoundTitleTwo}>
              <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
            </div>
          </div>
        )}
        <h1 className={styles.titleTwoRound}>Image Title Slide</h1>
        <h2 className={styles.subtitleTwoRound}>Presenter Name / Date</h2>
      </div>
    </>
  );
};

export default SlideSecond;
