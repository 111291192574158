import React from 'react';
import cn from 'classnames';
import { Typography } from '@material-ui/core';
import { MediaIcon } from 'assets/icons';
import { useStyles } from './Message.styles';

interface MessageProps {
  className?: string;
  text: string;
}

export const Message: React.FC<MessageProps> = ({ className, text }) => {
  const styles = useStyles();

  return (
    <div className={cn(className, styles.messageContainer)}>
      <MediaIcon className={styles.messageIcon} />
      <Typography className={styles.messageText}>{text}</Typography>
    </div>
  );
};
