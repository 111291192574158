import { makeStyles } from '@material-ui/core/styles';

const CARD_WIDTH = 201;
const CARD_HEIGHT = 209;

export const useStyles = makeStyles(theme => ({
  title: {
    '&&': {
      // overwrite styles with higher specificity
      marginBottom: 36,
    },
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardWrapper: {
    maxWidth: CARD_WIDTH,
    maxHeight: CARD_HEIGHT,
    marginRight: 34,
    marginBottom: 34,
  },
  card: {
    width: CARD_WIDTH,
    height: CARD_HEIGHT,
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius * 2,

    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
    }),

    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));
