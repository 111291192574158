import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.palette.utility.black2,
      textAlign: 'left',
      fontSize: theme.typography.pxToRem(16),
    },
    titleCell: {
      padding: theme.spacing(1),
      fontWeight: theme.typography.fontWeightSemiBold,
      height: 40,
      display: 'flex',
      alignItems: 'center',
    },
    cell: {
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      height: 40,
      width: 'max-content',
    },
    modalContainer: {
      position: 'absolute',
      zIndex: 1,
    },
    modalCover: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    buttonIcon: {
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeOut,
      }),
    },
    saveButton: {
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(2),

      '&:hover svg': {
        transform: 'rotateY(-180deg)',
      },
    },
    resetButton: {
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(2),

      '&:hover svg': {
        transform: 'rotate(-180deg)',
      },
    },
    errorMessage: {
      marginTop: theme.spacing(4),
    },
  })
);
