import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import cn from 'classnames';
import { useStyles } from './NavigationLink.styles';

interface NavigationLinkProps extends LinkProps {
  active: boolean;
}

export const NavigationLink: React.FC<NavigationLinkProps> = ({ className, active, ...linkProps }) => {
  const styles = useStyles({ active });

  return (
    <Typography component="span">
      <Link className={cn(styles.link, className)} {...linkProps} />
    </Typography>
  );
};
