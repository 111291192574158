import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, SlideStyleProps>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#fff',
  },
  slideTitle: {
    position: 'absolute',
    left: '0%',
    top: '7%',
    right: '0%',
    bottom: 'auto',
    marginTop: '0px',
    marginBottom: '0px',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    fontSize: '3.8em',
    lineHeight: '110%',
    textAlign: 'center',
  },
  slideSubtitle: {
    position: 'absolute',
    left: '0%',
    top: '16.5%',
    right: '0%',
    bottom: 'auto',
    marginTop: '0px',
    marginBottom: '0px',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    fontSize: '1.7em',
    lineHeight: '110%',
    textAlign: 'center',
  },
  chartLegend: {
    position: 'absolute',
    left: '0%',
    top: '22%',
    right: '0%',
    bottom: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  legendItem: {
    width: '14.5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
  },
  legendSymbol: {
    width: '10%',
    display: 'flex',
    minHeight: 'auto',
    height: 'auto',
    marginRight: '4%',
  },
  chartLine: {
    position: 'absolute',
    left: '9%',
    top: '28%',
    right: 'auto',
    bottom: 'auto',
    display: 'flex',
    width: '82%',
    height: '47.5%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  lineContainer: {
    display: 'flex',
    width: '100%',
    height: '9%',
    marginBottom: '0%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
  },
  lineLabel: {
    width: '4%',
    marginBottom: '0px',
    marginTop: '0px',
    marginRight: '1.5%',
    fontSize: '1.3em',
    lineHeight: '100%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
  },
  line: {
    width: '94%',
    height: '1px',
    marginLeft: '0%',
    backgroundColor: '#d9d9d9',
  },
  area: {
    position: 'absolute',
    left: '13.9%',
    top: 'auto',
    right: 'auto',
    bottom: '26%',
    width: '77.1%',
  },
  xAxisLabel: {
    position: 'absolute',
    left: '47%',
    top: 'auto',
    right: 'auto',
    bottom: '14%',
    marginTop: '0px',
    marginBottom: '0px',
    fontSize: '1.8em',
    lineHeight: '100%',
    textAlign: 'center',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
  },
  yAxisLabel: {
    position: 'absolute',
    left: '-4%',
    top: 'auto',
    right: 'auto',
    bottom: '46%',
    width: '24%',
    marginTop: '0px',
    marginBottom: '0px',
    transform: 'rotate(-90deg)',
    fontSize: '1.8em',
    lineHeight: '100%',
    textAlign: 'center',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
  },
  date: {
    position: 'absolute',
    top: 'auto',
    right: 'auto',
    bottom: '21%',
    width: '7%',
    marginTop: '0px',
    marginBottom: '0px',
    fontSize: '1.3em',
    lineHeight: '100%',
    textAlign: 'center',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
  },
  makeSquare: {
    width: '100%',
  },
}));
