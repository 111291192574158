import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    maxHeight: 'calc(100vh - 110px)',
  },
  creatorToolbar: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 65,
  },
  creatorAction: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 600,

    transition: theme.transitions.create('flex-basis', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
  },
  creatorActionHide: {
    flexBasis: 0,
  },
  creatorPreview: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '65%',
    position: 'sticky',
    height: 'calc(100vh - 110px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  creatorPreviewFullSize: {
    marginLeft: theme.spacing(5.5),
  },
}));
