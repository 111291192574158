import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const EyeDropperIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.339,9.735a2.368,2.368,0,0,0-3.431,0L18.683,10.96a2.459,2.459,0,0,0-3.267,3.267L9.535,20.109a2.426,2.426,0,0,0,3.431,3.431l5.881-5.881a2.459,2.459,0,0,0,3.267-3.267l1.225-1.225A2.368,2.368,0,0,0,23.339,9.735ZM11.822,22.4a.809.809,0,0,1-1.144-1.144h0l5.8-5.8L17.622,16.6ZM22.2,12.022l-2.287,2.287.572.572a.721.721,0,0,1,0,1.144.79.79,0,0,1-1.144,0L17.05,13.738a.809.809,0,0,1,1.144-1.144l.572.572,2.287-2.287A.809.809,0,1,1,22.2,12.022Z"
        fill="#fff"
        fillRule="evenodd"
        transform="translate(-8.8 -9)"
      />
    </SvgIcon>
  );
};
