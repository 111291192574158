import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  button: {
    '&.MuiButton-root': {
      position: 'relative',
      height: 42,

      padding: theme.spacing(1.25, 3.75),

      transition: theme.transitions.create(['background', 'color', 'border'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.standard,
      }),

      '&.MuiButton-contained': {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 26,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,

        '&:hover': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.common.white,
        },
      },

      '&.MuiButton-outlined': {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 26,
        color: theme.palette.primary.main,

        '&:hover': {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
  buttonText: {
    fontWeight: theme.typography.fontWeightSemiBold,
    textTransform: 'none',
  },
  progress: {
    position: 'absolute',
    color: theme.palette.common.white,
  },
}));
