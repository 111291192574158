import React from 'react';
import { ColorTheme } from 'components/CreatorPreview/templates/types';

const TwitterBoxIcon: React.FC<ColorTheme> = ({ colorTheme }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 342.9 342.9"
      fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
      style={{ borderRadius: '100%', width: '16%', height: '100%' }}
    >
      <g id="a"></g>
      <path
        d="M311.1,82.3c-14.2-22.1-27.4-44.7-48-60.7l0,0c-10.3-8-22.4-14.3-37.4-18.2C175-9.6,117.3,17.2,79.3,45.3
	C33.7,79,4.6,129.1,0.5,181.1c-2.9,36.9,6.8,75.3,31.7,105.2c42,50.6,125.4,69.6,192.3,47.3c66.9-22.3,113.6-82.1,118-144.4
	C345.2,151.1,332.3,115.3,311.1,82.3z M244.2,134.1c0.1,1.6,0.1,3.2,0.1,4.9c0,49.6-37.8,106.8-106.8,106.8
	c-21.2,0-40.9-6.2-57.6-16.9c2.9,0.3,5.9,0.5,9,0.5c17.6,0,33.8-6,46.6-16.1c-16.4-0.3-30.3-11.2-35.1-26.1c2.3,0.4,4.6,0.7,7.1,0.7
	c3.4,0,6.7-0.5,9.9-1.3c-17.2-3.5-30.1-18.6-30.1-36.8c0-0.2,0-0.3,0-0.5c5.1,2.8,10.9,4.5,17,4.7c-10.1-6.7-16.7-18.2-16.7-31.3
	c0-6.9,1.9-13.3,5.1-18.9c18.5,22.7,46.2,37.7,77.4,39.2c-0.6-2.7-1-5.6-1-8.6c0-20.7,16.8-37.5,37.5-37.5
	c10.8,0,20.6,4.6,27.4,11.9c8.6-1.7,16.6-4.8,23.8-9.1c-2.8,8.8-8.8,16.1-16.5,20.8c7.6-0.9,14.8-2.9,21.6-5.9l0,0
	C257.9,122.2,251.6,128.8,244.2,134.1z"
      />
    </svg>
  );
};

export default TwitterBoxIcon;
