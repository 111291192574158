import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const ColorSchemeIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.878,15.657a.976.976,0,1,0,.976.976A.976.976,0,0,0,6.878,15.657Zm11.765-3.9,1.2-1.2a2.927,2.927,0,0,0,0-4.136L17.082,3.668a2.927,2.927,0,0,0-4.136,0l-1.19,1.2A2.927,2.927,0,0,0,8.829,2h-3.9A2.927,2.927,0,0,0,2,4.927V18.584a2.927,2.927,0,0,0,2.927,2.927H18.584a2.927,2.927,0,0,0,2.927-2.927v-3.9a2.927,2.927,0,0,0-2.869-2.927ZM9.8,18.584a.976.976,0,0,1-.976.976h-3.9a.976.976,0,0,1-.976-.976V4.927a.976.976,0,0,1,.976-.976h3.9a.976.976,0,0,1,.976.976ZM11.755,7.619l2.575-2.575a.976.976,0,0,1,1.375,0l2.761,2.81a.976.976,0,0,1,0,1.375l-2.81,2.81h0l-3.9,3.853Zm7.8,10.965a.976.976,0,0,1-.976.976h-7a3.044,3.044,0,0,0,.166-.9L16.7,13.706h1.883a.976.976,0,0,1,.976.976Z"
        transform="translate(-2 -2)"
      />
    </SvgIcon>
  );
};
