import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabsRoot: {
    minHeight: 0,
  },
  tabRoot: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    textTransform: 'none',

    marginRight: theme.spacing(4),
    color: theme.palette.utility.gray2,
  },
  textWrapper: {
    paddingBottom: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightSemiBold,

    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard,
    }),

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  selected: {
    color: theme.palette.utility.black1,
  },
  textOpacity: {
    opacity: 1,
  },
}));
