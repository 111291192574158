const LOCAL_STORAGE_KEY_PREFIX = 'shrapslide';
const LOCAL_STORAGE_KEY_SEPARATOR = '::';

const createKey = (key: string): string => [LOCAL_STORAGE_KEY_PREFIX, key].join(LOCAL_STORAGE_KEY_SEPARATOR);

const localStorageGet = <T>(key: string): T | null => {
  const value = localStorage.getItem(createKey(key));

  return value ? (JSON.parse(value) as T) : null;
};

const localStoragePut = (key: string, value: unknown): void => {
  if (value === null) {
    localStorage.removeItem(createKey(key));
  } else {
    localStorage.setItem(createKey(key), JSON.stringify(value));
  }
};

export const appLocalStorage = {
  get: localStorageGet,
  put: localStoragePut,
};
