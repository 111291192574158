import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './PricingSelection.styles';

interface PricingSelectionProps {
  price: number;
  period: string;
  isBestValue?: boolean;
  isSelected: boolean;
  onClick: () => void;
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'usd',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const PricingSelection: React.FC<PricingSelectionProps> = ({
  price,
  period,
  isBestValue,
  isSelected,
  onClick,
}) => {
  const styles = useStyles({ isSelected });
  const { t } = useTranslation();

  return (
    <Box className={styles.container} onClick={onClick}>
      {isBestValue && (
        <Box className={styles.bestValueContainer}>
          <Typography className={styles.bestValue}>{t('creatorDownload.pricingSelection.bestValue')}</Typography>
        </Box>
      )}
      <Box className={styles.pricingDetailsContainer}>
        <Typography className={styles.period}>{period}</Typography>
        <Typography className={styles.perk}>{t('creatorDownload.pricingSelection.unlimited')}</Typography>
        <Typography className={styles.price}>{currencyFormatter.format(price)}</Typography>
        <Typography className={styles.fee}>{t('creatorDownload.pricingSelection.fee')}</Typography>
        <Box className={styles.selectContainer}>
          <Typography className={styles.select}>
            {isSelected ? t('creatorDownload.pricingSelection.selected') : t('creatorDownload.pricingSelection.select')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
