import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, SlideStyleProps>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#fff',
  },
  slideTitle: {
    position: 'absolute',
    fontSize: '3.8em',
    lineHeight: '110%',
    textAlign: 'center',
    right: '0',
    left: '0%',
    top: '7%',
    bottom: 'auto',
    marginTop: 0,
    marginBottom: 0,
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : '#3a5df6'),
  },
  pieRow: {
    position: 'absolute',
    display: 'flex',
    left: '0%',
    top: '23%',
    right: 0,
    bottom: 'auto',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: '0%',
  },
  pieBlock: {
    position: 'relative',
    display: 'flex',
    width: '20.5%',
    marginRight: '1.5%',
    marginLeft: '1.5%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pieContainer: {
    position: 'relative',
    display: 'flex',
    width: '83%',
    height: '30%',
    padding: '0',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    overflow: 'hidden',
  },
  pieback: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    opacity: 0.17,
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : '#3a5df6'),
  },
  pieLabel: {
    position: 'relative',
    width: '80%',
    marginTop: '11%',
    textAlign: 'center',
  },
  pieSlice: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : '#3a5df6'),
  },
  pieCenter: {
    position: 'absolute',
    left: '7%',
    top: '7%',
    right: '7%',
    bottom: '7%',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: '100%',
  },
  heading: {
    marginBottom: 0,
    marginTop: 0,
    fontSize: '4em',
    lineHeight: '100%',
    textAlign: 'center',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : '#0e0e1e'),
  },
  arrowDown: {
    position: 'relative',
    display: 'block',
    width: '13%',
    height: 'auto',
    marginTop: '11%',
    fontSize: '0px',
    lineHeight: '0px',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : '#3a5df6'),
  },
  labelTitle: {
    marginTop: 0,
    marginBottom: '4%',
    fontSize: '1.7em',
    lineHeight: '120%',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : '#0e0e1e'),
  },
  labelText: {
    marginBottom: 0,
    fontSize: '1.2em',
    lineHeight: '125%',
  },
  image: {
    width: '100%',
    height: '100%',
  },
}));
