import { makeStyles, Theme } from '@material-ui/core/styles';

type CreatorPreviewSlideStyles = {
  height?: number;
};

export const useStyles = makeStyles<Theme, CreatorPreviewSlideStyles>(theme => ({
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  slideContainer: {
    display: 'flex',
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
  },
  slidesImageFadeIn: {
    opacity: 0,
    animationName: '$fadeIn',
    animationDelay: `${theme.transitions.duration.complex}ms`,
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'forwards',
  },
  slideImage: {
    borderRadius: theme.shape.borderRadius * 2,
    height: '100%',
    width: '100%',
  },
  skeleton: {
    height: ({ height }) => height,
    width: '100%',
    borderRadius: theme.shape.borderRadius * 2,
  },
}));
