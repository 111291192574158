import { createAction } from 'services/store/create-action';
import type { PreviewImage, PresentationDto } from './types';

export enum PresentationActionType {
  InitializePresentation = '[Presentation]: Initialize presentation',

  StartPresentationObserver = '[Presentation]: Start presentation observer',
  StartPresentationObserverFailure = '[Presentation]: Start presentation observer - failure',
  StopPresentationObserver = '[Presentation]: Stop presentation observer',
  PresentationObserverEmission = '[Presentation]: Presentation observer emission',

  SetPresentationDownloadURL = '[Presentation]: Set presentation download url',

  StartPreviewObserver = '[Presentation]: Start preview observer',
  StartPreviewObserverFailure = '[Presentation]: Start preview observer - failure',
  StopPreviewObserver = '[Presentation]: Stop preview observer',
  PreviewObserverEmission = '[Presentation]: Preview observer emission',
  PreviewObserverEmissionFailure = '[Presentation]: Preview observer emission - failure',

  SavePresentationLayout = '[Presentation]: Save presentation layout',
  SavePresentationLayoutSuccess = '[Presentation]: Save presentation layout - success',
  SavePresentationLayoutFailure = '[Presentation]: Save presentation layout - failure',

  SavePresentationLogo = '[Presentation]: Save presentation logo',
  SavePresentationLogoSuccess = '[Presentation]: Save presentation logo - success',
  SavePresentationLogoFailure = '[Presentation]: Save presentation logo - failure',

  SavePresentationColors = '[Presentation]: Save presentation colors',
  SavePresentationColorsSuccess = '[Presentation]: Save presentation colors - success',
  SavePresentationColorsFailure = '[Presentation]: Save presentation colors - failure',

  SavePresentationFontTheme = '[Presentation]: Save presentation font theme',
  SavePresentationFontThemeSuccess = '[Presentation]: Save presentation font theme - success',
  SavePresentationFontThemeFailure = '[Presentation]: Save presentation font theme - failure',

  SavePresentationPhotoPack = '[Presentation]: Save presentation photo pack',
  SavePresentationPhotoPackSuccess = '[Presentation]: Save presentation photo pack - success',
  SavePresentationPhotoPackFailure = '[Presentation]: Save presentation photo pack - failure',

  GeneratePresentationFullPreview = '[Presentation]: Generate presentation full preview',
  GeneratePresentationFullPreviewSuccess = '[Presentation]: Generate presentation full preview - success',
  GeneratePresentationFullPreviewFailure = '[Presentation]: Generate presentation full preview - failure',

  GeneratePresentationDownloadLink = '[Presentation]: Generate presentation download link',
  GeneratePresentationDownloadLinkSuccess = '[Presentation]: Generate presentation download link - success',
  GeneratePresentationDownloadLinkFailure = '[Presentation]: Generate presentation download link - failure',

  ResetPresentationDownloadLink = '[Presentation]: Reset presentation download link',

  ResetPresentationState = '[Presentation]: Reset presentation state',
}

export const PresentationAction = {
  initializePresentation: () => createAction(PresentationActionType.InitializePresentation),

  startPresentationObserver: (presentationId: string) =>
    createAction(PresentationActionType.StartPresentationObserver, { presentationId }),
  startPresentationObserverFailure: () => createAction(PresentationActionType.StartPresentationObserverFailure),
  stopPresentationObserver: () => createAction(PresentationActionType.StopPresentationObserver),
  presentationObserverEmission: (presentationData: PresentationDto) =>
    createAction(PresentationActionType.PresentationObserverEmission, { presentationData }),

  setPresentationDownloadURL: (downloadURL: string) =>
    createAction(PresentationActionType.SetPresentationDownloadURL, { downloadURL }),

  startPreviewObserver: (presentationId: string) =>
    createAction(PresentationActionType.StartPreviewObserver, { presentationId }),
  startPreviewObserverFailure: () => createAction(PresentationActionType.StartPreviewObserverFailure),
  stopPreviewObserver: () => createAction(PresentationActionType.StopPreviewObserver),
  previewObserverEmission: (previewImages: PreviewImage[]) =>
    createAction(PresentationActionType.PreviewObserverEmission, { previewImages }),
  previewObserverEmissionFailure: () => createAction(PresentationActionType.PreviewObserverEmissionFailure),

  savePresentationLayout: (layoutId: PresentationDto['layout'], presentationId: string) =>
    createAction(PresentationActionType.SavePresentationLayout, { layoutId, presentationId }),
  savePresentationLayoutSuccess: () => createAction(PresentationActionType.SavePresentationLayoutSuccess),
  savePresentationLayoutFailure: () => createAction(PresentationActionType.SavePresentationLayoutFailure),

  savePresentationLogo: (logo: PresentationDto['logo'], presentationId: string) =>
    createAction(PresentationActionType.SavePresentationLogo, { logo, presentationId }),
  savePresentationLogoSuccess: () => createAction(PresentationActionType.SavePresentationLogoSuccess),
  savePresentationLogoFailure: () => createAction(PresentationActionType.SavePresentationLogoFailure),

  savePresentationColors: (colorTheme: PresentationDto['colorTheme'], presentationId: string) =>
    createAction(PresentationActionType.SavePresentationColors, { colorTheme, presentationId }),
  savePresentationColorsSuccess: () => createAction(PresentationActionType.SavePresentationColorsSuccess),
  savePresentationColorsFailure: () => createAction(PresentationActionType.SavePresentationColorsFailure),

  savePresentationFontTheme: (fontThemeId: PresentationDto['fontTheme'], presentationId: string) =>
    createAction(PresentationActionType.SavePresentationFontTheme, { fontThemeId, presentationId }),
  savePresentationFontThemeSuccess: () => createAction(PresentationActionType.SavePresentationFontThemeSuccess),
  savePresentationFontThemeFailure: () => createAction(PresentationActionType.SavePresentationFontThemeFailure),

  savePresentationPhotoPack: (photoPackId: PresentationDto['photoPackId'], presentationId: string) =>
    createAction(PresentationActionType.SavePresentationPhotoPack, { photoPackId, presentationId }),
  savePresentationPhotoPackSuccess: () => createAction(PresentationActionType.SavePresentationPhotoPackSuccess),
  savePresentationPhotoPackFailure: () => createAction(PresentationActionType.SavePresentationPhotoPackFailure),

  generatePresentationFullPreview: (presentationId: string) =>
    createAction(PresentationActionType.GeneratePresentationFullPreview, { presentationId }),
  generatePresentationFullPreviewSuccess: () =>
    createAction(PresentationActionType.GeneratePresentationFullPreviewSuccess),
  generatePresentationFullPreviewFailure: () =>
    createAction(PresentationActionType.GeneratePresentationFullPreviewFailure),

  generatePresentationDownloadLink: (presentationId: string) =>
    createAction(PresentationActionType.GeneratePresentationDownloadLink, { presentationId }),
  generatePresentationDownloadLinkSuccess: () =>
    createAction(PresentationActionType.GeneratePresentationDownloadLinkSuccess),
  generatePresentationDownloadLinkFailure: () =>
    createAction(PresentationActionType.GeneratePresentationDownloadLinkFailure),

  resetPresentationDownloadLink: () => createAction(PresentationActionType.ResetPresentationDownloadLink),

  resetPresentationState: () => createAction(PresentationActionType.ResetPresentationState),
};

export type PresentationActionCreator = typeof PresentationAction;
