import type firebase from 'firebase';
import { createAction } from 'services/store/create-action';
import type {
  PresentationDashboard,
  CuratedColorScheme,
  UserColorScheme,
  FontPair,
  PhotoPackDocumentData,
  StripePriceObject,
  LayoutsDocumentData,
} from './types';

export enum ConfigurationActionType {
  FetchUserPresentations = '[Firestore]: Fetch user presentations',
  FetchUserPresentationsSuccess = '[Firestore]: Fetch user presentations - success',
  FetchUserPresentationsFailure = '[Firestore]: Fetch user presentations - failure',

  DeleteUserPresentation = '[Firestore]: Delete user presentation',
  DeleteUserPresentationSuccess = '[Firestore]: Delete user presentation - success',
  DeleteUserPresentationFailure = '[Firestore]: Delete user presentation - failure',

  SetPresentationName = '[Firestore]: Set presentation name',
  SetPresentationNameSuccess = '[Firestore]: Set presentation name - success',
  SetPresentationNameFailure = '[Firestore]: Set presentation name - failure',

  FetchCuratedColorSchemes = '[Firestore]: Fetch curated color schemes',
  FetchCuratedColorSchemesSuccess = '[Firestore]: Fetch curated color schemes - success',
  FetchCuratedColorSchemesFailure = '[Firestore]: Fetch curated color schemes - failure',

  FetchUserColorSchemes = '[Firestore] Fetch user color schemes',
  FetchUserColorSchemesSuccess = '[Firestore]: Fetch user color schemes - success',
  FetchUserColorSchemesFailure = '[Firestore]: Fetch user color schemes - failure',

  SaveUserColorScheme = '[Firestore]: Save user color scheme',
  SaveUserColorSchemeSuccess = '[Firestore]: Save user color scheme - success',
  SaveUserColorSchemeFailure = '[Firestore]: Save user color scheme - failure',

  FetchFontPairs = '[Firestore]: Fetch font pairs fonts',
  FetchFontPairsSuccess = '[Firestore]: Fetch font pairs - success',
  FetchFontPairsFailure = '[Firestore]: Fetch font pairs - failure',

  FetchPhotoPacks = '[Firestore]: Fetch photo packs',
  FetchPhotoPacksSuccess = '[Firestore]: Fetch photo packs - success',
  FetchPhotoPacksFailure = '[Firestore]: Fetch photo packs - failure',

  FetchSubscriptionOptions = '[Firestore]: Fetch subscription options',
  FetchSubscriptionOptionsSuccess = '[Firestore]: Fetch subscription options - success',
  FetchSubscriptionOptionsFailure = '[Firestore]: Fetch subscription options - failure',

  FetchPresentationLayouts = '[Firestore] Fetch presentation layouts',
  FetchPresentationLayoutsSuccess = '[Firestore] Fetch presentation layouts - succcess',
  FetchPresentationLayoutsFailure = '[Firestore] Fetch presentation layouts - failure',
}

export const ConfigurationAction = {
  fetchUserPresentations: () => createAction(ConfigurationActionType.FetchUserPresentations),
  fetchUserPresentationsSuccess: (presentations: PresentationDashboard[]) =>
    createAction(ConfigurationActionType.FetchUserPresentationsSuccess, { presentations }),
  fetchUserPresentationsFailure: (error: firebase.FirebaseError) =>
    createAction(ConfigurationActionType.FetchUserPresentationsFailure, { error }),

  deleteUserPresentation: (presentationId: string) =>
    createAction(ConfigurationActionType.DeleteUserPresentation, { presentationId }),
  deleteUserPresentationSuccess: () => createAction(ConfigurationActionType.DeleteUserPresentationSuccess),
  deleteUserPresentationFailure: (error: firebase.FirebaseError) =>
    createAction(ConfigurationActionType.DeleteUserPresentationFailure, { error }),

  setPresentationName: (presentationName: string, presentationId: string) =>
    createAction(ConfigurationActionType.SetPresentationName, { presentationName, presentationId }),
  setPresentationNameSuccess: () => createAction(ConfigurationActionType.SetPresentationNameSuccess),
  setPresentationNameFailure: (error: firebase.FirebaseError) =>
    createAction(ConfigurationActionType.SetPresentationNameFailure, { error }),

  fetchCuratedColorSchemes: () => createAction(ConfigurationActionType.FetchCuratedColorSchemes),
  fetchCuratedColorSchemesSuccess: (colors: CuratedColorScheme[]) =>
    createAction(ConfigurationActionType.FetchCuratedColorSchemesSuccess, { colors }),
  fetchCuratedColorSchemesFailure: (error: firebase.FirebaseError) =>
    createAction(ConfigurationActionType.FetchCuratedColorSchemesFailure, { error }),

  fetchUserColorSchemes: () => createAction(ConfigurationActionType.FetchUserColorSchemes),
  fetchUserColorSchemesSuccess: (colors: UserColorScheme[]) =>
    createAction(ConfigurationActionType.FetchUserColorSchemesSuccess, { colors }),
  fetchUserColorSchemesFailure: (error: firebase.FirebaseError) =>
    createAction(ConfigurationActionType.FetchUserColorSchemesFailure, { error }),

  saveUserColorScheme: (color: UserColorScheme) => createAction(ConfigurationActionType.SaveUserColorScheme, { color }),
  saveUserColorSchemeSuccess: () => createAction(ConfigurationActionType.SaveUserColorSchemeSuccess),
  saveUserColorSchemeFailure: (error: firebase.FirebaseError) =>
    createAction(ConfigurationActionType.SaveUserColorSchemeFailure, { error }),

  fetchFontPairs: () => createAction(ConfigurationActionType.FetchFontPairs),
  fetchFontPairsSuccess: (fontPairs: FontPair[]) =>
    createAction(ConfigurationActionType.FetchFontPairsSuccess, { fontPairs }),
  fetchFontPairsFailure: (error: firebase.FirebaseError) =>
    createAction(ConfigurationActionType.FetchFontPairsFailure, { error }),

  fetchPhotoPacks: () => createAction(ConfigurationActionType.FetchPhotoPacks),
  fetchPhotoPacksSuccess: (photoPacks: PhotoPackDocumentData[]) =>
    createAction(ConfigurationActionType.FetchPhotoPacksSuccess, { photoPacks }),
  fetchPhotoPacksFailure: (error: firebase.FirebaseError) =>
    createAction(ConfigurationActionType.FetchPhotoPacksFailure, { error }),

  fetchSubscriptionOptions: () => createAction(ConfigurationActionType.FetchSubscriptionOptions),
  fetchSubscriptionOptionsSuccess: (subscriptionOptions: StripePriceObject[]) =>
    createAction(ConfigurationActionType.FetchSubscriptionOptionsSuccess, { subscriptionOptions }),
  fetchSubscriptionOptionsFailure: (error: firebase.FirebaseError) =>
    createAction(ConfigurationActionType.FetchSubscriptionOptionsFailure, { error }),

  fetchPresentationLayouts: () => createAction(ConfigurationActionType.FetchPresentationLayouts),
  fetchPresentationLayoutsSuccess: (layouts: LayoutsDocumentData[]) =>
    createAction(ConfigurationActionType.FetchPresentationLayoutsSuccess, { layouts }),
  fetchPresentationLayoutsFailure: (error: firebase.FirebaseError) =>
    createAction(ConfigurationActionType.FetchPresentationLayoutsFailure, { error }),
};

export type ConfigurationActionCreator = typeof ConfigurationAction;
