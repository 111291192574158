export const lines = [
  {
    label: '20',
  },
  {
    label: '18',
  },
  {
    label: '16',
  },
  {
    label: '14',
  },
  {
    label: '12',
  },
  {
    label: '10',
  },
  {
    label: '8',
  },
  {
    label: '6',
  },
  {
    label: '4',
  },
  {
    label: '2',
  },
  {
    label: '0',
  },
];

export const dates = [
  {
    label: '2013',
    left: '10.5%',
  },
  {
    label: '2014',
    left: '17.5%',
  },
  {
    label: '2015',
    left: '24.5%',
  },
  {
    label: '2016',
    left: '31.5%',
  },
  {
    label: '2017',
    left: '38.5%',
  },
  {
    label: '2018',
    left: '45.5%',
  },
  {
    label: '2019',
    left: '52.5%',
  },
  {
    label: '2020',
    left: '59.5%',
  },
  {
    label: '2021',
    left: '66.5%',
  },
  {
    label: '2022',
    left: '73.5%',
  },
  {
    label: '2023',
    left: '80.5%',
  },
  {
    label: '2024',
    left: '87.5%',
  },
];
