import { useStyles } from './SlideThird.styles';
import { SlideProps } from '../../types';
import { useRootStyles } from '../../common.styles';
import cn from 'classnames';

export const SlideThird: React.FC<SlideProps> = ({ colorTheme, logoUrl, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[7].image;
  const backupImageSource: string = photos?.items[7].backupImage!;

  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img
          src={imageSource ? imageSource : backupImageSource}
          loading="lazy"
          alt=""
          className={rootStyles.fullBackgroundImage}
        />
        <div className={styles.backgroundOverlay} />
        <div className={rootStyles.svgWhite}>
          <svg className={styles.svgWhiteThird} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 900">
            <path fill="#fff" d="M1299.5,0c4.9,38.6,7.5,78,7.5,118c0,329.7-173.1,619-433.4,782H1600V0H1299.5z"></path>
          </svg>
        </div>
        <h1 className={styles.roundTransition}>Image Section Title</h1>
        {logoUrl && (
          <div className={cn(rootStyles.logoContainer, rootStyles.logoContainerRoundTransitionRight)}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
      </div>
    </>
  );
};

export default SlideThird;
