import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const FontSchemeIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.657,5.976A.976.976,0,0,0,14.682,5H2.976a.976.976,0,0,0,0,1.951H7.853V17.682a.976.976,0,0,0,1.951,0V6.951h4.878A.976.976,0,0,0,15.657,5.976Zm4.878,4.878H14.682a.976.976,0,1,0,0,1.951h1.951v4.878a.976.976,0,0,0,1.951,0V12.8h1.951a.976.976,0,1,0,0-1.951Z"
        transform="translate(-2 -2)"
      />
    </SvgIcon>
  );
};
