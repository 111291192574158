import React from 'react';
import { ColorTheme } from 'components/CreatorPreview/templates/types';

const InstaBoxIcon: React.FC<ColorTheme> = ({ colorTheme }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 342.9 342.9"
      fill={colorTheme ? colorTheme?.accent1Color : 'CurrentColor'}
      style={{ borderRadius: '100%', width: '16%', height: '100%' }}
    >
      <g>
        <circle cx="171.4" cy="171" r="28.5" />
        <path
          d="M171.5,0C76.8,0,0,76.8,0,171.4s76.8,171.5,171.5,171.5s171.5-76.8,171.5-171.5S266.1,0,171.5,0z M257.6,207.2
		c0,15-4.9,27.7-14.2,36.7c-9.1,8.9-21.8,13.6-36.6,13.6h-70.7c-14.5,0-27-4.7-36.3-13.7c-9.5-9.2-14.5-22-14.5-37.1v-71.1
		c0-30.1,20.2-50.4,50.4-50.4h71.5c14.7,0,27.3,4.9,36.5,14.1h0c9.1,9.2,13.9,21.7,13.9,36.3V207.2z"
        />
        <path
          d="M207.2,101.2h-71.5c-10.5,0-19.2,3.2-25.2,9.2c-6.1,6-9.3,14.8-9.3,25.3v71.1c0,10.7,3.4,19.6,9.7,25.7
		c6.2,6,14.9,9.2,25.2,9.2h70.7c10.6,0,19.4-3.2,25.5-9.1c6.2-6,9.4-14.8,9.4-25.4v-71.5c0-10.3-3.2-19-9.3-25.1
		C226.3,104.4,217.6,101.2,207.2,101.2z M171.4,215.3c-24.4,0-44.3-19.9-44.3-44.3c0-24.4,19.9-44.3,44.3-44.3
		c24.4,0,44.3,19.9,44.3,44.3C215.8,195.4,195.9,215.3,171.4,215.3z M221.7,134.7c-5,2.4-11,0.2-13.4-4.8c-2.4-5-0.2-11,4.8-13.4
		c5-2.4,11-0.2,13.4,4.8C228.9,126.3,226.7,132.3,221.7,134.7z"
        />
      </g>
    </svg>
  );
};

export default InstaBoxIcon;
