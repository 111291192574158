import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  emailForm: {
    width: 359,
  },
  submitButton: {
    padding: theme.spacing(1, 5),
    marginLeft: theme.spacing(3),
  },
}));
