import React, { useState, useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import { useSharedManagement } from 'modules/shared/hooks';
import type { NotificationVariant } from 'modules/shared/state';
import { useStyles } from './NotificationOutlet.styles';
import { Alert } from '@material-ui/lab';

type NotificationOutletState = {
  message: string;
  variant: NotificationVariant;
  open: boolean;
  closing: boolean;
};

const initialState: NotificationOutletState = {
  message: '',
  variant: 'success',
  open: false,
  closing: false,
};

export const NotificationOutlet: React.FC = () => {
  const styles = useStyles();
  const [state, setState] = useState<NotificationOutletState>(initialState);
  const { notifyUserSuccess, notificationQueue } = useSharedManagement();

  const handleClose = (_: React.SyntheticEvent | MouseEvent, reason?: string): void => {
    if (reason === 'timeout' && !state.open) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      open: false,
      closing: true,
    }));
  };

  const handleExited = (): void => {
    notifyUserSuccess();
    setState({ ...initialState });
  };

  useEffect(() => {
    if (!state.open && !state.closing && notificationQueue.length > 0) {
      const [notification] = notificationQueue;

      setState({
        message: notification.message,
        variant: notification.variant || 'success',
        open: true,
        closing: false,
      });
    }
  }, [state, notificationQueue]);

  return (
    <Snackbar
      className={styles.root}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={state.open}
      autoHideDuration={5000}
      onClose={handleClose}
      onExited={handleExited}
      ClickAwayListenerProps={{
        mouseEvent: false,
        touchEvent: false,
      }}
      disableWindowBlurListener
    >
      <Alert severity={state.variant} onClose={handleClose}>
        {state.message}
      </Alert>
    </Snackbar>
  );
};
