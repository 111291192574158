import React, { useState } from 'react';
import { Typography, ButtonBase } from '@material-ui/core';
import { useStyles } from './PhotoPackItem.styles';

interface PhotoPackItemProps {
  name: string;
  thumbnail: string;
  thumbnailFallback?: string;
  isActive: boolean;
  onClick: () => void;
}

export const PhotoPackItem: React.FC<PhotoPackItemProps> = ({
  name,
  thumbnail,
  thumbnailFallback,
  isActive,
  onClick,
}) => {
  const styles = useStyles();
  const [error, setError] = useState(false);

  const thumbnailSource = error ? thumbnailFallback : thumbnail;

  return (
    <ButtonBase className={styles.container} disabled={isActive} onClick={onClick}>
      <img
        className={styles.thumbnail}
        width="200"
        height="120"
        src={thumbnailSource}
        alt="Photopack thumbnail"
        onError={() => setError(true)}
      />

      {isActive ? (
        <div className={styles.activeContainer}>
          <div className={styles.overlay} />
          <Typography className={styles.name}>{name}</Typography>
        </div>
      ) : (
        <div className={styles.stripe}>
          <Typography className={styles.name}>{name}</Typography>
        </div>
      )}
    </ButtonBase>
  );
};
