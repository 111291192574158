import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  subtitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.utility.black1,
    marginBottom: theme.spacing(5.375),
  },

  downloadOptionWrapper: {
    width: 200,
    height: 255,
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: `0px 5px 16px ${theme.palette.utility.grayscale[500]}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'unset',
    willChange: 'transform',

    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard,
    }),

    '&:hover': {
      transform: 'scale(1.05)',
    },
  },

  downloadOptionImageWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  downloadOptionName: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.utility.black1,
    marginTop: theme.spacing(2.25),
  },

  downloadOptionActionWrapper: {
    flexBasis: 61,
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
    background: `transparent linear-gradient(180deg, ${theme.palette.primary.dark} 0%, ${theme.palette.secondary.dark} 100%) 0% 0%`,
    display: 'flex',
  },
  downloadOptionActionTextWrapper: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  downloadOptionActionText: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.common.white,
  },
  downloadOptionActionIconWrapper: {
    position: 'relative',
    flexBasis: 61,
  },
  downloadOptionActionOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0.19,
    backgroundColor: theme.palette.utility.black1,
  },
  downloadOptionActionIcon: {
    width: 20,
    height: 20,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fill: theme.palette.common.white,
  },
}));
