import type firebase from 'firebase';
import { PresentationDto } from 'modules/presentation/types';

export type PresentationDashboard = {
  name: PresentationDto['name'];
  uid: string;
  createdAt: PresentationDto['createdAt'];
  modifiedAt: PresentationDto['modifiedAt'];
  source: string;
};

export type CuratedColorScheme = {
  accent1Color: string;
  accent2Color: string;
  accent3Color: string;
  accent4Color: string;
  accent5Color: string;
  accent6Color: string;
  internalId: string;
  isPublished: boolean;
  name: string;
  textColor: string;
  order: number;
};

export type UserColorScheme = Omit<CuratedColorScheme, 'isPublished'> & {
  createdAt: firebase.firestore.Timestamp;
};

export type ColorScheme = CuratedColorScheme | UserColorScheme;

export type FontPreviewImages = {
  fontName: string;
  lowercaseHighlight: string;
  lowercaseWhite: string;
  uppercaseHighlight: string;
  uppercaseWhite: string;
};

export type Font = {
  familyName: string;
  internalId: string;
  isPublished: boolean;
  isSystemFont: boolean;
  previewImages: FontPreviewImages | null;
  reference: string | null;
};

export type FontPair = {
  id: string;
  internalId: string;
  isPublished: boolean;
  isSystemPair: boolean;
  majorFont: Font;
  minorFont: Font;
  name: string;
  order: number;
};

export type FontPairDocumentData = Omit<FontPair, 'majorFont' | 'minorFont'> & {
  majorFont: firebase.firestore.DocumentReference<Font>;
  minorFont: firebase.firestore.DocumentReference<Font>;
};

export type PhotoPackItem = {
  backupImage: string;
  backupThumbnail: string;
  image: string;
  imageThumbnail: string;
  index: number;
};

export type PhotoPackDocumentData = {
  id: string;
  internalId: string;
  hasMissingPhotos: boolean;
  isPublished: boolean;
  items: PhotoPackItem[];
  name: string;
  order: number;
};

export type StripePriceInterval = 'day' | 'week' | 'month' | 'year';

// NOTE: not an exhaustive type definition
export type StripePriceObject = {
  id: string; // doc UID
  active: boolean;
  billing_scheme: 'per_unit' | 'tiered';
  currency: string; // ISO codes
  description: string;
  interval: StripePriceInterval;
  interval_count: number;
  perks?: string[];

  recurring: {
    aggregate_usage: string | null;
    interval: StripePriceInterval;
    interval_count: number;
    usage_type: 'metered' | 'licensed';
  } | null;

  type: 'one_time' | 'recurring';
  unit_amount: number;
};

export enum LayoutType {
  Block = 'BLOCK',
  Round = 'ROUND',
  Edge = 'EDGE',
  Flow = 'FLOW',
}

export type LayoutsDocumentData = {
  id: string;
  fileName: string;
  internalId: string;
  isPublished: boolean;
  name: string;
  type: LayoutType;
  order: number;
};
