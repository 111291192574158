import { i18nClient } from 'services/translations';
import { PresentationError } from './types';

export const downloadFile = (downloadURL: string, fileName?: string): void => {
  const downloadLink = document.createElement('a');
  downloadLink.href = downloadURL;

  if (fileName) {
    downloadLink.download = fileName;
  }

  downloadLink.click();
  downloadLink.remove();
};

export const clamp = (number: number, min: number, max: number) => {
  return Math.max(min, Math.min(number, max));
};

export const createPresentationErrorMessage = (error: PresentationError): string => {
  switch (error) {
    case PresentationError.LayoutChangeFailure:
      return i18nClient.t('general.presentationUpdateErrorMessage', { type: 'layout' });

    case PresentationError.LogoChangeFailure:
      return i18nClient.t('general.presentationUpdateErrorMessage', { type: 'logo' });

    case PresentationError.ColorChangeFailure:
      return i18nClient.t('general.presentationUpdateErrorMessage', { type: 'color scheme' });

    case PresentationError.FontChangeFailure:
      return i18nClient.t('general.presentationUpdateErrorMessage', { type: 'font scheme' });

    case PresentationError.PhotopackChangeFailure:
      return i18nClient.t('general.presentationUpdateErrorMessage', { type: 'photo pack' });

    case PresentationError.PreviewFailure:
      return i18nClient.t('general.presentationGenerateErrorMessage', { type: 'full preview' });

    case PresentationError.GeneratePackageFailure:
      return i18nClient.t('general.presentationGenerateErrorMessage', { type: 'presentation file' });

    default:
      return i18nClient.t('general.failureMessage');
  }
};
