import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const DownloadIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(-2 -4)">
        <path d="M21,14a.945.945,0,0,0-1,1v4a.945.945,0,0,1-1,1H5a.945.945,0,0,1-1-1V15a.945.945,0,0,0-1-1,.945.945,0,0,0-1,1v4a2.946,2.946,0,0,0,3,3H19a2.946,2.946,0,0,0,3-3V15A.945.945,0,0,0,21,14Z" />
        <path d="M11.3,15.7c.1.1.2.2.3.2a.6.6,0,0,0,.4.1.6.6,0,0,0,.4-.1c.1-.1.2-.1.3-.2l5-5a.99.99,0,1,0-1.4-1.4L13,12.6V3a1,1,0,0,0-2,0v9.6L7.7,9.3a.99.99,0,1,0-1.4,1.4Z" />
      </g>
    </SvgIcon>
  );
};
