import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, Partial<SlideStyleProps>>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    justifyContent: 'center',
  },
  accentLineEdge: {
    position: 'absolute',
    left: '6%',
    top: '12.2%',
    right: 'auto',
    bottom: 'auto',
    width: '7.5%',
    height: '1.5%',
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
  },
  titleFlowTwo: {
    position: 'absolute',
    left: '32%',
    top: '35%',
    right: 'auto',
    bottom: '46%',
    width: '36%',
    marginTop: '0px',
    marginBottom: '0px',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.textColor : theme.palette.slide.black),
    fontSize: '4.5em',
    lineHeight: '110%',
    textAlign: 'center',
  },
  subtitleFlowTwo: {
    position: 'absolute',
    left: '32%',
    top: '56%',
    right: 'auto',
    bottom: 'auto',
    width: '36%',
    marginTop: '0',
    marginBottom: '0',
    color: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
    fontSize: '1.8em',
    lineHeight: '110%',
    textAlign: 'center',
  },
  logoBoxFlowTwo: {
    position: 'absolute',
    left: '43%',
    top: '66%',
    right: '11.5%',
    bottom: '4%',
    zIndex: 100,
    width: '14%',
    height: '12%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
