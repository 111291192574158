import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, Partial<SlideStyleProps>>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    justifyContent: 'center',
  },
  titleTwoRound: {
    position: 'absolute',
    left: '5%',
    top: 'auto',
    right: 'auto',
    bottom: '57%',
    width: '40%',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '5.5em',
    lineHeight: '110%',
  },
  subtitleTwoRound: {
    position: 'absolute',
    left: '5%',
    top: '47.5%',
    right: 'auto',
    bottom: 'auto',
    width: '40%',
    marginTop: '0',
    marginBottom: '0',
    color: '#fff',
    fontSize: '2em',
    lineHeight: '110%',
  },
  logoBoxRoundTitleTwo: {
    position: 'absolute',
    left: '5.5%',
    top: 'auto',
    right: 'auto',
    bottom: '7%',
    zIndex: 100,
    display: 'flex',
    width: '20%',
    height: '17%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  svgWhiteSecond: {
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
  },
  svgDarkSecond: {
    borderRadius: theme.shape.borderRadius * 2,
  },
}));
