import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const PhotoPackIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.584,2H4.927A2.927,2.927,0,0,0,2,4.927V18.584a2.927,2.927,0,0,0,2.927,2.927H18.584a2.741,2.741,0,0,0,.478-.049l.293-.068h.117l.361-.137.127-.068c.1-.059.2-.107.3-.176a3.7,3.7,0,0,0,.371-.312l.068-.088a2.624,2.624,0,0,0,.263-.312l.088-.127a2.253,2.253,0,0,0,.176-.341.975.975,0,0,0,.068-.146c.049-.117.078-.244.117-.371v-.146a2.536,2.536,0,0,0,.1-.585V4.927A2.927,2.927,0,0,0,18.584,2ZM4.927,19.559a.976.976,0,0,1-.976-.976v-4.2L7.161,11.16h0a.976.976,0,0,1,1.385,0l8.39,8.4Zm14.633-.976a.792.792,0,0,1-.146.488.918.918,0,0,1-.088.117L14.106,13.97l.858-.858a.976.976,0,0,1,1.385,0h0l3.209,3.219Zm0-5.014-1.834-1.814a3,3,0,0,0-4.136,0l-.858.858L9.921,9.8a3,3,0,0,0-4.136,0L3.951,11.619V4.927a.976.976,0,0,1,.976-.976H18.584a.976.976,0,0,1,.976.976ZM13.219,5.9a1.463,1.463,0,1,0,1.463,1.463A1.463,1.463,0,0,0,13.219,5.9Z"
        transform="translate(-2 -2)"
      />
    </SvgIcon>
  );
};
