import { useStyles } from './SlideFirst.styles';
import { useRootStyles } from '../../common.styles';
import { SlideProps } from '../../types';
import cn from 'classnames';

export const SlideFirst: React.FC<SlideProps> = ({ colorTheme, logoUrl, photoPack, fontPair }) => {
  const styles = useStyles({ colorTheme, fontPair });
  const rootStyles = useRootStyles({ colorTheme, fontPair });
  const photos = photoPack;
  const imageSource: string | null = photos?.items[0].image;
  const backupImageSource: string = photos?.items[0].backupImage!;
  return (
    <>
      <div className={cn(styles.slideCanvas, rootStyles.root)}>
        <img src={imageSource ? imageSource : backupImageSource} loading="lazy" alt="" className={styles.image} />
        <div className={styles.titleOneBlock}></div>
        <h1 className={styles.titleOne}>Image Title Slide</h1>
        <h2 className={styles.subtitleOne}>Presenter Name / Date</h2>
        {logoUrl && (
          <div className={rootStyles.logoContainer}>
            <img src={logoUrl} loading="lazy" alt="" className={rootStyles.logo} />
          </div>
        )}
      </div>
    </>
  );
};

export default SlideFirst;
