import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { NavigationLink, Button } from 'components';
import { routes } from 'const';
import SharpslideLogo from 'assets/sharpslide-logo-v2.svg';
import { useStyles } from './AppPanel.styles';
import { useAuthManagement } from 'modules/auth/hooks';

interface AppPanelProps {}

export const AppPanel: React.FC<AppPanelProps> = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { user, logoutUser } = useAuthManagement();

  const isLoggedIn = Boolean(user);
  const isLoginRoute = location.pathname === routes.login.path;
  const isAccountRoute = location.pathname === routes.account.path;
  const isDashboardRoute = location.pathname === routes.dashboard.path;
  const actionPrompt = isLoginRoute ? t('appPanel.registerPrompt') : t('appPanel.loginPrompt');
  const actionText = isLoginRoute ? t('appPanel.getStarted') : t('appPanel.signIn');

  const handleClickLogo = (): void => {
    if (isLoggedIn) history.push(routes.dashboard.path);
  };

  const handleClickActionButton = (): void => {
    if (isLoginRoute) {
      history.push(routes.register.path);
    } else {
      history.push(routes.login.path);
    }
  };

  return (
    <AppBar className={styles.appBarHeight} elevation={0} position="relative" color="transparent">
      <Toolbar className={cn(styles.toolbar, styles.appBarHeight)} disableGutters>
        <img
          className={cn({ [styles.sharpslideLogo]: isLoggedIn })}
          width="167"
          height="33"
          src={SharpslideLogo}
          alt="Sharpslide company logotype"
          onClick={handleClickLogo}
        />

        {isLoggedIn ? (
          <nav>
            <NavigationLink className={styles.navigation} to={routes.dashboard.path} active={isDashboardRoute}>
              {t('appPanel.dashboard')}
            </NavigationLink>
            <NavigationLink className={styles.navigation} to={routes.account.path} active={isAccountRoute}>
              {t('appPanel.account')}
            </NavigationLink>
            <Button onClick={logoutUser}>{t('appPanel.logout')}</Button>
          </nav>
        ) : (
          <div className={styles.actionContainer}>
            <Typography className={styles.actionPrompt}>{actionPrompt}</Typography>
            <Button onClick={handleClickActionButton}>{actionText}</Button>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};
