import { makeStyles, Theme } from '@material-ui/core/styles';

type CreatorPreviewStyles = {
  height?: number;
};

export const useStyles = makeStyles<Theme, CreatorPreviewStyles>(theme => ({
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },

  '@keyframes fadeOut': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },

  previewContainer: {
    backgroundColor: theme.palette.utility.gray1,
    borderTopLeftRadius: theme.shape.borderRadius * 4,
    padding: theme.spacing(4.375, 5.625),
  },
  previewTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2.5),
  },
  previewTitle: {
    color: theme.palette.utility.black1,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightSemiBold,
    marginRight: theme.spacing(1.25),
  },
  infoIcon: {
    fontSize: theme.typography.pxToRem(20),
  },

  slidesContainer: {
    display: 'grid',
    gridGap: theme.spacing(5.5, 5),
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',

    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(4, auto)         ',
    },
  },
  slidesContainerFullSize: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(380px, 1fr))',

    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(4, auto)         ',
    },
  },
  slidesContainerFadeIn: {
    opacity: 0,
    animationName: '$fadeIn',
    animationDelay: `${theme.transitions.duration.complex}ms`,
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'forwards',
  },
  slidesContainerFadeOut: {
    opacity: 0,
    animationName: '$fadeOut',
    animationDelay: `${theme.transitions.duration.complex}ms`,
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'forwards',
  },
  skeleton: {
    height: ({ height }) => height,
    width: '100%',
    borderRadius: theme.shape.borderRadius * 2,
  },
}));
