import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, Partial<SlideStyleProps>>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    justifyContent: 'center',
  },
  slideContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: 0,
    opacity: 0.75,
    height: 'auto',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : '#3a5df6'),
  },
  fullBackgroundImageRounded: {
    borderTopLeftRadius: theme.shape.borderRadius * 4,
    borderBottomRightRadius: theme.shape.borderRadius * 4,
  },
  transition: {
    width: '80%',
    color: '#fff',
    fontSize: '7em',
    lineHeight: '110%',
    textAlign: 'center',
  },
  logoBoxFlowThree: {
    position: 'absolute',
    left: '4.5%',
    top: '6.4%',
    right: 'auto',
    bottom: 'auto',
    zIndex: 100,
    width: '18%',
    height: '14%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  svgWhiteFlowThree: {
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
  },
}));
