import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, Partial<SlideStyleProps>>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    justifyContent: 'center',
  },
  accentLineEdge: {
    position: 'absolute',
    left: '6%',
    top: '12.2%',
    right: 'auto',
    bottom: 'auto',
    width: '7.5%',
    height: '1.5%',
    backgroundColor: ({ colorTheme }) => (colorTheme ? colorTheme.accent1Color : theme.palette.slide.accent1),
  },
  titleTwoEdge: {
    position: 'absolute',
    left: '6%',
    top: '16%',
    right: 'auto',
    bottom: 'auto',
    width: '40%',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '5.5em',
    lineHeight: '110%',
  },
  subtitleTwoEdge: {
    position: 'absolute',
    left: '6%',
    top: '65.5%',
    right: 'auto',
    bottom: 'auto',
    width: '40%',
    marginTop: '0',
    marginBottom: '0',
    color: '#fff',
    fontSize: '2em',
    lineHeight: '110%',
  },
  svgWhiteSecond: {
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
  },
  svgDarkSecond: {
    borderRadius: theme.shape.borderRadius * 2,
  },
  logoBoxEdgeTitleTwo: {
    position: 'absolute',
    left: 'auto',
    top: 'auto',
    right: '11.5%',
    bottom: '4%',
    zIndex: 100,
    width: '19.8%',
    height: '15%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
