import type firebase from 'firebase';
import { PresentationDocumentData, PresentationDto } from '../types';

export const presentationConverter = {
  toFirestore(): firebase.firestore.DocumentData {
    return {};
  },

  fromFirestore(snapshot: firebase.firestore.DocumentSnapshot): PresentationDto {
    const presentationData = snapshot.data() as PresentationDocumentData | undefined;

    return {
      ...presentationData,
      layout: presentationData?.layout?.id,
      fontTheme: presentationData?.fontTheme?.fontPair?.id,
    };
  },
};
