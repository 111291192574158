import { SlideStyleProps } from '../../types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, SlideStyleProps>(theme => ({
  slideCanvas: {
    position: 'relative',
    aspectRatio: '16/9',
    boxShadow: `0px 3px 12px ${theme.palette.utility.grayscale[100]}`,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    justifyContent: 'center',
  },
  titleOneEdge: {
    position: 'absolute',
    left: '6%',
    top: '55.5%',
    right: 'auto',
    bottom: 'auto',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#fff',
    fontSize: '6em',
    lineHeight: '110%',
  },
  subtitleOneEdge: {
    position: 'absolute',
    left: '6%',
    top: '83%',
    right: 'auto',
    bottom: 'auto',
    marginTop: '0',
    marginBottom: '0',
    color: '#fff',
    fontSize: '2em',
    lineHeight: '110%',
  },
  logoBoxEdgeTitleOne: {
    position: 'absolute',
    left: '4.3%',
    top: '33%',
    right: 'auto',
    bottom: '-3px',
    zIndex: 100,
    width: '21.2%',
    height: '13.5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
