import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(4),
  },

  templatesTitle: {
    fontSize: theme.typography.pxToRem(44),
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.utility.black1,
    letterSpacing: -1.8,
    marginBottom: theme.spacing(4.375),
  },

  presentationsWrapper: {
    display: 'grid',
    gridGap: theme.spacing(5.875, 2.625),
    gridTemplateColumns: 'repeat(auto-fill, minmax(390px, 1fr))',
    justifyItems: 'center',
  },
}));
